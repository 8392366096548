import { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, EButtonType } from "../../components/Button";
import { RootState } from "../../reducers";
import { Dispatch, bindActionCreators } from 'redux';
import * as actions from '../../actions/generalActions';
import * as adminActions from '../../actions/admin';
import { connect } from "react-redux";
import MikaTextInput from "../../components/Input/MikaTextInput/MikaTextInput";
import {toast} from "react-toastify";
import Tooltip from "rc-tooltip";
import clearFormSvg from "../../assets/icons/archeology.svg";
import TableBbros from "../../components/Table/TableBbros";
import {COLOR_COLUMNS, OPTIONS} from "../../components/Table/TableConstants";
import EditIcon from "@material-ui/icons/Edit";
import {Responsibility} from "../../types/responsibility.type";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import {EInputTextType, Input} from "../../components/Input/Input";
import {isMobile} from "../../constants/constants";
import TranslatedText, {translatedText} from "../../components/TranslatedText";

interface Props {
    admin: any;
    intl: any;
    measureActions: any;
    loading: boolean;
    adminActions: any;
    buttonLoader: boolean
}

const ColorPalettes = (props: Props) => {
    const [selectedColorId, setSelectedColorId] = useState(null);
    const [colorPalette, setColorPalette] = useState('');

    useEffect(() => {
        props.adminActions.getColorPalettes()
    }, [])

    const saveColor = () => {
        if(colorPalette === ''){
            toast.error(translatedText({
                id: "admin.pleaseFillTheField",
                defaultMessage: "Ju lutem mbushni fushen!"
            }))
            return
        }
        let payload = {
            colorPalette: colorPalette
        }
        if(selectedColorId){
            props.adminActions.editColorPalette(payload, selectedColorId).then(()=>{
                props.adminActions.getColorPalettes()
            })
        }else{
            props.adminActions.createColorPalette(payload).then(()=>{
                props.adminActions.getColorPalettes()
            })
        }
    }

    function editResponsibilityData (item) {
        setSelectedColorId(item?.id)
        setColorPalette('')
        toast.info(translatedText({
            id: "app.admin.responsibilitySelected",
            defaultMessage: "Procesi u selektua"
        }))
    }

    function deleteResponsibility (item) {
        props.adminActions.deleteColorPalette(item?.id).then(()=>{
            props.adminActions.getColorPalettes()
        })
    }

    function resetFields (){
        setSelectedColorId(null)
        setColorPalette('')
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData:Responsibility) => editResponsibilityData(rowData)
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData:Responsibility) => deleteResponsibility(rowData)
        },
    ]

    return (
        <div className="admin-page">
            <div className={`create-admin-page-form ${isMobile?'w-100':'w-25'}`}>
                <div className="d-flex align-items-center flex-column">
                    <Input
                        name="responsibilityName"
                        value={colorPalette || ''}
                        type="text"
                        inputWrapperClass="w-100"
                        onChange={(name, value) => setColorPalette(value)}
                        inputTextType={EInputTextType.SimpleInput}
                    >
                        <TranslatedText id="colorPalette" defaultMessage="Ngjyra"/>
                    </Input>
                    <Button
                        isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                        onClick={saveColor}
                        className="w-100"
                        buttonType={EButtonType.GreyButton}
                    >
                        <TranslatedText id="addColor" defaultMessage="Ruaj ngjyren" />
                    </Button>
                </div>
            </div>
            <div className="admin-page-list">
                <h2 className="m-0 mb-4">
                    <TranslatedText id="listOfColors" defaultMessage="Lista e ngjyrave"/>
                    <Tooltip placement="right" overlay={<span><TranslatedText id="clearForm"
                                                                                defaultMessage="Pastro Formen"/></span>}>
                        <img className="create-client-icon" onClick={resetFields} src={clearFormSvg}
                             alt="create client"/>
                    </Tooltip>
                </h2>
                <TableBbros
                    title={translatedText({ id:"admin.listOfColors", defaultMessage:"Lista e ngjyrave"})}
                    data={props.admin?.colorPalettes}
                    columns={COLOR_COLUMNS}
                    options={OPTIONS}
                    actions={actions}
                    isLoading={props.loading}
                />
            </div>
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        admin: state.admin
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        adminActions: bindActionCreators(adminActions as any, dispatch)
    }
}

export default connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(ColorPalettes));
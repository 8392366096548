import React from 'react';
import { SelectOption } from '../../types/selectOption.type';
import { FormattedMessage } from 'react-intl';
import './Agreements.scss'
import TranslatedText from "../../components/TranslatedText";
interface Props {
    clientName: string,
    agreementStatus: SelectOption
    prepaid: number,
    leftDebt: number,
    total: number,
    comment: string
}
export class PrintableAgreement extends React.PureComponent<Props> {
  render() {
    return (
        <>
            <div className="printableAgreement">
                <div className='printableAgreement-wrapper'>
                    <p className="pdfTitle"><TranslatedText id="app.table.nameSurname" defaultMessage="Marrveshja" /></p>
                    <div className="agreementContainer">
                        <div className="agreementField">
                            <p className="fieldLabel"><TranslatedText id="app.table.nameSurname" defaultMessage="Emri i klientit" /></p>
                            <p>{ this.props.clientName }</p>
                        </div>
                        <div className="agreementField">
                            <p className="fieldLabel"><TranslatedText id="app.table.nameSurname" defaultMessage="Statusi i marrveshjes" /></p>
                            <p>{ this.props.agreementStatus?.label }</p>
                        </div>
                        <div className="agreementField">
                            <p className="fieldLabel"><TranslatedText id="app.table.nameSurname" defaultMessage="Parapagesa" /></p>
                            <p>{ this.props.prepaid }</p>
                        </div>
                        <div className="agreementField">
                            <p className="fieldLabel"><TranslatedText id="app.table.nameSurname" defaultMessage="Borxhi i mbetur" /></p>
                            <p>{ this.props.leftDebt }</p>
                        </div>
                        <div className="agreementField">
                            <p className="fieldLabel"><TranslatedText id="app.table.nameSurname" defaultMessage="Totali i pageses" /></p>
                            <p>{ this.props.total }</p>
                        </div>
                        <p className="fieldLabel commentField"><TranslatedText id="app.table.nameSurname" defaultMessage="Komenti i marrveshjes" /></p>
                        <div className="commentField">
                            <p>{ this.props.comment }</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
  );
  }
}
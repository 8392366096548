import * as constants from '../constants/constants';
import { axiosCaller } from "../utils/apiCaller";
import {setButtonLoading, setLoading, wrap} from './generalActions'
import {toast} from "react-toastify";

export const getInstallationRecords = (query?,startDate?, endDate?) => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `installations/transports${query ? `?${query}` : ''}${query && startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}`:`${startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}`:``}`}`).then(res => {
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            dispatch({ "type": constants.FETCH_INSTALLATION_RECORDS, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const getProjectsReadyForInstallation = () => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `installations`).then(res => {
            dispatch({ "type": constants.FETCH_PROJECTS_FOR_INSTALLATION, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const getInstallationRecordsForMap = () => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `installations/map`).then(res => {

            dispatch({ "type": constants.FETCH_INSTALLATION_RECORDS_FOR_MAP, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const getInstallationRecordByProjectId = (projectId: number) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `installations/${projectId}/project`).then(res => {
            dispatch({ "type": constants.FETCH_INSTALLATION_RECORD_BY_PROJECT_ID, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')


export const getInstallationRecordByTransportId = (transportId: number) => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `installations/${transportId}/transport`).then(res => {
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            dispatch({ "type": constants.FETCH_INSTALLATION_RECORD_BY_TRANSPORT_ID, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const createInstallationRecord = (payload) => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('POST', `installations`, payload).then(res => {
            toast.success('Instalimi u krijua me sukses')
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')


export const editInstallationRecord = (payload, id) => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('POST', `installations/${id}`, payload).then(res => {
            toast.success('Instalimi u editua me sukses')
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const deleteInstallationRecord = (serviceId) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('DELETE', `installations/${serviceId}`).then(res => {
            toast.info('Instalimi u fshi me sukses!')
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')
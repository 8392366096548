import { Dispatch, bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import * as agreementsActions from '../../actions/agreements';
import { withRouter } from 'react-router';
import { Client } from '../../types/client.type';
import { Switch,Route } from 'react-router-dom';
import ShowAllBlueprints from "../Blueprints/ShowAllBlueprints";
import CreateBlueprintForm from "../Blueprints/CreateBlueprintForm";
interface Props {
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history:any;
    client:any;
    clientActions: any,
    actions: any,
    agreements: any
    agreementsActions: any,
    letter: string,
    clientsByModuleData: Array<Client>
}

const Blueprints = (props: Props) => {
    return (
        <div className="agreements">
            <Switch>
                <Route exact path="/dashboard/blueprints" component={ShowAllBlueprints} />
                <Route exact path="/dashboard/blueprints/create/:clientId?" component={CreateBlueprintForm}/>
                <Route render={() => <div>BLUEPRINTSS</div>}/>
            </Switch>
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        agreements: state.agreements
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        agreementsActions: bindActionCreators(agreementsActions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Blueprints as any)));
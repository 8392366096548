import React, { useEffect } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import { Switch,Route } from 'react-router-dom';
import "./Service.scss";
import { withRouter } from 'react-router';
import { User } from '../../types/user.type';
import AllServiceRecords from "./AllServiceRecords";
// import AllProjectForServiceMap from "./AllProjectForServiceMap";
import CreateServiceForm from "./CreateServiceForm";
import Archive from "../Archive/Archive";
import Page404 from "../Page404";
import ServiceMap from "./ServiceMap";

interface Props {
    intl: any;
    isLoading: boolean;
    setLoading: boolean;
    actions: any;
    clientActions: any;
    client: any;
    loading: boolean;
    validationMessageConfig: any;
    letter:string;
    app:any;
    history:any;
    measureUsers: User[];
}

const Service = (props: Props) => {

    useEffect(()=>{
        props.actions.getUserPermissionsAndResponsibilities()
    },[])

    return (
        <div className="transport inside-container">
            <Switch>
                <Route exact path="/dashboard/service" component={AllServiceRecords} />
                <Route exact path="/dashboard/service/records/:statusId?" component={AllServiceRecords} />
                <Route exact path="/dashboard/service/records-not-registered-clients/" render={()=>(<AllServiceRecords notRegisteredClients />)} />
                <Route exact path="/dashboard/service/create/:serviceId?/:projectId?" component={CreateServiceForm} />
                <Route exact path="/dashboard/service/not-registered-clients/:serviceId?" render={()=>(<CreateServiceForm notRegisteredClients/>)} />
                <Route path="/dashboard/service/archive/:moduleName?" component={Archive} />
                <Route path="/dashboard/service/map/:moduleName?" component={ServiceMap} />
                <Route render={()=><Page404/>}/>
            </Switch>
        </div>
    )

}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Service as any)))
import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import React, {useEffect, useState} from "react";
import {FormattedMessage, injectIntl} from 'react-intl'
import {Dispatch, bindActionCreators} from 'redux';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import * as agreementActions from '../../actions/agreements';
import {RootState} from '../../reducers';
import moment from "moment";
import {SelectOption} from "../../types/selectOption.type";
import TranslatedText, {translatedText} from "../../components/TranslatedText";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import HotkeyButton from "../../components/HotkeyButton/HotkeyButton";
import {setLoading} from "../../actions/generalActions";
import SimpleLoader from "../../components/Spinner/SimpleLoader";
import DataTransferComponent from "../../components/DataTransferComponent/DataTransferComponent";
import {EInputTextType, Input} from "../../components/Input/Input";
import {Button, EButtonType} from "../../components/Button";
import {toast} from "react-toastify";
import TaskForm from "./Tasks/TaskForm";
import {getMonthsBetween} from "../Admin/globalFunctions";
import {editAgreementDates} from "../../actions/agreements";

interface Props {
    loading: boolean
    actions: any,
    calendarData: any[],
    calendarDataPerClient: any[],
    client: any,
    clientActions: any,
    agreements: any
    intl: any
    buttonLoader: boolean
    departments: any
    projectDetails: any
    agreementActions: any
}

const AgreementCalendar = (props: Props) => {

    const [calendarEvents, setCalendarEvents] = useState([])
    const [selectedProduct, setSelectedProduct] = useState<any>()
    const [productIdForModal, setProductIdForModal] = useState<number>()
    const [selectedDepartment, setSelectedDepartment] = useState<SelectOption>({} as SelectOption)
    const [startDate, setStartDate] = useState<any>()
    const [calendarComment, setCalendarComment] = useState('')
    const [initialStartDate, setInitialStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()
    const [productData, setProductData] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)
    const [calendarDateView, setCalendarDateView] = useState(moment().format('YYYY-MM-DD'))
    const currentDate = moment().format('YYYY-MM-DD')
    const [showYearlyProduction, setShowYearlyProduction] = useState<boolean>(false)
    const [showTaskModal, setShowTaskModal] = useState<boolean>(false)
    const [taskCreationModal, setTaskCreationModal] = useState<boolean>(false)
    const [selectedAgreement, setSelectedAgreement] = useState<any>()
    const [selectedTask, setSelectedTask] = useState<any>()
    const [searchQuery, setSearchQuery] = useState('');
    const [startMonth, setStartMonth] = useState<any>()
    const [endMonth, setEndMonth] = useState<any>()
    const [selectedMonths, setSelectedMonths] = useState([])


    useEffect(() => {
        props.agreementActions.getAgreementsForCalendar()
    }, [])

    useEffect(() => {
        let calendarProduct = {
            startEditable: true,
            start: '',
            end: '',
            projectName: '',
            productName: '',
            clientName: '',
            id: null,
            taskId: null
        }

        let calendarEventArray = []
        props.agreements?.tasks?.forEach((task) => {
            calendarProduct = {
                ...task,
                taskId: task?.id,
                start: task?.start,
                end: task?.end,
            }
            calendarEventArray.push(calendarProduct)
        })
        props.agreements?.agreementsForCalendar?.forEach((calendarEvent) => {
                let endingDate = calendarEvent?.end
            if(!calendarEvent?.end){
                endingDate = moment(calendarEvent?.start).add(1, 'day').format("YYYY-MM-DD")
            }
            calendarProduct = {
                ...calendarEvent,
                clientName: calendarEvent?.client?.name,
                projectName: calendarEvent?.project?.name,
                id: calendarEvent?.id,
                start: calendarEvent?.start,
                end: endingDate,
                // endingDateString: endingDate,
            }
            calendarEventArray.push(calendarProduct)
        })
        calendarEventArray.sort(function compare(a, b) {
            let firstMilliseconds = new Date(a['end']).getTime();
            let secondMilliseconds = new Date(b['end']).getTime();
            return firstMilliseconds - secondMilliseconds;
        });
        console.log(calendarEventArray,'calendarEventArray SORTED')
        setCalendarEvents(calendarEventArray)
    }, [props.agreements?.agreementsForCalendar, props.agreements?.tasks])


    function addDays(date, days) {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;

    }

    function searchClick(searchItem) {
        if (searchItem?.taskId) {
            selectTask(searchItem)
        } else {
            setModalData({id: searchItem?.id, extendedProps: {...searchItem}})
        }
    }

    function setModalData(agreement) {
        console.log(agreement,'agreement,')
        return
        // make call
        let endingDate = agreement?.end

        setLoading(true)
        if(!agreement?.end){
            endingDate = moment(agreement?.start).add(1, 'day').format("YYYY-MM-DD")
        }
        setStartDate(new Date(moment(agreement?.start).format("YYYY-MM-DD")))
        setEndDate(new Date(endingDate))

        setSelectedAgreement({
            ...agreement.extendedProps,
            id: agreement?.id
        })
        props.actions.getAllProjectInfo('', agreement?.extendedProps?.project?.id).then(() => {
            setLoading(false)
        })
    }

    function selectTask(calendarTaskEvent) {
        setShowTaskModal(true)
        setSelectedTask(calendarTaskEvent)
        console.log(calendarTaskEvent, 'calendarTaskEvent')
    }


    function renderEventContent(eventInfo) {
        if (eventInfo?.event?.extendedProps?.taskId) {
            return (
                <div className={`event-container eventNr${eventInfo?.event?.id}`}
                     onClick={() => selectTask(eventInfo?.event)}>
                    <p>{eventInfo?.event?.title} ({eventInfo?.event?.extendedProps?.user?.name})</p>
                    <p>{eventInfo?.event?.extendedProps?.comment}</p>
                </div>
            )
        } else {
            return (
                <div className={`${eventInfo?.event?.extendedProps?.project?.shipmentDestinationId == 2 ? "export" : ""} event-container eventNr${eventInfo?.event?.id}`}
                     onClick={() => setModalData(eventInfo?.event)}>
                    <p>{eventInfo?.event?.extendedProps?.client?.name} ({moment(eventInfo?.event?.end)?.format('DD-MM-YYYY')})</p>
                    <p>{eventInfo?.event?.extendedProps?.project?.name}({eventInfo?.event?.extendedProps?.project?.shipmentDestinationName})</p>
                </div>
            )
        }
    }


    function closeAgreementInfoModal() {
        setSelectedAgreement({})
    }

    function selectCalendarView(monthView) {
        setCalendarDateView(monthView)
        setShowYearlyProduction(false)
    }

    function saveStartAndEndDate() {
        // if(moment(updatedStartDate) < moment()){
        //     toast.error('Data e fillimit nuk duhet te jete ne te kaluaren!')
        //     return;
        // }
        //
        // if(moment(updatedEndDate) < moment(updatedStartDate)){
        //     toast.error('Data e mbarimit nuk duhet te jete para se te filloi!')
        //     return;
        // }
        //
        // if (!updatedStartDate) {
        //     toast.error('Ju lutem mbushni daten e fillimit')
        //     return
        // }
        //
        // let payload = {
        //     productId: Number(updateDatesPayload?.productId),
        //     start: updatedStartDate ? moment(updatedStartDate).format('YYYY-MM-DD') : '',
        //     end: updatedEndDate ? moment(updatedEndDate).format('YYYY-MM-DD') : ''
        // }
        //
        // props.productionActions.updateProductDates(payload).then((res)=>{
        //     if (res.data) {
        //         toast.success('Datat u ndryshuan me sukses!')
        //         setUpdateDatesPayload({
        //             startDate: '',
        //             endDate: '',
        //             articleId: '',
        //             productId: '',
        //             quantity: ''
        //         })
        //         props?.getProductsOfClient()
        //     } else {
        //         toast.error('Diqka shkoi gabim!')
        //     }
        // })
    }

    const filteredData = calendarEvents.filter(item =>
        Object.values(item).some(value =>
            typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    const objectWithMonths = {
        "January": "01",
        "February": "02",
        "March": "03",
        "April": "04",
        "May": "05",
        "June": "06",
        "July": "07",
        "August": "08",
        "September": "09",
        "October": "10",
        "November": "11",
        "December": "12"
    };

    function filterMonths(){
        let arrayOfMonths = getMonthsBetween(startMonth, endMonth)
        let arrayOfMonthsForCalendar = []
        arrayOfMonths?.forEach((month)=>{
            console.log(currentDate, month.split(' '))
            arrayOfMonthsForCalendar.push({
                label: month?.split(' ')[0],
                value: `${month?.split(' ')[1]}-${objectWithMonths[month?.split(' ')[0]]}-01`
            })
        })
        setShowYearlyProduction(true)
        setSelectedMonths(arrayOfMonthsForCalendar)
    }

    function saveAgreementDates(){
        let payload = new FormData();

        payload.append("agreementId", selectedAgreement?.id);

        if(startDate){
            payload.append("start", moment(startDate).format('YYYY-MM-DD'));
        }
        if(endDate){
            payload.append("end", moment(endDate).format('YYYY-MM-DD'));
        }
        console.log(payload, props?.agreementActions)
        props.agreementActions?.editAgreementDates(payload).then(() => {
            props.agreementActions.getAgreementsForCalendar()
            closeAgreementInfoModal()
            toast.info('Datat u edituan me sukses!')
        })

    }

    return (
        <div className="agreements-calendar-container">
            <div className={`showYearlyProductionButtonAgreement ${showYearlyProduction ? "show": "hide"}`}>
                <input type="month" value={startMonth} onChange={(event)=> setStartMonth(event.target?.value)}/>
                <input type="month" value={endMonth} onChange={(event)=>setEndMonth(event.target?.value)} />
                <button onClick={filterMonths} className={'filterMonths'}>Filtro</button>
            </div>
            <div className={'addTaskButton'} onClick={() => setShowTaskModal(true)}>
                <p>
                    <TranslatedText id="addTask" defaultMessage="Shto task"/>
                </p>
            </div>
            <div className={'searchFilter'}>
                <div className={'inputAndListContainer'}>
                    <Input
                        name="searchQuery"
                        value={searchQuery || ''}
                        type="text"
                        inputWrapperClass="col-12 m-0"
                        onChange={(name, value) => setSearchQuery(value)}
                        inputTextType={EInputTextType.SimpleInput}
                        placeholder={translatedText({id: "client.offerFieldName", defaultMessage: "Kerko..."})}/>
                    {searchQuery && <div className={'searchList'}>
                        {searchQuery && filteredData.map(item => (
                            <>
                                {item?.taskId ?
                                    <div className={"itemContainer"} onClick={() => searchClick(item)} key={item?.id}>
                                        <p>Title: {item.title}</p>
                                        <p>Comment: {item.comment}</p>
                                    </div>
                                    :
                                    <div className={"itemContainer"} onClick={() => searchClick(item)} key={item?.id}>
                                        <p>Project Name: {item.projectName}</p>
                                        <p>Client Name: {item.clientName}</p>
                                    </div>
                                }
                            </>
                        ))}
                    </div>}
                </div>
            </div>
            {showYearlyProduction ?
                <div className={'calendar-wrapper'}>
                    {selectedMonths?.map((month) => {
                        return <div className={'calendar-holder'} onClick={() => selectCalendarView(month.value)}>
                            <FullCalendar
                                plugins={[daygridPlugin]}
                                eventContent={renderEventContent}
                                // eventDrop={saveChangedDate}
                                events={calendarEvents}
                                initialDate={month.value}
                                eventDragStart={(eventInfo: any) => setSelectedProduct(eventInfo.event)}
                                headerToolbar={{
                                    right: "prev,next",
                                    center: "title",
                                    left: "dayGridMonth"
                                }}
                            />
                        </div>
                    })}
                </div> :
                <FullCalendar
                    plugins={[daygridPlugin, interactionPlugin, timeGridPlugin]}
                    eventContent={renderEventContent}
                    // eventDrop={saveChangedDate}
                    events={calendarEvents}
                    initialDate={calendarDateView}
                    eventDragStart={(eventInfo: any) => setSelectedProduct(eventInfo.event)}
                    headerToolbar={{
                        right: "prev,next",
                        center: "title",
                        left: "dayGridMonth,timeGridWeek,timeGridDay"
                    }}
                />
            }
            {selectedAgreement?.id && <div className={'agreementExtraInfoModal-wrapper'}>
                <div className={'agreementExtraInfoModal'}>
                    <div className={'x-sign-container'}>
                        <HotkeyButton
                            keyCode="Escape"
                            modifiers={[]}
                            isActive={false}
                            onStateChange={(state) => {
                                if (state) {
                                    closeAgreementInfoModal()
                                }
                            }}
                        >
                            <img className={'x-sign'} src={DifferentColoredPlus} alt="" onClick={() => {
                                closeAgreementInfoModal()
                            }}/>
                        </HotkeyButton>
                    </div>
                    <div className={'agreementContent'}>
                        {loading ? <SimpleLoader/> :
                            <>
                                <div className={'titleContainer'}>
                                    <p>{props?.projectDetails?.measure?.clientNameAndLastName}({props?.projectDetails?.agreement?.totalDebtLeft}€)</p>
                                    <p>{props?.projectDetails?.agreement?.projectName}</p>
                                </div>
                                <div className={''}>
                                    <DataTransferComponent clientId={props?.client?.client?.id} measureNeeded
                                                           agreementNeeded noProjectDetails noContactDetails/>
                                </div>
                                <div className={'w-100 d-flex align-items-end p-2'}>
                                    <Input
                                        name="date"
                                        value={startDate ? startDate : ''}
                                        type="date"
                                        onChange={(name, value) => {
                                            setStartDate(value)
                                        }}
                                        inputWrapperClass={`simple-date-input w-100 m-0 p-0 pe-0 mb-0 heightFitContent ${startDate ? " " : 'does-not-have-data'}`}>
                                        <TranslatedText id="completionDate" defaultMessage="Data e fillimit"/>
                                    </Input>
                                </div>
                                <div className={'w-100 d-flex align-items-end p-2'}>
                                    <Input
                                        name="date"
                                        value={endDate ? endDate : ''}
                                        type="date"
                                        onChange={(name, value) => {
                                            setEndDate(value)
                                        }}
                                        inputWrapperClass={`simple-date-input w-100 m-0 p-0 pe-0 mb-0 heightFitContent ${endDate ? " " : 'does-not-have-data'}`}>
                                        <TranslatedText id="completionDate" defaultMessage="Data e perfundimit"/>
                                    </Input>
                                </div>
                                <div className={''}>
                                    <Button
                                        isLoading={props?.buttonLoader}
                                        isDisabled={props?.buttonLoader}
                                        submit={true}
                                        className="m-auto w-100"
                                        onClick={saveAgreementDates}
                                        buttonType={EButtonType.GreyButton}>
                                            <TranslatedText id="app.navigation.next" defaultMessage="Ruaj"/> :
                                    </Button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>}
            {showTaskModal && <div className={'taskCreationModal-wrapper'}>
                <TaskForm getUpdatedData={props.agreementActions.getAgreementsForCalendar} task={selectedTask}
                          setSelectedTask={setSelectedTask} setShowTaskModal={setShowTaskModal}/>
            </div>}
        </div>
    )
}


function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        agreements: state?.agreements
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        agreementActions: bindActionCreators(agreementActions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(AgreementCalendar as any)));
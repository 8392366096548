import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import { withRouter } from 'react-router';
import { useEffect } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import * as productionActions from '../../actions/production'
import * as adminActions from '../../actions/admin'
import Slider from "react-slick";
import { Input } from '../../components/Input/Input';
import { useState } from 'react';
import {useParams} from "react-router-dom";
import Spinner from "../../components/Spinner";
import { ParameterTypes } from '../../types/parameter.type';
import TranslatedText from "../../components/TranslatedText";

interface Props {
    slidesPerSlide: number;
    user: any,
    adminActions: any,
    admin: any,
    loading: boolean
}

const ResponsibilitySlider = (props: Props) => {

    const {clientId} = useParams<ParameterTypes>();

    const [slidesToShow , setSlidesToShow] = useState<number>()
    const [userIds , setUserIds] = useState<number[]>([])

    useEffect(()=>{
        props.adminActions.getUsersForResponsibilities()
    }, [])
    //
    useEffect(()=>{
        if(props.admin?.usersForResponsibilities?.length < 4){
            setSlidesToShow(props.admin?.usersForResponsibilities?.length)
        }else{
            setSlidesToShow(4)
        }
    }, [props.admin?.usersForResponsibilities])

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToShow
    };

    function saveUsers(isChecked: boolean, value: number){
        if(isChecked){
            setUserIds([
                ...userIds,
                value
            ])
        }else{
            setUserIds([
                ...userIds.filter(val=> val !== value)
            ])
        }
    }

    return(
        <div>
            { props.loading ? <Spinner/> : <div className="responsibilitySliderContainer">
                <p className="title">
                    <TranslatedText id="production.responsibilityAssigment" defaultMessage="Ndarja e proceseve"/>
                </p>
                <Slider {...settings} className="responsibilitySlider">
                    {props.admin?.usersForResponsibilities?.map((responsibilityWithUsers, index) => {
                        return (
                            <div key={index} className="responsibilitySlide">
                                <p className="responsibilityTitle">
                                    {responsibilityWithUsers.name}
                                </p>
                                <div className="custom-card">
                                    <div className="responsibility-user-container">
                                        {responsibilityWithUsers?.users.map((user) => {
                                            return (
                                                <div key={user.id}
                                                     className={`responsibility-user ${user.available ? '' : 'not-available'}`}>
                                                    <Input
                                                        value={user.id}
                                                        type="checkbox"
                                                        valueNeedeed
                                                        onChange={(currentTarget) => saveUsers(currentTarget.checked, currentTarget.value)}
                                                    />
                                                    <p>{user.firstname} {user.lastname}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </div>}
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        user: state.user,
        admin: state.admin,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        adminActions: bindActionCreators(adminActions as any, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ResponsibilitySlider as any)));
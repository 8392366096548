import * as React from 'react';
import { renderDefinedTrue } from '../../../utils/react';
import styles from './Label.module.scss'; // Import css modules stylesheet as styles


export interface Props {
  style?: any;
  for?: any;
}

export class InputLabel extends React.Component<Props, {}> {
  // public render(): JSX.Element {
  public render() {
    return renderDefinedTrue(this.props.children, () => (
      <label className={styles.inputLabel}
             style={this.props.style}>
        {this.props.children}
      </label>
    ));
  }
}

import React, {CSSProperties, useEffect, useState} from 'react';
import {ErrorMessage, Field} from 'formik';
import './MikaTextInput.scss'
import classNames from "classnames";


interface Props{
  style?: CSSProperties;
  labelKey?: string;
  name?: string;
  type?: string;
  value?: any;
  placeholder?: string;
  flat?: boolean,
  bordered?: boolean,
  disabled?: boolean,
  className?: string,
  children?: JSX.Element | JSX.Element[]
  onChange?: (value: string) => void;
}

const MikaTextInput: React.FC<Props> = (props: Props) => {

  function returnErrorMessage(errorMessage){
    return <p className='errorMessage'>{errorMessage}</p>
  }

  function onChangeEvent(textValue: string) {
    if(props?.onChange){
      props?.onChange(textValue)
    }
  }

  return <div className={classNames({
    ['mikaTextInputWrapper']: true,
    ['flat']: props.flat,
    ['bordered']: props.bordered,
    // ['invalid']: isInvalid,
    [props.className]: props.className
  })}>
    { props.children && <span>{props.children}</span> }
    { props.labelKey && <label> {props.labelKey}</label> }
    { props.value !== undefined ?
        <Field value={props?.value || ""} onChange={(inputElement: React.ChangeEvent<HTMLInputElement>)=> onChangeEvent(inputElement.target.value)} placeholder={props.placeholder} name={props.name} type="text" disabled={props.disabled} />
    :
        <>
          <Field placeholder={props.placeholder} name={props.name} type="text" disabled={props.disabled} />
          <ErrorMessage name={props.name}>{message => returnErrorMessage(message)}</ErrorMessage>
        </>
    }
  </div>
}


export default MikaTextInput;

import {EInputTextType, Input} from '../../components/Input/Input'
import {FormattedMessage, injectIntl} from 'react-intl'
import {withRouter} from 'react-router';
import {RootState} from '../../reducers';
import {connect} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import * as actions from '../../actions/generalActions';
import * as productionActions from '../../actions/production';
import * as adminActions from '../../actions/admin';
import './admin.scss'
import {Button, EButtonType} from '../../components/Button';
import {Material} from '../../types/material.type';
import Tooltip from 'rc-tooltip';
import MikaTextInput from "../../components/Input/MikaTextInput/MikaTextInput";
import clearFormSvg from '../../assets/icons/archeology.svg'
import {toast} from 'react-toastify';
import TableBbros from '../../components/Table/TableBbros';
import * as Yup from "yup";
import * as validationConfigs from "../../utils/validationConfigs";
import FormikFormWrapper from "../../components/FormikFormWrapper/FormikFormWrapper";
import {OPTIONS, MATERIAL_COLUMNS} from '../../components/Table/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import {checkCorrespondingInput, setOnlyNewCorrespondingItems} from "./globalFunctions";
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import {getMaterial} from "../../actions/admin";
import {isMobile} from "../../constants/constants";
import DeleteModalComponent from "../../components/Modal/DeleteModal";
import ModalComponent from "../../components/Modal/ModalComponent";
import TranslatedText, {translatedText} from "../../components/TranslatedText";

interface Props {
    history: any;
    loading: boolean;
    actions: any;
    production: any;
    intl: any;
    admin: any
    adminActions: any;
    productionActions: any;
    buttonLoader: boolean
}

const Responsibilities = (props: Props) => {
    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);
    const [materialName, setMaterialName] = useState<string>('')
    const [materialID, setMaterialID] = useState<number>(null)
    const [edit, isEdit] = useState<boolean>(false);
    const [responsibilityIds, setResponsibilityIds] = useState<number[]>([]);
    const [initialValues, setInitialValues] = useState({
        materialName: '',
    })

    useEffect(() => {
        props.productionActions.fetchResponsibilities()
        props.adminActions.fetchMaterials()
    }, [])

    useEffect(() => {
        let tempArrayOfResponsibilityIds = []
        props.admin?.selectedMaterial?.responsibilities?.forEach((responsibility) => {
            tempArrayOfResponsibilityIds.push(JSON.stringify(responsibility.id))
        })
        setResponsibilityIds(tempArrayOfResponsibilityIds)
    }, [props.admin.selectedMaterial])

    function saveMaterial(values) {
        if(materialName === '' || responsibilityIds.length === 0){
            toast.error(translatedText({
                id: "admin.pleaseFillTheField",
                defaultMessage: "Ju lutem mbushni fushen!"
            }))
            return
        }
        let payload = {
            material: materialName,
            responsibilities: responsibilityIds
        }

        props.adminActions.createMaterial(payload).then(() => {
            props.productionActions.fetchResponsibilities()
            props.adminActions.fetchMaterials()
            resetFields()
        })
        setMaterialName('')
    }

    function editMaterial(values) {
        if(materialName === '' || responsibilityIds.length === 0){
            toast.error(translatedText({
                id: "admin.pleaseFillTheField",
                defaultMessage: "Ju lutem mbushni fushen!"
            }))
            return
        }
        let payload = {
            material: materialName,
            responsibilities: responsibilityIds
        }

        props.adminActions.editMaterial(materialID, payload).then(() => {
            props.productionActions.fetchResponsibilities()
            props.adminActions.fetchMaterials()
            resetFields()
        })
        setMaterialName('')
        setMaterialID(null);
        isEdit(false);
    }

    const editMaterialData = (item: Material) => {
        props.adminActions.getMaterial(item.value)
        isEdit(true);
        setMaterialName(item.label)
        setMaterialID(item.value);
        setInitialValues({
            materialName: item.label
        })
        toast.info(translatedText({
            id: "app.admin.materialSelected",
            defaultMessage: "Materiali u selektua"
        }))
    }

    const deleteMaterial = () => {
        props.adminActions.deleteMaterial(materialID).then(() => {
            setDeleteModal(false)
            props.productionActions.fetchResponsibilities()
            props.adminActions.fetchMaterials()

        })
    }
    const resetFields = () => {
        setMaterialName('');
        setInitialValues({
            materialName: ''
        })
        setMaterialID(null);
        isEdit(false)
        setResponsibilityIds([])
    }

    function validationScheme() {
        return Yup.object().shape({
            materialName: validationConfigs.MATERIAL_VALIDATION,
        });
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData: Material) => editMaterialData(rowData)
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData: Material) => deleteButtonFunction(rowData)
        },
    ]

    function deleteButtonFunction (material) {
        setMaterialID(material?.value)
        setDeleteModal(true)
    }

    function returnIndexOfThatResponsibility(responsibilityIds, responsibility) {
        let tempIndex = ''
        responsibilityIds.forEach((responsibilityId, index) => {
            if (responsibilityId == responsibility.id) {
                tempIndex = `${index + 1}`
            }
        })
        return tempIndex
    }

    return (
        <div className="admin-page material-page">
            <div className="create-admin-page-form">
                <FormikFormWrapper
                    onSubmit={!edit ? saveMaterial : editMaterial}
                    validationScheme={validationScheme()}
                    initialValues={initialValues}>
                    <MikaTextInput name={'materialName'} bordered value={materialName} onChange={setMaterialName}>
                        <TranslatedText id="admin.materialName" defaultMessage="Materialet *"/>
                    </MikaTextInput>
                    <div>
                        <Button
                            isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                            submit={true}
                            className="w-100"
                            buttonType={EButtonType.GreyButton}>
                            {!edit ?
                                <TranslatedText id="admin.createMaterial" defaultMessage="Shto nje material"/>
                                :
                                <TranslatedText id="admin.editMaterial" defaultMessage="Ndrysho nje material"/>
                            }
                        </Button>
                    </div>
                </FormikFormWrapper>
            </div>

            <div className={`responsibilityListContainer ${isMobile ? 'w-100' : ''}`}>
                <p><TranslatedText id="client.createArticle" defaultMessage="Lista e proceseve *"/></p>
                <div className="responsibilities-container">
                    {props.production.responsibilities && props.production.responsibilities.map((responsibility, index) => {
                        return (
                            <div className="responsibility" key={index}>
                                <Input
                                    id={`input` + responsibility?.label}
                                    name={responsibility?.label}
                                    value={responsibility?.id}
                                    type="checkbox"
                                    inputWrapperClass="checkboxWrapper"
                                    valueNeedeed
                                    checked={checkCorrespondingInput(responsibilityIds, responsibility)}
                                    onChange={(currentTarget) => setOnlyNewCorrespondingItems(currentTarget.checked, currentTarget.value, setResponsibilityIds, responsibilityIds)}
                                >
                                    {returnIndexOfThatResponsibility(responsibilityIds, responsibility) &&
                                    <p className={'index'}>{returnIndexOfThatResponsibility(responsibilityIds, responsibility)}</p>}
                                    <p className="m-0">{responsibility?.label}</p>
                                </Input>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="admin-page-list">
                <h2 className="m-0 mb-4">
                    <TranslatedText id="client.responsibilityList" defaultMessage="Lista e materialeve"/>
                    <Tooltip placement="right" overlay={<span><TranslatedText id="app.clearForm"
                                                                                defaultMessage="Pastro Formen"/></span>}>
                        <img className="create-client-icon" onClick={resetFields} src={clearFormSvg}
                             alt="create client"/>
                    </Tooltip>
                </h2>
                <TableBbros
                    title={translatedText({id: "table.material", defaultMessage: "Llojet e materialit"})}
                    data={props.admin.materials}
                    columns={MATERIAL_COLUMNS}
                    options={OPTIONS}
                    actions={actions}
                    isLoading={props.loading}
                />
            </div>
            <ModalComponent
                isModalHeader
                isOpen={isDeleteModalOpen}
                close={setDeleteModal}
            >
                <DeleteModalComponent
                    history={props.history}
                    close={() => setDeleteModal(false)}
                    deleteFunction={() => deleteMaterial()} />
            </ModalComponent>
        </div>
    )
}


function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        production: state.production,
        admin: state.admin
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        productionActions: bindActionCreators(productionActions as any, dispatch),
        adminActions: bindActionCreators(adminActions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(Responsibilities as any)));
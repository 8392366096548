import * as constants from "../constants/constants";

export type measureTemplateObj = { 
    id: number,
    template: string
    templateWithoutBaseUrl:string,
    createdAt: string,
    updatedAt: string,
}

export interface MeasuresState {
    clientId: number;
    productType: string;
    productColor: string;
    productMaterial: string;
    productLocation: string;
    sketch: any;
    picture: any;
    measure: any;
    details: string;
    measureClient: any;
    assets: Array<any>;
    measureTemplates: Array<measureTemplateObj>;
    measureTemplate: any;
}

const initialState = () => {
    return {
        clientId: null,
        productType: "",
        productColor: "",
        productMaterial: "",
        productLocation: "",
        sketch:"",
        picture:"",
        measure:"",
        details:"",
        measureClient:{},
        assets:[],
        measureTemplates: [],
        measureTemplate: {}
    }
}

const measures = (state: MeasuresState = initialState(), action: any) => {
    switch (action.type) {
        case constants.FETCH_CLIENT_MEASURE: {
            return { 
                ...state, 
                measureClient: action.payload.data,
            };
        }
        case constants.CREATE_MEASURE: {
            return { 
                ...state, 
                clientId: action.payload.data.client_id,
                productType: action.payload.data.product_type,
                productColor: action.payload.data.product_color,
                productMaterial: action.payload.data.product_material,
                productLocation: action.payload.data.product_location,
                sketch: action.payload.data.sketch,
                picture: action.payload.data.picture,
                measure: action.payload.data.measure,
                details: action.payload.data.details,
            };
        }
        case constants.EDIT_MEASURE: {
            return { 
                ...state, 
                clientId: action.payload.data.client_id,
                productType: action.payload.data.product_type,
                productColor: action.payload.data.product_color,
                productMaterial: action.payload.data.product_material,
                productLocation: action.payload.data.product_location,
                sketch: action.payload.data.sketch,
                picture: action.payload.data.picture,
                measure: action.payload.data.measure,
                details: action.payload.data.details,
            };
        }
        case constants.GET_ASSETS: {
            return {
                ...state,
                assets: action.payload.assets
            }
        }
        case constants.CLEAR_ASSETS: {
            return {
                ...state,
                assets: action.payload
            }
        }
        case constants.CREATE_MEASURE_TEMPLATE: {
            return  {
                ...state,
                measureTemplates: [...state.measureTemplates,action.payload]
            }
        }
        case constants.GET_ALL_MEASURE_TEMPLATES: {
            return {
                ...state,
                measureTemplates: action.payload
            }
        }
        case constants.GET_MEASURE_TEMPLATE: {
            return {
                ...state,
                measureTemplate: action.payload
            }
        }
    }
    return state;
}

export default measures;
import React, {useEffect} from 'react';
import {Dispatch} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import {withRouter} from 'react-router';
import './Production.scss'
import {Link} from 'react-router-dom';
import ProductStatuses from './ProductStatuses';
import {checkIfUserIsAuthorized} from "../Admin/globalFunctions";
import {toast} from "react-toastify";
import {translatedText} from "../../components/TranslatedText";

interface Props {
    intl: any;
    isLoading: boolean;
    setLoading: boolean;
    history: any;
    permissions: any;
    doneStatus: number
}

const CreateProductionsForm = (props: Props) => {

    useEffect(() => {
        if (Object.keys(props.permissions).length !== 0 && !checkIfUserIsAuthorized(props.permissions, props.permissions?.fullPrivileges, props.permissions?.production)) {
            toast.error(translatedText({
                id: "app.yourNotAuthenticatedToModifyDataForThisModule",
                defaultMessage: "Nuk keni qasje ne modifikimin e te dhenave te ketij moduli!"
            }))
            props.history.push('/dashboard/production')
            if (props.permissions?.fullPrivileges) {
                props.history.push('/dashboard/production')
            } else {
                props.history.push('/dashboard/production/create')
            }
        }
    }, [props.permissions])

    return (
        <div className="production-container position-relative">
            {/*{props?.permissions?.fullPrivileges && <div className="button-container">*/}
            {/*    <Link to="/dashboard/production/calendar">*/}
            {/*        <p className="m-0">*/}
            {/*            <TranslatedText id="production.productStatuses" defaultMessage="Kalendari"/>*/}
            {/*        </p>*/}
            {/*    </Link>*/}
            {/*</div>}*/}
            {props?.doneStatus === 1 ? <ProductStatuses doneStatus={1}/> :
                <ProductStatuses doneStatus={0}/>
            }
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateProductionsForm as any)));
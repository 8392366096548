import { toast } from 'react-toastify';
import * as constants from '../constants/constants';
import { ResetPasswordCredentials } from '../containers/ResetPassword/ResetPassword';
import { ToNextModule } from '../types/toNextModule';
import { apiCaller, axiosCaller } from "../utils/apiCaller";
import {
    GET_ARCHIVED_MATERIALS_AND_RESPONSIBILITIES,
    GET_CLIENTS_WITH_STATUS1_FILTER,
    GET_RETURNED_RESPONSIBILITIES
} from "../constants/constants";
import {SelectOption} from "../types/selectOption.type";

export interface ACTION {
    type: string;
    payload?: any;
}

// export const setNavBarOpen = (payload: boolean): ACTION => ({
//     type: constants.IS_NAV_OPEN,
//     payload
// })

export const setNavBarOpen = (payload:boolean) => wrap(async(dispatch:any, state:any) => {
    dispatch({ "type": constants.IS_NAV_OPEN, payload: payload });
}, 'LOGIN_ERROR');


export const setLoading = (payload: boolean): ACTION => ({
    type: constants.LOADING,
    payload
});

export const setButtonLoading = (payload: boolean): ACTION => ({
    type: constants.BUTTON_LOADING,
    payload
});

export const setPartialLoading = (payload: boolean): ACTION => ({
    type: constants.PARTIAL_LOADING,
    payload
});

export const setPermissions = (payload): ACTION => ({
    type: constants.PERMISSIONS,
    payload: payload.data.permissions
});

export const setAdminDepartments = (payload): ACTION => ({
    type: constants.ADMIN_DEPARTMENTS,
    payload: payload.data.adminDepartments
});
export const setUserResponsibilities = (payload): ACTION => ({
    type: constants.USER_RESPONSIBILITIES,
    payload: payload.data.responsibilities
});

export const setEmail = (payload: string): ACTION => ({
    type: constants.SET_EMAIL,
    payload
});

export const setUsername = (payload: string): ACTION => ({
    type: constants.SET_USERNAME,
    payload
});

export const setRememberMe = (payload: boolean): ACTION => ({
    type: constants.SET_REMEMBER_ME,
    payload
});


export const setPassword = (payload: string): ACTION => ({
    type: constants.SET_PASSWORD,
    payload
});

export const resetPassword = (payload: string): ACTION => ({
    type: constants.RESET_PASSWORD,
    payload
});

export const isLogin = (payload: any): ACTION => ({
    type: constants.IS_LOGIN,
    payload
});

export const isAuthenticated = (payload: any): ACTION => ({
    type: constants.IS_AUTHENTICATED,
    payload
});

export const toggleNav = (payload: boolean): ACTION => ({
    type: constants.TOGGLE_NAV,
    payload
})

export const setStep = (payload: any): ACTION => ({
    type: constants.SET_STEP,
    payload
})

export const setLetterActive = (payload: boolean): ACTION => ({
    type: constants.IS_LETTER_ACTIVE,
    payload
})

export const setLetter = (payload: string): ACTION => ({
    type: constants.SET_LETTER,
    payload
})

export const setSelectedClientIndex = (payload: number): ACTION => ({
    type: constants.SET_SELECTED_CLIENT_INDEX,
    payload
})

export const setProjectName = (projectName: number): ACTION => ({
    type: constants.SET_PROJECT_NAME,
    payload: projectName
})


export const wrap = (fn:any, type = '') => {
    return function (dispatch:any) {
        return fn(dispatch).catch((error:any) => {
            try {
                let errorJson = JSON.parse(error);
                dispatch({ type, payload: errorJson });
            } catch (e) {
                return e;
            }
        });
    };
}

export const openAdminPanel = (payload: boolean): ACTION => ({
    type: constants.IS_ADMIN_PANEL_OPEN,
    payload
})

export const setLanguage = (payload): ACTION => ({
    type: constants.SET_LANGUAGE,
    payload
})

export const logOutLocally = (): ACTION => ({
  type: constants.LOG_OUT,
});


export const showValidations  = (payload: any): ACTION => ({
    type: constants.SHOW_VALIDATIONS,
    payload
});

// add login action
export const login = (payload:any) => wrap(async(dispatch:any, state:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        var newPayload = {
            username: payload.username,
            password: payload.password,
            rememberMe: payload.rememberMe
        }
        return await apiCaller('POST', 'login', newPayload).then((response:any) => {
            if(response.token) {
                localStorage.setItem('mikaBearerToken', response.token);
                localStorage.setItem('username', response.user.username);
                localStorage.setItem('mikaUserId', response.user.id);
                localStorage.setItem('userPermissions', response.permissions);
                const token = localStorage.getItem('mikaBearerToken')
                dispatch(authenticate(token));
                dispatch({ "type": constants.LOGIN, payload: response })
            } else {
                toast.error(response.error_login)
                dispatch(setLoading(false))
                dispatch(setButtonLoading(false));
            }
        });
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        localStorage.removeItem('mikaBearerToken');
        toast.error(err)
    }
}, 'LOGIN_ERROR');


export const resetPw = (payload:ResetPasswordCredentials) => wrap(async(dispatch:any, state:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('POST', 'reset-password', payload).then((response:any) => {
            if(response.data.errors) {
                toast.error(response.data.errors.password ? response.data.errors.password[0] : response.data.errors)
                dispatch(setLoading(false))
                dispatch(setButtonLoading(false));
            } else if(response.data.status) {
                dispatch(setLoading(false))
                dispatch(setButtonLoading(false));
                toast.info(response.data.status)
            }
        });
    } catch(err) {

        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error(err.details.password)
    }
}, 'LOGIN_ERROR');

export const forgotPassword = (payload: {email: string}) => wrap((dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('POST', `forgot-password`, payload).then(res => {
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            toast.success('Emaili u dergua me sukses');
        })
    }
    catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_MEASURE_ASSETS_ERROR')

export const authenticate = (payload) => wrap((dispatch, state) => {
    dispatch(setLoading(true))
    dispatch(setButtonLoading(true));
    return apiCaller('GET', `authenticated`, null, payload)
        .then(response => {
            if(response){
                dispatch(isAuthenticated(response))
                dispatch(isLogin(true))
                dispatch(setLoading(false))
                dispatch(setButtonLoading(false));
                return response
            } else {
                toast.error(response)
            }
        }).catch(err => {
            toast.error('Invalid token');
            localStorage.removeItem('mikaBearerToken');
            return null;
        })
}, 'LOGIN_ERROR');


export const logOut = () => wrap(async(dispatch:any) => {
    try {
        await apiCaller('POST', 'logout', {}).then(response => {
            dispatch({ "type": constants.LOG_OUT, payload: null });
        });
    } catch(err) {
        throw (err || 'Diqka nuk shkoi mire');
    }
    return true;
}, 'LOG_OUT');

export const fetchClientsByModuleData = (selectedLetter: string, page: number, moduleNumber: number, archive:string) => wrap(async(dispatch:any) => {
    if(selectedLetter === '#'){
        selectedLetter = "%23"
    }
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await apiCaller('POST', `clients/advanced-search`,{moduleId: moduleNumber}).then(res => {
            dispatch({ "type": constants.FETCH_CLIENTS_BY_MODULE_DATA, "payload": res });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            return res
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_CLIENTS_BY_MODULE_DATA')

export const setClientToNextModule = (payload: ToNextModule ) => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await apiCaller('POST', `modules/create-client-for-next-module` , payload).then(res => {
            dispatch({ "type": constants.SET_CLIENT_TO_NEXT_MODULE, "payload": res });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'SET_CLIENT_TO_NEXT_MODULE')

export const getAllMeasureUsers = () => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await apiCaller('GET', `getAllMeasureUsers`).then(res => {
            dispatch({ "type": constants.GET_ALL_MEASURE_USERS, "payload": res });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        toast.error('Diqka nuk shkoi mire');
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'GET_ALL_MEASURE_USERS')


export const getUserPermissionsAndResponsibilities = () => wrap(async(dispatch:any) => {
    try {
        return await apiCaller('GET', `users/permissions-responsibilities`).then(res => {
            dispatch(setPermissions(res))
            dispatch(setAdminDepartments(res))
            dispatch(setUserResponsibilities(res))
            return res
        })
    } catch(err) {
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_ALL_MEASURE_USERS')

export const fetchUnits = () => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `units`).then(res => {
            dispatch({ "type": constants.FETCH_UNITS, "payload": res.data.data });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_UNITS')

export const getAllDepartments = () => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `all-departments`).then(res => {
            dispatch({ "type": constants.GET_DEPARTMENTS, "payload": res.data.data });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_UNITS')

export const getAllProductionUsers = () => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `users/production-users`).then(res => {
            dispatch({ "type": constants.GET_PRODUCTION_USERS, "payload": res.data.data });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_UNITS')



export const fetchUnitsForArticle = () => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `units/article`).then(res => {
            dispatch({ "type": constants.FETCH_UNITS_FOR_ARTICLE, "payload": res.data.data });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_UNITS_FOR_ARTICLE')

export const fetchArticles = (filter) => wrap((dispatch:any, state:any) => {
    try {
        return axiosCaller('GET', `articles${filter !== undefined ? `${filter}`: ``}`).then((res:any) => {
            dispatch({ "type": constants.FETCH_ARTICLES, "payload": res.data });
        });
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const fetchArticlesForProduction = () => wrap((dispatch:any, state:any) => {
    try {
        return axiosCaller('GET', `articles?forProduction=1`).then((res:any) => {
            dispatch({ "type": constants.FETCH_ARTICLES_FOR_PRODUCTION, "payload": res.data.data });
        });
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const fetchArticlesForWarehouse = () => wrap((dispatch:any, state:any) => {
    try {
        return axiosCaller('GET', `articles?forProduction=0`).then((res:any) => {
            dispatch({ "type": constants.FETCH_ARTICLES_FOR_WAREHOUSE, "payload": res.data.data });
        });
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const fetchCalendarData = (deparment?: SelectOption) => wrap((dispatch:any, state:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('GET', `clients/products?moduleId=5&done=0&active=1${deparment?.value ? `&departmentId=${deparment?.value}` : ''}`).then((res:any) => {
            dispatch({ "type": constants.FETCH_CALENDAR_DATA, "payload": res });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const fetchCalendarDataPerClient = (clientId: number) => wrap((dispatch:any, state:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('GET', `clients/products/${clientId}?moduleId=5`).then((res:any) => {
            dispatch({ "type": constants.FETCH_CALENDAR_DATA_PER_CLIENT, "payload": res.data });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const getAllClients = (moduleId: number) => wrap((dispatch: any, state: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('GET', `clients/clients-with-id-and-firstname-and-lastname?moduleId=${moduleId}`).then((res: any) => {
            dispatch({ "type": constants.GET_ALL_CLIENTS, "payload": res.data.data });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const fetchOfferFields = () => wrap((dispatch: any, state: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('GET', `dynamic-offers`).then((res: any) => {
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            dispatch({ "type": constants.FETCH_ALL_OFFER_FIELDS, "payload": res.data.data });
        });
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'FETCH_ALL_OFFER_FIELDS');

export const fetchCountries = () => wrap((dispatch: any, state: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('GET', `countries`).then((res: any) => {
            dispatch({ "type": constants.FETCH_COUNTRIES, "payload": res.data.data });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'FETCH_COUNTRIES'); 



export const fetchCities = (countryName: string) => wrap((dispatch: any, state: any) => {
    try {
        return axiosCaller('GET', `countries/cities?city=${countryName}`).then((res: any) => {
            dispatch({ "type": constants.FETCH_CITIES, "payload": res.data.data });
        });
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'FETCH_CITIES');


export const getClientsWithStatusFilter = (moduleName:string, statusId: number) => wrap((dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('GET', `clients/${moduleName}/status${statusId ? `?statusId=${statusId}`: '' }`).then(res => {
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            dispatch({ "type": constants.GET_CLIENTS_WITH_STATUS_FILTER, "payload": res })
        })
    }
    catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_CLIENTS_WITH_STATUS_FILTER')

export const getClientsForMeasure = (measureStatusId?, startDate?, endDate?) => wrap((dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('GET', `measure/filter${measureStatusId ? measureStatusId === 'passive' ? '?passive=1' : `?measureStatusId=${measureStatusId}` : ''}${measureStatusId && startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}`:`${startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}`:``}`}`).then(res => {
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            if(measureStatusId == 1){
                dispatch({ "type": constants.GET_CLIENTS_WITH_STATUS1_FILTER, "payload": res })
            }else if(measureStatusId == 2){
                dispatch({ "type": constants.GET_CLIENTS_WITH_STATUS2_FILTER, "payload": res })
            }else if(measureStatusId == 3){
                dispatch({ "type": constants.GET_CLIENTS_WITH_STATUS3_FILTER, "payload": res })
            }else{
                dispatch({ "type": constants.GET_CLIENTS_WITH_STATUS_FILTER, "payload": res })
            }
        })
    }
    catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_CLIENTS_WITH_STATUS_FILTER')

export const getClientsForOffer = (offerStatusId?, startDate?, endDate?) => wrap((dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('GET', `offer/filter${offerStatusId ? `?offerStatusId=${offerStatusId}` : ''}${offerStatusId && startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}`:`${startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}`:``}`}`).then(res => {
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            if(offerStatusId == 1){
                dispatch({ "type": constants.GET_CLIENTS_WITH_STATUS1_FILTER, "payload": res })
            }else if(offerStatusId == 2){
                dispatch({ "type": constants.GET_CLIENTS_WITH_STATUS2_FILTER, "payload": res })
            }else if(offerStatusId == 3){
                dispatch({ "type": constants.GET_CLIENTS_WITH_STATUS3_FILTER, "payload": res })
            }else if(offerStatusId == 4){
                dispatch({ "type": constants.GET_CLIENTS_WITH_STATUS4_FILTER, "payload": res })
            }else if(offerStatusId == 5){
                dispatch({ "type": constants.GET_CLIENTS_WITH_STATUS5_FILTER, "payload": res })
            }else{
                dispatch({ "type": constants.GET_CLIENTS_WITH_STATUS_FILTER, "payload": res })
            }
        })
    }
    catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_CLIENTS_WITH_STATUS_FILTER')


export const getClientsForAgreement = (agreementStatusId?, startDate?, endDate?) => wrap((dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('GET', `agreement/filter${agreementStatusId ? `?agreementStatusId=${agreementStatusId}` : ''}${agreementStatusId && startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}`:`${startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}`:``}`}`).then(res => {
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            if(agreementStatusId == 1){
                dispatch({ "type": constants.GET_CLIENTS_WITH_STATUS1_FILTER, "payload": res })
            }else if(agreementStatusId == 2){
                dispatch({ "type": constants.GET_CLIENTS_WITH_STATUS2_FILTER, "payload": res })
            }else if(agreementStatusId == 3){
                dispatch({ "type": constants.GET_CLIENTS_WITH_STATUS3_FILTER, "payload": res })
            }else if(agreementStatusId == 4){
                dispatch({ "type": constants.GET_CLIENTS_WITH_STATUS4_FILTER, "payload": res })
            }else if(agreementStatusId == 5){
                dispatch({ "type": constants.GET_CLIENTS_WITH_STATUS5_FILTER, "payload": res })
            }else{
                dispatch({ "type": constants.GET_CLIENTS_WITH_STATUS_FILTER, "payload": res })
            }
        })
    }
    catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_CLIENTS_WITH_STATUS_FILTER')


// export const deleteModuleData = (clientId: number, moduleName: string) => wrap((dispatch:any) => {
//     try {
//         dispatch(setLoading(true))
//         dispatch(setButtonLoading(true));
//         return axiosCaller('DELETE', `clients/${clientId}/${moduleName}`).then(res => {
//             if(res.data.data){
//                 toast.success("Te dhenat e klientit u fshin me sukses!")
//             }else{
//                 toast.error("Ky klient gjindet ne modulet e ardhshme!")
//             }
//             dispatch(setLoading(false))
//             dispatch(setButtonLoading(false));
//         })
//     }
//     catch(err) {
//         dispatch(setLoading(false))
//dispatch(setButtonLoading(false));
//         toast.error('Diqka nuk shkoi mire');
//     }
// }, 'GET_MEASURE_ASSETS_ERROR')

export const getArchivedProducts = (departmentId?, shipmentDestinationId?, startDate?, endDate?) => wrap((dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('GET', `products/product-archive?active=1&done=1${departmentId ? `&departmentId=${departmentId}`: ''}${shipmentDestinationId ? `&shipmentDestinationId=${shipmentDestinationId}`: ''}${startDate ? `&start=${startDate}`: ''}${endDate ? `&end=${endDate}`: ''}`).then(res => {
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            dispatch({ "type": constants.GET_ARCHIVED_PRODUCTS, "payload": res.data.data })
        })
    }
    catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_MEASURE_ASSETS_ERROR')

export const getArchivedProductsForWarehouse = () => wrap((dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('GET', `products/archive`).then(res => {
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            dispatch({ "type": constants.GET_ARCHIVED_PRODUCTS, "payload": res.data.data })
        })
    }
    catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_MEASURE_ASSETS_ERROR')


// export const getArchivedProducts = (departmentId?, startDate?, endDate?) => wrap(async (dispatch: any) => {
//     try {
//         dispatch(setLoading(true))
//         dispatch(setButtonLoading(true));
//         return await axiosCaller('GET', `clients/products-statuses?moduleId=5&active=1&done=1${departmentId && departmentId != 'null' ? `&departmentId=${departmentId}`: ''}${startDate && endDate ? `&start=${startDate}&end=${endDate}` : ''}`).then(res => {
//             dispatch({ "type": constants.GET_ARCHIVED_PRODUCTS, "payload": res.data?.data });
//             dispatch(setLoading(false))
//             dispatch(setButtonLoading(false));
//         })
//     } catch(err) {
//         dispatch(setLoading(false))
//         dispatch(setButtonLoading(false));
//     }
// }, 'FETCH_PRODUCT_STATUSES')

export const getReturnedMaterials = (departmentId?, startDate?, endDate?) => wrap((dispatch:any) => {
    let questionMark = departmentId || startDate || endDate
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('GET', `returned-material-statuses${questionMark ? '?' : ''}${departmentId ? `departmentId=${departmentId}`: ''}${startDate ? `${departmentId ? "&" :'' }startDate=${startDate}`: ''}${endDate ? `&endDate=${endDate}`: ''}`).then(res => {
            dispatch({ "type": constants.GET_RETURNED_MATERIALS, "payload": res?.data?.data })
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    }
    catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_MEASURE_ASSETS_ERROR')

export const getReturnedResponsibilities = (departmentId?, startDate?, endDate?) => wrap((dispatch:any) => {
    let questionMark = departmentId || startDate || endDate
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('GET', `returned-product-statuses${questionMark ? '?' : ''}${departmentId ? `departmentId=${departmentId}`: ''}${startDate ? `${departmentId ? "&" :'' }startDate=${startDate}`: ''}${endDate ? `&endDate=${endDate}`: ''}`).then(res => {
            dispatch({ "type": constants.GET_RETURNED_RESPONSIBILITIES, "payload": res?.data?.data })
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    }
    catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_MEASURE_ASSETS_ERROR')

export const getArchivedResponsibilities = (departmentId? , startDate? , endDate?) => wrap((dispatch:any) => {
    let questionMark = departmentId || startDate || endDate
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('GET', `archive/product-status${questionMark ? '?' : ''}${departmentId ? `departmentId=${departmentId}`: ''}${startDate ? `${departmentId ? "&" :'' }startDate=${startDate}`: ''}${endDate ? `&endDate=${endDate}`: ''}`).then(res => {
            dispatch({ "type": constants.GET_ARCHIVED_RESPONSIBILITIES, "payload": res?.data?.data })
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    }
    catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_MEASURE_ASSETS_ERROR')

export const getArchivedMaterials = (departmentId? , startDate? , endDate?) => wrap((dispatch:any) => {
    let questionMark = departmentId || startDate || endDate
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('GET', `archive/material-status${questionMark ? '?' : ''}${departmentId ? `departmentId=${departmentId}`: ''}${startDate ? `${departmentId ? "&" :'' }startDate=${startDate}`: ''}${endDate ? `&endDate=${endDate}`: ''}`).then(res => {
            dispatch({ "type": constants.GET_ARCHIVED_MATERIALS, "payload": res?.data?.data })
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    }
    catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_MEASURE_ASSETS_ERROR')



export const getArchivedMaterialsAndResponsibilities = (page? ,departmentId? , startDate? , endDate?, clientId?, responsibilityId?, userId?, sort?) => wrap((dispatch:any) => {
    let questionMark = departmentId || startDate || endDate || clientId || responsibilityId || sort || userId

    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('GET', `archive/statuses${questionMark ? "?" : ""}${departmentId ? `departmentId=${departmentId}`: ''}${startDate ? `${departmentId ? "&" :'' }startDate=${startDate}`: ''}${endDate ? `&endDate=${endDate}`: ''}${clientId ? `&clientId=${clientId}` : ''}${responsibilityId ? `&responsibilityId=${responsibilityId}` : ''}${userId ? `&userId=${userId}` : ''}`).then(res => {
            dispatch({ "type": constants.GET_ARCHIVED_MATERIALS_AND_RESPONSIBILITIES, "payload": res?.data })
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    }
    catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_MEASURE_ASSETS_ERROR')

export const getModuleDataForClient = (moduleName:string, clientId: string) => wrap((dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('GET', `${moduleName}/client/${clientId}`).then(res => {
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            dispatch({ "type": constants.GET_ALL_PROJECTS, "payload": res })
        })
    }
    catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_MODULE_DATA_FOR_CLIENT')

export const getAllProjectInfo = (projectName: string, projectId: number) => wrap((dispatch:any) => {
    try {
        let replacedProjectName =  projectName;
        if(replacedProjectName.includes('#')){
            replacedProjectName = replacedProjectName.replaceAll('#','%23')
        }
        return axiosCaller('GET', `project${projectId ? `?projectId=${projectId}`:''}${projectName ? `?projectName=${replacedProjectName}`:''}`).then(res => {
            dispatch({ "type": constants.GET_ALL_PROJECT_INFO, "payload": res })
        })
    }
    catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_MODULE_DATA_FOR_CLIENT')

export const getProject = (projectId) => wrap(async (dispatch: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `projects/${projectId}`).then((response) => {
            dispatch({ "type": constants.GET_PROJECT, "payload": response?.data?.data })
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            return response
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'GET_CLIENT_PROJECTS')


export const removeFile = (moduleName: string, fileId: number) => wrap((dispatch:any) => {
    try {
        return axiosCaller('DELETE', `${moduleName}s/assets/${fileId}`)
    }
    catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_MODULE_DATA_FOR_CLIENT')

export const deleteModuleData = (moduleName: string, payload: {id: number}) => wrap((dispatch:any) => {
    try {
        return axiosCaller('POST', `clients/${moduleName}/delete`, payload)
    }
    catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_MODULE_DATA_FOR_CLIENT')

export const deleteArchiveData = (url: string, id: number) => wrap((dispatch:any) => {
    try {
        return axiosCaller('DELETE', `${url}/${id}`)
    }
    catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_MODULE_DATA_FOR_CLIENT')

export const getArchiveRecords = (urlString, start?, end?) => wrap((dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('GET', `${urlString}${start && end ? `?start=${start}&end=${end}`:''}`).then(res => {
            dispatch({ "type": constants.GET_ARCHIVE_RECORDS, "payload": res.data })
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    }
    catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_MODULE_DATA_FOR_CLIENT')

export const getProductsOfProject = (projectId) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `project/${projectId}/products`).then(res => {
            console.log('res.data.data', res.data?.data?.products)
            dispatch({ "type": constants.FETCH_PROJECT_INFORMATION, "payload": res.data?.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')
import { useEffect } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as productionActions from '../../actions/production';
import * as clientActions from '../../actions/client';
import { withRouter } from 'react-router';
import { Client, ClientObject } from '../../types/client.type';
import {OPTIONS, PRODUCTION_COLUMNS, PRODUCTION_COLUMNS_MOBILE} from '../../components/Table/TableConstants';
import TableBbros from '../../components/Table/TableBbros';
import EditIcon from '@material-ui/icons/Edit';
import {translatedText} from "../../components/TranslatedText";

interface Props {
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history: any;
    client: any;
    clientActions: any;
    productionActions: any;
    actions: any,
    offers: any,
    measures: any,
    letter: string,
    clientsByModuleData: Array<Client>
    permissions: any
}  

const ShowAllProductions = (props: Props) => {

    useEffect(() => {
        const page = 1;
        const moduleId = 5;
        props.actions.fetchClientsByModuleData(props.letter, page, moduleId)
        if(!props?.permissions?.fullPrivileges){
            props.history.push({pathname: '/dashboard/production/create'})
        }
    }, [])

    const editProductionData = (item:any) => {
        const moduleId = 5;
        props.clientActions.fetchClient(item.id, moduleId)
        props.productionActions.fetchProductionTableData(item.id)
        props.history.push({pathname: '/dashboard/production/create'})
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData:ClientObject) => editProductionData(rowData)
        }
    ]

    return (
        <div>
            <div className="wrapper">
                <div className="productionTable">
                    <TableBbros
                        className={'firstColumnThinner'}
                        title={translatedText({ id:"table.production", defaultMessage:"Prodhimi"})}
                        data={props.clientsByModuleData}
                        columns={window.innerWidth > 500 ? PRODUCTION_COLUMNS : PRODUCTION_COLUMNS_MOBILE}
                        options={OPTIONS}
                        actions={actions}
                        isLoading={props.loading}
                    />
                </div>
            </div>
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        measures: state.measures,
        offers: state.offers
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        productionActions: bindActionCreators(productionActions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(ShowAllProductions as any)));
import React from 'react';
import { FormattedMessage } from 'react-intl';
import './warehouse.scss'
import TranslatedText from "../../components/TranslatedText";
interface Props {
    clientName: string,
    products: any,
    quantities: any,
    projectName: string
}
export class WarehouseRecordPrint extends React.PureComponent<Props> {
    render() {
        return (
            <>
                <div className="printableWarehouseRecord">
                    <div className='printableWarehouseRecord-wrapper'>
                        <p className="pdfTitle"><TranslatedText id="app.table.nameSurname" defaultMessage="Deponimini" /></p>
                        <div className="warehouseRecordContainer">
                            <div className="warehouseField">
                                <p className="fieldLabel"><TranslatedText id="app.table.nameSurname" defaultMessage="Emri i klientit" /></p>
                                <p>{ this.props.clientName }</p>
                            </div>
                            <div className="warehouseField">
                                <p className="fieldLabel"><TranslatedText id="app.table.nameSurname" defaultMessage="Emri i projektit" /></p>
                                <p>{ this.props.projectName }</p>
                            </div>
                        </div>
                        <div className="warehouseRecordContainer mt-5">
                            <div className="warehouseField">
                                <p className="fieldLabel"><TranslatedText id="app.table.nameSurname" defaultMessage="Lista e produkteve" /></p>
                            </div>
                            <div className="warehouseField">
                                <p className="fieldLabel"><TranslatedText id="app.table.nameSurname" defaultMessage="Sasia" /></p>
                            </div>
                        </div>
                        <div className={'productContainer'}>
                            {this.props.products && this.props.products?.map((product,index)=>{
                                return(<div className={'productRow'} key={index}>
                                    <p>{`${product?.designation?.name} (${product?.type})`}</p>
                                    <p>{`${this.props?.quantities[index]?.quantity ? this.props?.quantities[index]?.quantity : 0} / ${product?.unit}`}</p>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
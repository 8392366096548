import React, {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import * as measuresActions from '../../actions/measures';
import {withRouter} from 'react-router';
import {Client, ClientObject} from '../../types/client.type';
import TableBbros from '../../components/Table/TableBbros';
import {ARCHIVED_PRODUCTS_COLUMNS, OPTIONS} from '../../components/Table/TableConstants';
import MikaSelect from "../../components/BbrosSelect/mikaSelect.component";
import {SelectOption} from "../../types/selectOption.type";
import FontAwesome from 'react-fontawesome';
import moment from "moment";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import {Button, EButtonType} from "../../components/Button";
import {toast} from "react-toastify";
import TranslatedText, {translatedText} from "../../components/TranslatedText";
import EditIcon from "@material-ui/icons/Edit";
import AttachFile from "@material-ui/icons/AttachFile";
import TimeTracker from "../../components/TimeTracker/TimeTracker";
import {BLUEPRINTING, isMobile, pauseReasons, POSTPROCESING, shipmentDestinations} from "../../constants/constants";
import HotkeyButton from "../../components/HotkeyButton/HotkeyButton";
import {Input} from "../../components/Input/Input";
import MikaFileInput from "../../components/Input/MikaFileInput/MikaFileInput";
import PlusIconLink from "../../assets/icons/plus.svg";
import {convertNumberToHMS} from "../Admin/globalFunctions";


interface Props {
    intl: any;
    history: any;
    clientActions: any;
    measuresActions: any;
    measures: any
    actions: any;
    letter: string,
    clientsByModuleData: Array<Client>,
    loading: boolean,
    archivedProducts: any
    departments: any
    buttonLoader: boolean
    permissions: any
}

const ArchivedProducts = (props: Props) => {
    const [selectedDepartment, setSelectedDepartment] = useState<SelectOption>({} as SelectOption)
    const [selectedShipmentDestination, setSelectedShipmentDestination] = useState<SelectOption>({} as SelectOption)
    const [startDate, setStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()
    const [productData, setProductData] = useState<any>(null)
    const [archivedProducts, setArchivedProducts] = useState<any>([])
    const [filtersOpened, setFiltersOpened] = useState<boolean>(false)
    const [calculatedTotalPrice, setCalculatedTotalPrice] = useState<number>(0)


    useEffect(() => {
        props.actions.getUserPermissionsAndResponsibilities()
        props.actions.getArchivedProducts()
        props.actions.getAllDepartments()
    }, [])

    useEffect(() => {
        if(props?.archivedProducts?.length !== 0){
            let tempCalcTotalPrice = 0
            let tempArray = []
            props?.archivedProducts?.forEach((product)=>{
                let tempObj:any = {
                    clientName: product?.client?.name,
                    projectName: product?.project?.name,
                    productName: `${product?.designation?.name} (${product?.type})`,
                    files: product?.productFiles,
                    quantity: product?.quantity,
                    trackersStart: product?.trackersStart,
                    trackersEnd: product?.trackersEnd,
                    price: product?.discountPrice ? product?.discountPrice : product?.totalPrice,
                    duration: product?.totalDuration ?  convertNumberToHMS(product?.totalDuration) : '00h:00m:00s'
                }
                tempCalcTotalPrice += +(product?.discountPrice ? product?.discountPrice : product?.totalPrice)
                tempArray.push(tempObj)
            })
            setCalculatedTotalPrice(tempCalcTotalPrice)
            setArchivedProducts(tempArray)
        }
    }, [props?.archivedProducts]);

    function filterData(){
        if(selectedDepartment?.value || (startDate && endDate)){
            props.actions.getArchivedProducts(selectedDepartment?.value ? selectedDepartment?.value : null,selectedShipmentDestination?.value ? selectedShipmentDestination?.value : null, startDate, endDate)
            setFiltersOpened(false)
        }else{
            if(!selectedDepartment?.value){
                props.actions.getArchivedProducts()
                setFiltersOpened(false)
                return
            }
            props.actions.getArchivedProducts()
        }
    }

    function lookAtTheFiles (product){
        if(product?.files?.length === 0){
            toast.info('Ky produkt nuk ka foto!')
            return
        }
        setProductData(product)
    }

    function clearProductData (){
        setProductData(null)
    }


    const actions = [
        {
            icon: AttachFile,
            tooltip: 'Files',
            onClick: (event: Event, rowData: ClientObject) => lookAtTheFiles(rowData)
        }
    ]

    return (
        <div className="wrapper">
            <div className={`productionFilterButton`}>
                <div className={'w-100 h-100 position-relative'}>
                    <div className={'filterIconContainer'} onClick={() => setFiltersOpened(!filtersOpened)}>
                        <FontAwesome name={'filter'}/>
                    </div>
                    <div className={`filterDropdownContainer ${filtersOpened ? 'show' : 'hide'}`}>
                        <div className={'filterWrapper'}>
                            <div className={'closeFiltersButton'} onClick={() => setFiltersOpened(!filtersOpened)}>
                                <FontAwesome name={'close'}/>
                            </div>
                            <MikaSelect options={props?.departments} placeholder={'Reparti'} value={selectedDepartment}
                                        setEntireOptionFunction={setSelectedDepartment}/>
                            <MikaSelect options={shipmentDestinations} placeholder={'Destinacioni'} value={selectedShipmentDestination}
                                        setEntireOptionFunction={setSelectedShipmentDestination}/>
                            <div
                                className={`dateContainer`}>
                                <p className={'label'}>Data e fillimit</p>
                                <p className={'dateValue'}>{startDate ? moment(startDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                {startDate && <img src={DifferentColoredPlus} onClick={() => setStartDate('')}
                                                   className="x-signDate"/>}
                                <div className={'calendar'}>
                                    <input type="date" value={startDate}
                                           onChange={(e) => setStartDate(e?.target?.value)}/>
                                    <div className={'calendarIcon'}>
                                        <FontAwesome
                                            name="calendar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`dateContainer`}>
                                <p className={'label'}>Data e mbarimit</p>
                                <p className={'dateValue'}>{endDate ? moment(endDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                {endDate &&
                                <img src={DifferentColoredPlus} onClick={() => setEndDate('')} className="x-signDate"/>}
                                <div className={'calendar'}>
                                    <input type="date" value={endDate} onChange={(e) => setEndDate(e?.target?.value)}/>
                                    <div className={'calendarIcon'}>
                                        <FontAwesome
                                            name="calendar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <Button
                                isLoading={props?.buttonLoader}
                                isDisabled={props?.buttonLoader}
                                className="m-auto w-100"
                                onClick={filterData}
                                buttonType={EButtonType.GreyButton}>
                                Filtro
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {productData && <div className={"productFilesModalWrapper"}>
                <div className="productFilesModal">
                    <HotkeyButton
                        keyCode="Escape"
                        modifiers={[]}
                        isActive={false}
                        onStateChange={(state) => {
                            if (state) {
                                clearProductData()
                            }
                        }}
                    >
                        <div className={'x-sign'}>
                            <img src={DifferentColoredPlus} alt="" onClick={() => {
                                clearProductData()
                            }}/>
                        </div>
                    </HotkeyButton>
                    <div className={'blueprintsFileUploadsContainer d-flex justify-content-between'}>
                        <div className={`w-100 allFileContainer`}>
                            {productData?.files?.length !== 0 && productData?.files?.map((productUpload) => {
                                return (
                                    <div className={'blueprint-file-container'}>
                                        <a target={'_blank'}
                                           href={productUpload?.blueprint}>{productUpload?.nameOfBlueprint}</a>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>}

            <TableBbros
                title={translatedText({id: "table.measure", defaultMessage: "Arkiva e produkteve"})}
                data={archivedProducts}
                columns={ARCHIVED_PRODUCTS_COLUMNS}
                actions={actions}
                options={OPTIONS}
                isLoading={props.loading}
            />
            {props.permissions?.fullPrivileges && <div className={'totalPriceContainerForWarehouse'}>
                <p><TranslatedText id="total"
                                   defaultMessage="Totali: "/></p>
                <p>{calculatedTotalPrice?.toFixed(2)} €</p>
            </div>}
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        measures: state.measures
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        clientActions: bindActionCreators(clientActions as any, dispatch),
        measuresActions: bindActionCreators(measuresActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ArchivedProducts)))

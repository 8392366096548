import React, {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import * as measuresActions from '../../actions/measures';
import './Measures.scss'
import {withRouter} from 'react-router';
import {Client, ClientObject} from '../../types/client.type';

import TableBbros from '../../components/Table/TableBbros';
import {
    COLUMNS,
    COLUMNS_MOBILE,
    MEASURE_ARCHIVE_RECORD_COLUMNS_MOBILE, MEASURE_COLUMNS, MEASURE_COLUMNS_MOBILE,
    OPTIONS
} from '../../components/Table/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import MikaSelect from "../../components/BbrosSelect/mikaSelect.component";
import {SelectOption} from "../../types/selectOption.type";
import {MEASURE_STATUSES} from "../../constants/constants";
import {translatedText} from "../../components/TranslatedText";
import {useParams} from "react-router-dom";
import {ParameterTypes} from "../../types/parameter.type";
import {getClientsForMeasure} from "../../actions/generalActions";
import moment from "moment";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import {Button, EButtonType} from "../../components/Button";
import FontAwesome from 'react-fontawesome';
import {toast} from "react-toastify";


interface Props {
    intl: any;
    history: any;
    clientActions: any;
    measuresActions: any;
    measures: any
    actions: any;
    letter: string,
    clientsByModuleData: Array<Client>,
    loading: boolean,
    buttonLoader: boolean
    statusId1Content: any
    statusId2Content: any
    statusId3Content: any
}

const ShowAllMeasures = (props: Props) => {
    const {statusId} = useParams <ParameterTypes>()
    const [selectedStatus, setSelectedStatus] = useState<SelectOption>({} as SelectOption)
    const [startDate, setStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()
    const [filtersOpened, setFiltersOpened] = useState<boolean>(false)

    const measureTableTitles = {
        1: translatedText({id: "table.initialMeasures", defaultMessage: "Matjet fillestare"}),
        2: translatedText({id: "table.finalMeasure", defaultMessage: "Matjet finale"}),
        3: translatedText({id: "table.waitingMeasures", defaultMessage: "Matjet ne pritje"})
    }

    const measureTableContent = {
        1: props?.statusId1Content,
        2: props?.statusId2Content,
        3: props?.statusId3Content
    }

    useEffect(() => {
        getClients()
    }, [statusId])

    function filterData(){
        if(selectedStatus?.value || (startDate && endDate)){
            getClients()
            setFiltersOpened(false)
        }else{
            if(!selectedStatus?.value){
                getClients()
                setFiltersOpened(false)
                return
            }
            getClients()
        }
    }

    function getClients(){
        props?.actions?.getClientsForMeasure(selectedStatus?.value || statusId, startDate, endDate)
    }

    function editMeasureData (client: any) {
        props.history.push({pathname: `/dashboard/measures/create/${client?.project?.value}/${client?.clientId}`})
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData: ClientObject) => editMeasureData(rowData)
        }
    ]

    return (
        <div className="wrapper allModuleContent">
            <div className={`productionFilterButton`}>
                <div className={'w-100 h-100 position-relative'}>
                    <div className={'filterIconContainer'} onClick={() => setFiltersOpened(!filtersOpened)}>
                        <FontAwesome name={'filter'}/>
                    </div>
                    <div className={`filterDropdownContainer ${filtersOpened ? 'show' : 'hide'}`}>
                        <div className={'filterWrapper'}>
                            <div className={'closeFiltersButton'} onClick={() => setFiltersOpened(!filtersOpened)}>
                                <FontAwesome name={'close'}/>
                            </div>
                            {!statusId && <MikaSelect options={MEASURE_STATUSES} placeholder={'Statusi'} value={selectedStatus}
                                         setEntireOptionFunction={setSelectedStatus}/>}
                            <div
                                className={`dateContainer`}>
                                <p className={'label'}>Data e fillimit</p>
                                <p className={'dateValue'}>{startDate ? moment(startDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                {startDate && <img src={DifferentColoredPlus} onClick={() => setStartDate('')}
                                                   className="x-signDate"/>}
                                <div className={'calendar'}>
                                    <input type="date" value={startDate}
                                           onChange={(e) => setStartDate(e?.target?.value)}/>
                                    <div className={'calendarIcon'}>
                                        <FontAwesome
                                            name="calendar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`dateContainer`}>
                                <p className={'label'}>Data e mbarimit</p>
                                <p className={'dateValue'}>{endDate ? moment(endDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                {endDate &&
                                <img src={DifferentColoredPlus} onClick={() => setEndDate('')} className="x-signDate"/>}
                                <div className={'calendar'}>
                                    <input type="date" value={endDate} onChange={(e) => setEndDate(e?.target?.value)}/>
                                    <div className={'calendarIcon'}>
                                        <FontAwesome
                                            name="calendar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <Button
                                isLoading={props?.buttonLoader}
                                isDisabled={props?.buttonLoader}
                                className="m-auto w-100"
                                onClick={filterData}
                                buttonType={EButtonType.GreyButton}>
                                Filtro
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <TableBbros
                className={'firstColumnThinner'}
                title={statusId ? measureTableTitles[statusId] : translatedText({id: "table.measure", defaultMessage: "Matjet"})}
                data={statusId ? measureTableContent[statusId] : props?.clientsByModuleData}
                columns={window.innerWidth > 500 ? MEASURE_COLUMNS : MEASURE_COLUMNS_MOBILE}
                options={OPTIONS}
                actions={actions}
                isLoading={props.loading}
            />
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        measures: state.measures
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        clientActions: bindActionCreators(clientActions as any, dispatch),
        measuresActions: bindActionCreators(measuresActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ShowAllMeasures)))

import React, {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as serviceActions from '../../actions/service';
import * as transportActions from '../../actions/transport';
import * as installationActions from '../../actions/installation';
import * as impressionActions from '../../actions/impression';
import * as adminActions from '../../actions/admin'
import "./Impression.scss";
import {withRouter} from 'react-router';
import {User} from '../../types/user.type';
import Spinner from '../../components/Spinner';
import {ArticleInfomation} from '../../types/ArticleInformation.type';
import {SelectOption} from '../../types/selectOption.type';
import {Button, EButtonType} from "../../components/Button";
import {useParams} from "react-router-dom";
import {ParameterTypes} from "../../types/parameter.type";
import Tooltip from "rc-tooltip";
import clearFormSvg from "../../assets/icons/archeology.svg";
import {EInputTextType, Input} from "../../components/Input/Input";
import Star from "../../assets/icons/star.png";
import StarBorder from "../../assets/icons/startBorder.png"
import Arrow from '../../assets/icons/Icon_awesome_arrow_alt_circle_right.svg'
import Select from "react-select";
import {isMobile} from "../../constants/constants";
import TranslatedText, {translatedText} from "../../components/TranslatedText";
import {getImpressionByProjectId} from "../../actions/impression";

interface Props {
    intl: any;
    setLoading: boolean;
    actions: any;
    clientActions: any;
    client: any;
    loading: boolean;
    articles: ArticleInfomation[];
    app: any;
    user: any;
    measureUsers: User[];
    generalActions: any;
    allClients: SelectOption[];
    countries: any[];
    citiesFields: any[];
    transport: any;
    transportActions: any;
    archiveRecords: any;
    installation: any
    installationActions: any
    history: any
    serviceActions: any
    service: any
    impressionActions: any
    impression: any
    adminActions: any
    admin: any
    buttonLoader: boolean
    permissions: any
}

const CreateImpressionForm = (props: Props) => {

    const { clientId, projectId } = useParams<ParameterTypes>();
    const [ratings, setRatings] = useState<any>([])
    const [averageRating, setAverageRating] = useState<any>(0)
    const [comment, setComment] = useState<string>('')
    const [pictureIndex, setPictureIndex] = useState<number>(0)
    const [allPictures, setAllPictures] = useState<any>()


    useEffect(()=>{
        props.adminActions.getImpressionQuestions()
    },[])

    useEffect(()=>{
        let tempArray = []
        props.admin?.impressionQuestions?.forEach(()=>{
            tempArray.push(0)
        })
    },[props.admin?.impressionQuestions])

    useEffect(()=>{
        let impressionInfo = props.impression?.fetchedImpression
        let tempRatingArray = []
        if(impressionInfo?.impression){
            setComment(impressionInfo?.impression?.feedback)
            impressionInfo?.impression?.questionFields?.forEach((question)=>{
                tempRatingArray.push({
                    ratingValue: question?.questionRating,
                    questionId: question?.impressionQuestionId
                })
            })
            setRatings(tempRatingArray)
        }
        let tempFileArray = []
        impressionInfo?.installationFiles?.forEach((file)=>{
            if(file?.installationFile){
                file?.installationFile?.forEach((actualInstallationFile)=>{
                    tempFileArray.push({id: actualInstallationFile.id, fileUrl: actualInstallationFile?.installationFileWithUrl, name: actualInstallationFile?.installationFileName})
                })
            }
        })
        setAllPictures(tempFileArray)
    },[props?.impression?.fetchedImpression])

    useEffect(()=>{
        if(projectId){
            props.impressionActions.getImpressionByProjectId(projectId)
        }else{
            resetFields()
        }
    },[projectId])

    useEffect(()=>{
        let tempRatingTotalPoints = 0
        ratings?.forEach((rating)=>{
            tempRatingTotalPoints += rating?.ratingValue
        })
        if(ratings?.length !== 0){
            setAverageRating(tempRatingTotalPoints / ratings?.length)
        }else{
            setAverageRating(0)
        }

    },[ratings])

    function resetFields() {
        setAllPictures([])
        setComment('')
        setRatings([])
        props.history.push('/dashboard/impression/create')
    }

    function saveImpression(){
        let formData = new FormData()

        if(projectId){
            formData.append('projectId', projectId)
        }

        if(ratings){
            ratings.forEach((rating)=>{
                console.log(rating)
                formData.append('questionFields[]', JSON.stringify(rating.questionId))
                formData.append('questionFieldValues[]', JSON.stringify(rating.ratingValue))
            })
        }
        formData.append('feedback', comment)

        if(props?.impression?.fetchedImpression?.impression?.id){
            props.impressionActions.editImpression(formData, props?.impression?.fetchedImpression?.impression?.id)
        }else{
            props.impressionActions.createImpression(formData)
        }
    }
    
    const ratingStars = [1,2,3,4,5];

    function changePicture(isIncrease){
        if(isIncrease){
            if(pictureIndex === allPictures.length - 1){
                return
            }else{
                setPictureIndex(pictureIndex + 1)
            }
        }else{
            if(pictureIndex === 0){
                return
            }else{
                setPictureIndex(pictureIndex - 1)
            }
        }
    }

    function setRatingForEachQuestion(value, questionIndex, questionId){
        let tempArray = [
            ...ratings
        ]
        tempArray[questionIndex] = {
            ratingValue: value,
            questionId: questionId
        }
        setRatings(tempArray)
    }

    function createServiceForm() {
        return (
            <div className={'w-100 mb-5 serviceContainer'}>
                <div className="d-flex mb-5">
                    <h1 className="main-title my-0">{props.impression?.fetchedImpression?.client?.name} ({props.impression?.fetchedImpression?.name})</h1>
                    {/*<Tooltip placement="right" overlay={<span><TranslatedText id="app.clearForm"*/}
                    {/*                                                            defaultMessage="Pastro Formen"/></span>}>*/}
                    {/*    <img className="create-client-icon" onClick={resetFields} src={clearFormSvg}*/}
                    {/*         alt="create client"/>*/}
                    {/*</Tooltip>*/}
                </div>
                <div className={`contentContainer d-flex ${isMobile ? 'flex-column' : ''}`}>
                    <div className={`${isMobile ? 'w-100': 'w-50'} `}>
                        {allPictures && <div className={`gallery p-5`}>
                            <img onClick={() => changePicture(false)} className={'arrowIcons left'} src={Arrow} alt=""/>
                            <img className={'mainImage'} src={allPictures[pictureIndex]?.fileUrl} alt=""/>
                            <img onClick={() => changePicture(true)} className={'arrowIcons right'} src={Arrow} alt=""/>
                        </div>}
                        <Input
                            name="comments"
                            label="label"
                            id="comments"
                            defaultValue=''
                            inputWrapperClass={"textarea-wrapper"}
                            type="textarea"
                            inputTextType={EInputTextType.SimpleInput}
                            value={comment || ''}
                            onChange={(name, value)=>{setComment(value)}}
                        >
                            <p className={'m-0 font-weight-bold'}><TranslatedText id="app.agreements.appendFinalAgreement" defaultMessage="Vleresimi nga Klienti"/></p>
                        </Input>
                    </div>
                    <div className={`${isMobile ? 'w-100': 'w-50'} impressionQuestionsContainer`}>
                        {props.admin?.impressionQuestions && props.admin?.impressionQuestions?.map((question, index)=>{
                            return (
                                <div className={'questionContainer'}>
                                    <p>{question.label}</p>
                                    <div className={'starRatingContainer'}>
                                        { ratingStars.map((value)=>{
                                            return (
                                                <div className={'starWrapper'} key={value} onClick={()=>setRatingForEachQuestion(value, index, question?.value)}>
                                                    {ratings[index]?.ratingValue + 1 > value ?
                                                        <img src={Star} alt=""/>
                                                        :
                                                        <img src={StarBorder} alt=""/>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                        <div className={'questionContainer'}>
                            <p><TranslatedText id="averagePoints" defaultMessage="Mesatarja e pikeve"/></p>
                            <div className={'starRatingContainer averageContainer'}>
                                <p>{averageRating?.toFixed(2)}</p>
                                <div className={'starWrapper'} >
                                    <img src={Star} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'w-100 d-flex justify-content-end'}>
                    {((!props.permissions.observer) || props.permissions.fullPrivileges) &&<Button
                        isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                        onClick={saveImpression}
                        className="my-5 w-50"
                        buttonType={EButtonType.PurpleButton}>
                        <TranslatedText id="client.editContact"
                                        defaultMessage="Ruaj"/>
                    </Button>}
                </div>
            </div>
        )
    }

    return (
        <div className="wrapper">
            {props.loading ? <Spinner/> : createServiceForm()}
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        service: state.service,
        installation: state.installation,
        transport: state.transport,
        impression: state.impression,
        admin: state.admin
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        serviceActions: bindActionCreators(serviceActions as any, dispatch),
        transportActions: bindActionCreators(transportActions as any, dispatch),
        installationActions: bindActionCreators(installationActions as any, dispatch),
        impressionActions: bindActionCreators(impressionActions as any, dispatch),
        adminActions: bindActionCreators(adminActions as any, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateImpressionForm as any)))
import React, {useState, useEffect} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../../reducers';
import * as actions from '../../../actions/generalActions';
import * as adminActions from '../../../actions/admin';
import {withRouter} from 'react-router';

import EditIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import {
    ADMIN_COLUMNS,
    ADMIN_COLUMNS_MOBILE,
    OPTIONS,
    USERS_DASHBOARD_COLUMNS
} from '../../../components/Table/TableConstants';
import TableBbros from '../../../components/Table/TableBbros';
import {UserObject} from '../../../types/user.type';
import {isMobile} from "../../../constants/constants";
import {translatedText} from "../../../components/TranslatedText";
import {fetchUsersDashboardInformation} from "../../../actions/admin";
import HotkeyButton from "../../../components/HotkeyButton/HotkeyButton";
import DifferentColoredPlus from "../../../assets/icons/differentColoredPlus.svg";
import './usersDashboard.scss'
import Tooltip from "rc-tooltip";

interface Props {
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history: any;
    actions: any,
    letter: string,
    admin: any,
    adminActions: any
}

const UsersDashboard = (props: Props) => {

    const [users, setUsers] = useState<any>()
    const [selectedUserInformation, setSelectedUserInformation] = useState(null)

    const statuses = {
        1: 'completed',
        2: 'toDo',
        3: 'late',
        4: 'inProcess',
        5: 'paused',
        6: 'paused',
        7: 'otherWork',
        8: 'paused',
        9: 'nonActive'
    }

    const userStatuses = [
        {
            value: 1,
            label: 'Aktiv'
        },
        {
            value: 2,
            label: 'Jo aktiv'
        },
        {
            value: 3,
            label: 'Pasiv'
        },
    ]

    const userStatusesClasses = {
        1: 'active',
        2: 'nonActive',
        3: 'passive'
    }

    useEffect(() => {
        props.adminActions.fetchUsersDashboardInformation()
    }, [])

    useEffect(() => {
        let tempArray = []

        props?.admin?.usersDashboardInformation?.forEach((user) => {
            let tempStatus = userStatuses[2]
            let allStatuses = [
                ...user?.productStatuses,
                ...user?.materialStatuses
            ]
            let isWorking = allStatuses?.find((status)=> status?.responsibilityStatusId === 4)

            if(isWorking){
                tempStatus = userStatuses[0]
            } else if(allStatuses?.length != 0){
                tempStatus = userStatuses[1]
            }
            tempArray.push({
                ...user,
                allStatuses,
                status: tempStatus
            })
        })

        setUsers(tempArray)

    }, [props?.admin?.usersDashboardInformation])

    const editUserData = (user) => {
        console.log(user, 'user')
        setSelectedUserInformation(user)
        // props.history.push({pathname: `/admin/create-user/${user?.id}`})
    }


    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData: UserObject) => editUserData(rowData)
        }
    ]

    function closeUserActivityModal() {
        setSelectedUserInformation(null)
    }

    return (
        <div>
            <div className="wrapper usersDashboard">
                <TableBbros
                    title={translatedText({id: "table.admin", defaultMessage: "Admin"})}
                    data={users}
                    columns={USERS_DASHBOARD_COLUMNS}
                    options={OPTIONS}
                    actions={actions}
                    isLoading={props.loading}
                />
                {selectedUserInformation && <div className={'userActivityModal-wrapper'}>
                    <div className={'userActivityModal'}>
                        <div className="x-sign">
                            <HotkeyButton
                                keyCode="Escape"
                                modifiers={[]}
                                isActive={false}
                                onStateChange={(state) => {
                                    if (state) {
                                        closeUserActivityModal()
                                    }
                                }}
                            >
                                <img src={DifferentColoredPlus} alt="" onClick={() => {
                                    closeUserActivityModal()
                                }}/>
                            </HotkeyButton>
                        </div>
                        <div className={'userActivityContent'}>
                            <div className={'w-100 d-flex'}>
                                <div className={'w-75'}>
                                    <h2>{selectedUserInformation?.name}</h2>
                                </div>
                                <div className={'w-25'}>
                                    <p className={`statusLabel ${userStatusesClasses[selectedUserInformation?.status?.value]}`}>{selectedUserInformation?.status?.label}</p>
                                </div>
                            </div>
                            <div className={'listedActivities'}>
                                {selectedUserInformation?.allStatuses?.map((status) => {
                                    console.log(status)
                                    return (<div className={'status-row'}>
                                        <div className={'projectProductNames'}>
                                            <div className={'projectName'}>
                                                <p>{status?.project?.name}</p>
                                            </div>
                                            <div className={'articleName'}>
                                                <p>{status?.article?.name}</p>
                                            </div>
                                        </div>
                                        {/*<Tooltip placement="top" overlay={<span>{}</span>}>*/}
                                            <div className={`status-box ${statuses[status?.responsibilityStatusId]}`}>
                                                <p>{status?.responsibility?.name}{status?.material?.name ? `(${status?.material?.name})` : ""}</p>
                                            </div>
                                        {/*</Tooltip>*/}
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        admin: state.admin,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        adminActions: bindActionCreators(adminActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch)
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(UsersDashboard as any)));
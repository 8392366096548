import React from 'react'
import { FormattedMessage } from 'react-intl';
import XIcon  from '../../assets/icons/close-icon.svg'
import "./DeleteModal.scss"
import { Button, EButtonType } from '../Button';
import TranslatedText from "../TranslatedText";

interface Props {
    history: any;
    close: () => void;
    deleteFunction?: () => void;
    children?: any
}

interface State {

}

class DeleteModalComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
            <div className="delete-modal">
                <div className="d-flex flex-column align-items-center w-75">
                    <p className="medium-text questionary-title">
                        <TranslatedText id="areYouSure" defaultMessage="A jeni i sigurte?" />
                    </p>
                    <div className={'childrenContainer'}>
                        {this.props?.children}
                    </div>
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-5">
                                <Button onClick={this.props.close}
                                    submit={true}
                                    className="ml-0 col-12 align-items-center mb-3 px-3 text-center"
                                    buttonType={EButtonType.GreyButton}>
                                    <TranslatedText id="no" defaultMessage="Jo" />
                                </Button>
                            </div>
                            <div className="col-5">
                                <Button
                                    onClick={() => this.props.deleteFunction()}
                                    submit={true}
                                    className="ml-0 col-12 align-items-center mb-3 px-3 text-center"
                                    buttonType={EButtonType.PurpleButton}>
                                    <TranslatedText id="yes" defaultMessage="Po" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DeleteModalComponent
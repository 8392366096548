import classNames from 'classnames';
import * as React from 'react'
import "./Wrapper.scss"

interface Props {
    children?: any,
    className?: any,
    isNavOpen?: boolean;
}

export default class Wrapper extends React.Component<Props> {
    render() {
        const {children, className} = this.props;
        return (
            <div className={classNames(`content-wrapper ${className}`, {
                'collapsed': this.props.isNavOpen
            })}>
                {children}
            </div>
        )
    }
}

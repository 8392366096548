import { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, EButtonType } from "../../components/Button";
import MikaFileInput from "../../components/Input/MikaFileInput/MikaFileInput";
import { RootState } from "../../reducers";
import { Dispatch, bindActionCreators } from 'redux';
import * as actions from '../../actions/generalActions';
import * as measureActions from '../../actions/measures';
import { connect } from "react-redux";
import TableBbros from "../../components/Table/TableBbros";
import { OPTIONS, TEMPLATE_COLUMNS } from "../../components/Table/TableConstants";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ModalComponent from "../../components/Modal/ModalComponent";
import DeleteModalComponent from "../../components/Modal/DeleteModal";
import { measureTemplateObj } from "../../reducers/measures";
import Tooltip from "rc-tooltip";
import clearFormSvg from "../../assets/icons/archeology.svg";
import {toast} from "react-toastify";
import {isMobile} from "../../constants/constants";
import TranslatedText, {translatedText} from "../../components/TranslatedText";


interface Props {
    admin: any;
    intl: any;
    measureActions: any;
    measures: any;
    loading: boolean;
    history: any;
    buttonLoader: boolean
}

export type File = {
    lastModified: number,
    lastModifiedDate: string,
    name: string,
    size: number,
    type: string,
    webkitRelativePath: string,
}

type FileObject = {
    file: File
    fileName: string
}

const MeasurmentSketchTemplate = (props: Props) => {

    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);
    const [templateID, setTemplateID] = useState<number>(0);
    const [addSketch, setAddSketch] = useState({
        employerId: "",
        fileName: "",
        file: null
    })

    useEffect(() => {
        props.measureActions.getAllMeasureTemplates()
    }, [])

    const saveMeasureTemplates = () => {

        let formData = new FormData();

        if (addSketch.file) {
            formData.append("template", addSketch.file);
        }else{
            toast.error('Ju lutem nkarkoni file-in!')
        }
        props.measureActions.createMeasureTemplate(formData).then(()=>{
            setAddSketch({
                employerId: "",
                fileName: "",
                file: null
            })
        })
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Shiko filen',
            onClick: (event: Event, rowData:measureTemplateObj) => window.open(rowData?.template, '_blank')
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData:measureTemplateObj) => handleRemove(rowData)
        },
    ]

    const handleRemove = (template: measureTemplateObj) => {
        setDeleteModal(true)
        setTemplateID(template.id)
    }

    const deleteTemplate = () => {
        setDeleteModal(false)
        props.measureActions.removeMeasureTemplate(templateID).then(() => {
            props.measureActions.getAllMeasureTemplates()
        })
    }

    function resetFields() {
        setAddSketch({
            employerId: "",
            fileName: "",
            file: null
        })
    }

    return (
        <div className="admin-page">
            <div className={`create-admin-page-form ${isMobile ? 'w-100':'w-25'}`}>
                <div className="d-flex align-items-center">
                    <MikaFileInput 
                        labelKey={translatedText({
                            id:"offer.addPDF",
                            defaultMessage:"Shto nje skice"
                        })}
                        value={addSketch}
                        setFiles={setAddSketch} 
                        className={'mt-3 w-100'}
                        accept="application/pdf"
                        />
                </div>
                <Button
                    isLoading={props?.buttonLoader}
                    submit={true}
                    isDisabled={addSketch.file === null}
                    className="w-100"
                    buttonType={EButtonType.GreyButton}
                    onClick={() => saveMeasureTemplates()}
                    >
                        <TranslatedText id="app.navigation.addSketches" defaultMessage="Shto skica" />
                </Button>
            </div>
            <div className="admin-page-list">
                <h2 className="m-0 mb-4">
                    <TranslatedText id="client.offerFieldsList" defaultMessage="Lista e fushave te ofertes" />
                    <Tooltip placement="right" overlay={<span><TranslatedText id="app.clearForm" defaultMessage="Pastro Formen"/></span>}>
                            <img className="create-client-icon" onClick={resetFields} src={clearFormSvg}
                                 alt="create client"/>
                    </Tooltip>
                </h2>
                <TableBbros
                    title={translatedText({ id:"table.template", defaultMessage:"Template"})}
                    data={props.measures.measureTemplates}
                    columns={TEMPLATE_COLUMNS}
                    options={OPTIONS}
                    actions={actions}
                    isLoading={props.loading}
                />
            </div>
                <ModalComponent
                    isModalHeader
                    isOpen={isDeleteModalOpen}
                    close={setDeleteModal}
                    >
                    <DeleteModalComponent
                        history={props.history} 
                        close={() => setDeleteModal(false)}
                        deleteFunction={() => deleteTemplate()} />
                </ModalComponent>
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        admin: state.admin,
        measures: state.measures
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        measureActions: bindActionCreators(measureActions as any, dispatch)
    }
}

export default connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(MeasurmentSketchTemplate));
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import { RootState } from '../../reducers';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as warehouseActions from "../../actions/warehouse";
import TableBbros from "../../components/Table/TableBbros";
import { OPTIONS, WAREHOUSE_PRODUCTS_COLUMNS } from "../../components/Table/TableConstants";
import { ArticleInfomation } from "../../types/ArticleInformation.type";
import TranslatedText, {translatedText} from "../../components/TranslatedText";

interface Props {
    history: any;
    intl: any;
    client: any;
    warehouse: any;
    warehouseActions: any;
    loading: boolean;
    actions: any;
    articles: ArticleInfomation[]
}

const WarehouseProducts = (props: Props) => {

    useEffect(() => {
        props.warehouseActions.getWarehouseProducts();
    }, [])

    return (
        <div>
            <div className="warehouseProducts warehouseListMobileDesign">
                <h2 className="m-0 mb-4">
                    <TranslatedText id="client.articlesList" defaultMessage="Lista e artikujve" />
                </h2>
                <TableBbros
                    title={translatedText({ id: "table.artikulli", defaultMessage: "Artikulli" })}
                    data={props.warehouse?.warehouseProducts}
                    columns={WAREHOUSE_PRODUCTS_COLUMNS}
                    options={OPTIONS}
                    isLoading={props.loading}
                />
            </div>
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        warehouse: state.warehouse
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        warehouseActions: bindActionCreators(warehouseActions as any, dispatch),
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(injectIntl(WarehouseProducts)));
import React, {ReactNode, useCallback, useEffect} from 'react'
import './hotkeyButton.scss'
export enum Modifier {
    CTRL,
    META,
    SHIFT,
    ALT
}

interface Props {
    children: ReactNode // The inner contents of the button (for example F2, CTRL+C etc.).
    keyCode: string // The literal JS keycode to detect.
    modifiers?: Modifier[] // Any additional modifier buttons to be required for the shortcut to be valid.
    description?: string // Description (the text to the right of the component) of the action.
    isActive: boolean // Current state of the component.
    onStateChange: (active: boolean) => void // Callback to notify the parent about a shortcut being detected/released.
}

const HotkeyButton = ({
                          keyCode,
                          modifiers = [],
                          description = '',
                          isActive,
                          onStateChange,
                          children
                      }: Props) => {
    // Detect shortcut matches.
    const checkKeysPressed = useCallback(
        (event: KeyboardEvent) => {
            // Are all the desired keys pressed.
            const active =
                event.key.toLowerCase() === keyCode.toLowerCase() &&
                event.type === 'keydown' &&
                ((modifiers.includes(Modifier.META) && event.metaKey) ||
                    (!modifiers.includes(Modifier.META) && !event.metaKey)) &&
                ((modifiers.includes(Modifier.ALT) && event.altKey) ||
                    (!modifiers.includes(Modifier.ALT) && !event.altKey)) &&
                ((modifiers.includes(Modifier.CTRL) && event.ctrlKey) ||
                    (!modifiers.includes(Modifier.CTRL) && !event.ctrlKey)) &&
                ((modifiers.includes(Modifier.SHIFT) && event.shiftKey) ||
                    (!modifiers.includes(Modifier.SHIFT) && !event.shiftKey))
            // Notify parent.
            onStateChange(active)
        },
        [keyCode, onStateChange, modifiers]
    )

    // Listen for keyboard events.
    useEffect(() => {
        window.addEventListener('keyup', checkKeysPressed)
        window.addEventListener('keydown', checkKeysPressed)
        return () => {
            // Remove listeners on unmount.
            window.removeEventListener('keyup', checkKeysPressed)
            window.removeEventListener('keydown', checkKeysPressed)
        }
    }, [checkKeysPressed])

    return (
        <>
            <div className={'hotkeyButtonContainer'}>
                {/* The button. */}
                <div className={'hotkeyButton'}>
                    {children}
                </div>
                {/* Description of the action. */}
                <p>
                    {description}
                </p>
            </div>
        </>
    )
}

export default HotkeyButton

import * as constants from "../constants/constants";
import { Responsibility } from '../types/responsibility.type';

export interface TransportState {
    equipments: Responsibility[]
    transportEquipments: any
    transportRecords: any
    transportRecord: any
    transportRecordsByCode: any
    transportProjects: any
    warehouseRecordsReadyForTransport: any
    projectsForTransport: any
    selectedWarehouseRecordForTransport: any
}

const initialState = () => {
    return {
        equipments: [],
        transportEquipments: [],
        transportRecords: [],
        transportRecord: {},
        transportRecordsByCode: [],
        transportProjects: [],
        warehouseRecordsReadyForTransport:[],
        projectsForTransport: [],
        selectedWarehouseRecordForTransport: {}
    }
}

const admin = (state: TransportState = initialState(), action: any) => {
    switch (action.type) {
        case constants.FETCH_EQUIPMENTS: {
            return { 
                ...state, 
                equipments: action.payload
            };
        }
        case constants.FETCH_TRANSPORT_EQUIPMENTS: {
            let equipments = []
            action?.payload?.forEach((project)=>{
                project.offers.products.forEach((product)=>{
                    product.equipments.forEach((equipment)=>{
                        let tempObj = equipments.find(item => item.value === equipment.value)
                        if(!tempObj){
                            equipments.push(equipment)
                        }
                    })
                })
            })
            return {
                ...state,
                transportEquipments: equipments
            };
        }
        case constants.FETCH_TRANSPORT_RECORDS: {
            return {
                ...state,
                transportRecords: action.payload
            };
        }
        case constants.FETCH_PROJECTS_FOR_TRANSPORT: {
            let tempArray = []
            action.payload?.forEach((warehouseRecord)=>{
                let tempObj = {
                    ...warehouseRecord,
                    clientName: warehouseRecord?.client?.clientName,
                    projectName: warehouseRecord?.project?.name,
                    country: warehouseRecord?.client?.country,
                    city: warehouseRecord?.client?.city,
                    address: warehouseRecord?.client?.address,
                    measureComment: warehouseRecord?.measure?.comment,
                    measureLocation: warehouseRecord?.measure?.location,
                    label: warehouseRecord?.label ? warehouseRecord?.label : '',
                    id: warehouseRecord?.project?.id,
                    value: warehouseRecord?.project?.id,
                    warehouseRecordId: warehouseRecord?.id
                }
                tempArray.push(tempObj)
            })
            return {
                ...state,
                projectsForTransport: tempArray
            }
        }
        case constants.FETCH_TRANSPORT_RECORD: {
            return {
                ...state,
                transportRecord: action.payload
            };
        }
        case constants.FETCH_TRANSPORT_RECORD_CODE: {
            return {
                ...state,
                transportRecordsByCode: action.payload
            };
        }
        case constants.FETCH_TRANSPORT_PROJECTS: {
            return {
                ...state,
                transportProjects: action.payload
            };
        }
        case constants.GET_WAREHOUSE_RECORDS_READY_FOR_TRANSPORT: {
            return {
                ...state,
                warehouseRecordsReadyForTransport: action.payload
            };
        }
        case constants.SET_SELECTED_WAREHOUSE_RECORD_FOR_TRANSPORT: {
            return {
                ...state,
                selectedWarehouseRecordForTransport: action.payload
            };
        }
    }
    return state;
}

export default admin;
import * as React from 'react';
import { IInputConfig } from '../Input';
import { defined } from '../../../utils/variableEvaluation';

import styles from './InputRadio.module.scss'; // Import css modules stylesheet as styles

interface IProps {
    index: number;
    input: IInputConfig;
    forcedValue?: string;
    checked?: boolean;
    readOnly?: boolean;
    defaultChecked?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
}

export function InputRadioOption(props: IProps) {
    const {
        id,
        name,
        label,
        inputWrapperStyle,
        value,
        onChange
    } = props.input;

    const handleChange = (e: React.ChangeEvent<any>) => {
        if (!props.readOnly) {
            const name = e.target.name;
            const value = props.input.value;
            onChange && onChange(name, value);
        }
    };

    return (
        <li
            className={styles.radio}
            style={defined(inputWrapperStyle) ? inputWrapperStyle : { width: 'auto' }}>

            <input
                onChange={handleChange}
                id={id}
                readOnly={props.readOnly}
                className={styles.radioInput}
                type="radio"
                value={value}
                checked={props.checked}
                name={name}
                defaultChecked={props.defaultChecked}
                onFocus={props.onFocus}
                onBlur={props.onBlur}/>

            <label htmlFor={id}
                   className={styles.radioLabel}>
                {label}
            </label>
            {/*<i className={styles.radioInputMark} />*/}
        </li>
    );
}

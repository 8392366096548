import React from 'react';
import styles from './Modal.module.scss'
import ModalFooterComponent from './ModalFooterComponent';
import ModalHeaderComponent from './ModalHeaderComponent';
import classNames from 'classnames';
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import HotkeyButton from "../HotkeyButton/HotkeyButton";

interface Props {
    isModalHeader?: boolean;
    isModalFooter?: boolean;
    isOpen: boolean;
    children: object;
    close: (boolean) => void;
}

const ModalComponent:React.FC<Props> = (props:Props) => {
    const showHideClassName = props.isOpen ? styles.displayBlock : styles.displayNone;

    function closeModal () {
        props.close(false)
    }

    return (
        <div className={`modal ${styles.modalComponent} ${showHideClassName}`}>
            <div className={styles.modalBody}>
                {props.isModalHeader && 
                    <ModalHeaderComponent 
                        close={closeModal} />
                }
                <HotkeyButton
                    keyCode="Escape"
                    modifiers={[]}
                    isActive={false}
                    onStateChange={(state) => {
                        if(state){
                            closeModal()
                        }
                    }}
                >
                </HotkeyButton>
                <div className={styles.modalChildren}>
                    {props.children}
                </div>
                {props.isModalHeader && 
                    <ModalFooterComponent />
                }
            </div>
        </div>
    )
}
export default ModalComponent;
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import { withRouter } from 'react-router';
import './warehouse.scss'
import * as productionActions from '../../actions/production'
import DeleteModalComponent from '../../components/Modal/DeleteModal';
import ModalComponent from '../../components/Modal/ModalComponent';
import TableBbros from '../../components/Table/TableBbros';
import {
    COLUMNS,
    COLUMNS_MOBILE,
    OPTIONS,
    WAREHOUSE_PRODUCTS,
    WAREHOUSE_PRODUCTS_MOBILE
} from '../../components/Table/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { ClientObject } from '../../types/client.type';
import {isMobile, shipmentDestinations} from "../../constants/constants";
import TranslatedText, {translatedText} from "../../components/TranslatedText";
import moment from "moment";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import {Button, EButtonType} from "../../components/Button";
import FontAwesome from 'react-fontawesome';
import {toast} from "react-toastify";
import MikaSelect from "../../components/BbrosSelect/mikaSelect.component";
import {SelectOption} from "../../types/selectOption.type";



interface Props {
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history: any;
    production: any,
    productionActions: any
    actions:any;
    letter: string;
    clientsByModuleData: any;
    buttonLoader: any,
    permissions: any,
    departments: any
}

const AllWarehouseProducts = (props: Props) => {

    const moduleId = 6
    const moduleName = 'warehouse'
    const [selectedDepartment, setSelectedDepartment] = useState<SelectOption>({} as SelectOption)
    const [selectedShipmentDestination, setSelectedShipmentDestination] = useState<SelectOption>({} as SelectOption)
    const [clientId, setClientId] = useState<number>(null);
    const [warehouseProducts, setWarehouseProducts] = useState([])
    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()
    const [filtersOpened, setFiltersOpened] = useState<boolean>(false)
    const [calculatedTotalPrice, setCalculatedTotalPrice] = useState<number>(0)

    useEffect(() => {
        props.actions.getAllDepartments()
        props.productionActions.fetchWarehouseProducts()
    }, [])

    useEffect(() => {
        let tempWarehouseArray = [...props.production.warehouseProducts]
        let tempCalcTotalPrice = 0

        tempWarehouseArray.sort(function compare(a, b) {
            let firstMilliseconds = new Date(b['finishedProduct']?.updatedAt).getTime();
            let secondMilliseconds = new Date(a['finishedProduct']?.updatedAt).getTime();
            return firstMilliseconds - secondMilliseconds;
        });
        tempWarehouseArray.forEach((warehouse, index)=>{
            tempWarehouseArray[index] = {
                ...tempWarehouseArray[index],
                price: warehouse?.discountPrice ? warehouse?.discountPrice : +warehouse?.price * +warehouse?.unit,
                finishedProduct: warehouse?.finishedProduct?.updatedAt ? moment(warehouse?.finishedProduct?.updatedAt).format("DD/MM/YYYY hh:mm:ss") : ''
            }
            tempCalcTotalPrice += (warehouse?.discountPrice ? +warehouse?.discountPrice : +warehouse?.price * +warehouse?.unit)
        })
        setCalculatedTotalPrice(tempCalcTotalPrice)
        setWarehouseProducts(tempWarehouseArray)
    }, [props.production.warehouseProducts])

    const editMeasureData = (client) => {
        console.log(client)
        props.history.push({pathname: `/dashboard/warehouse/create/${client?.clientID}/${client?.project?.id}`})
    }

    function deleteWarehouse(client: ClientObject) {
        setClientId(client?.id)
        setDeleteModal(true)
    }

    const deleteAction = () => {
        props.actions.deleteModuleData(clientId, moduleName)
        setDeleteModal(false)
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData: ClientObject) => editMeasureData(rowData)
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData:ClientObject) => deleteWarehouse(rowData)
        }
    ]

    function filterData(){
        if(startDate && endDate){
            props.productionActions.fetchWarehouseProducts(startDate, endDate, selectedDepartment?.value, selectedShipmentDestination?.value)
            setFiltersOpened(false)
        }else{
            if((!startDate) && (!endDate)){
                props.productionActions.fetchWarehouseProducts(null, null, selectedDepartment?.value, selectedShipmentDestination?.value)
                return;
            }
            props.productionActions.fetchWarehouseProducts(null, null, selectedDepartment?.value, selectedShipmentDestination?.value)
        }
    }

    return(
        <div className="wrapper warehouseContainer">
            <div className={`productionFilterButton`}>
                <div className={'w-100 h-100 position-relative'}>
                    <div className={'filterIconContainer'} onClick={() => setFiltersOpened(!filtersOpened)}>
                        <FontAwesome name={'filter'}/>
                    </div>
                    <div className={`filterDropdownContainer ${filtersOpened ? 'show' : 'hide'}`}>
                        <div className={'filterWrapper'}>
                            <div className={'closeFiltersButton'} onClick={() => setFiltersOpened(!filtersOpened)}>
                                <FontAwesome name={'close'}/>
                            </div>
                            <MikaSelect options={props?.departments} placeholder={'Reparti'} value={selectedDepartment}
                                        setEntireOptionFunction={setSelectedDepartment}/>
                            <MikaSelect options={shipmentDestinations} placeholder={'Destinacioni'} value={selectedShipmentDestination}
                                        setEntireOptionFunction={setSelectedShipmentDestination}/>
                            <div
                                className={`dateContainer`}>
                                <p className={'label'}>Data e fillimit</p>
                                <p className={'dateValue'}>{startDate ? moment(startDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                {startDate && <img src={DifferentColoredPlus} onClick={() => setStartDate('')}
                                                   className="x-signDate"/>}
                                <div className={'calendar'}>
                                    <input type="date" value={startDate}
                                           onChange={(e) => setStartDate(e?.target?.value)}/>
                                    <div className={'calendarIcon'}>
                                        <FontAwesome
                                            name="calendar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`dateContainer`}>
                                <p className={'label'}>Data e mbarimit</p>
                                <p className={'dateValue'}>{endDate ? moment(endDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                {endDate &&
                                    <img src={DifferentColoredPlus} onClick={() => setEndDate('')}
                                         className="x-signDate"/>}
                                <div className={'calendar'}>
                                    <input type="date" value={endDate} onChange={(e) => setEndDate(e?.target?.value)}/>
                                    <div className={'calendarIcon'}>
                                        <FontAwesome
                                            name="calendar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <Button
                                isLoading={props?.buttonLoader}
                                isDisabled={props?.buttonLoader}
                                className="m-auto w-100"
                                onClick={filterData}
                                buttonType={EButtonType.GreyButton}>
                                Filtro
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <TableBbros
                className={'firstColumnThinner'}
                title={translatedText({id: "table.warehouseCondition", defaultMessage: "Gjendja e depos"})}
                data={warehouseProducts}
                columns={isMobile ? WAREHOUSE_PRODUCTS_MOBILE : WAREHOUSE_PRODUCTS}
                options={OPTIONS}
                actions={actions}
                isLoading={props.loading}
            />
            <ModalComponent
                isModalHeader
                isOpen={isDeleteModalOpen}
                close={setDeleteModal}
            >
                <DeleteModalComponent
                    history={props.history}
                    close={() => setDeleteModal(false)}
                    deleteFunction={() => deleteAction()}/>
            </ModalComponent>
            {props.permissions?.fullPrivileges && <div className={'totalPriceContainerForWarehouse'}>
                <p><TranslatedText id="total"
                                   defaultMessage="Totali: "/></p>
                <p>{calculatedTotalPrice?.toFixed(2)} €</p>
            </div>}
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        production: state.production,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        productionActions: bindActionCreators(productionActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(AllWarehouseProducts as any)));
import * as React from 'react';
import { InputRadioOption } from './InputRadioOption';
import { IInputConfig } from '../Input';
import styles from './InputRadio.module.scss';
import classNames from "classnames"; // Import css modules stylesheet as styles

interface IProps {
  radioOptions?: IInputConfig[];
  forcedValue?: string;
  readOnly?: boolean;
  onBlur: any;
}

export function InputRadio(props: IProps) {

    const [focused, setFocused] = React.useState(false);

      const options = props.radioOptions && props.radioOptions.map((input: IInputConfig, i: number) => (
        <InputRadioOption
          key={input.id}
          input={input}
          index={i}
          readOnly={props.readOnly}
          defaultChecked={input.defaultChecked}
          checked={props.forcedValue === input.value}
          forcedValue={input.value}
          onFocus={() => setFocused(true)}
          onBlur={() => {
              props.onBlur();
              setFocused(false)
          }}
        />
      ));

    return <ul className={classNames(styles.radioWrapper, { [styles.focused]: focused })}>{options}</ul>;
}

import React, {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import * as measuresActions from '../../actions/measures';
import * as offerActions from '../../actions/offers';
import './Offers.scss'
import {withRouter} from 'react-router';
import TableBbros from '../../components/Table/TableBbros';
import {ALL_MODULE_CONTENT_COLUMNS, CANCELED_PRODUCTS, OPTIONS} from '../../components/Table/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import {useParams} from "react-router-dom";
import {ParameterTypes} from "../../types/parameter.type";
import DeleteModalComponent from "../../components/Modal/DeleteModal";
import ModalComponent from "../../components/Modal/ModalComponent";
import DeleteIcon from "@material-ui/icons/DeleteOutline";

interface Props {
    intl: any;
    history: any;
    clientActions: any;
    offerActions: any;
    offers: any
    actions: any;
    letter: string,
    allProjects: any,
    loading: boolean,
    client: any
}

const CanceledProducts = (props: Props) => {

    useEffect(() => {
        props.offerActions.getCanceledProducts()
    }, [])

    return (
        <div className="wrapper">
            <TableBbros
                title={'Produktet e anuluara'}
                data={props?.offers?.canceledProducts}
                columns={CANCELED_PRODUCTS}
                options={OPTIONS}
                isLoading={props.loading}
            />
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        offers: state.offers
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        clientActions: bindActionCreators(clientActions as any, dispatch),
        measuresActions: bindActionCreators(measuresActions as any, dispatch),
        offerActions: bindActionCreators(offerActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CanceledProducts)))

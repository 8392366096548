import * as constants from "../constants/constants";

export interface ServiceState {
    serviceRecords: any
    projectsReadyForService: any
    fetchedService: any,
    selectedProjectForService: any,
    serviceMapData: any
}

const initialState = () => {
    return {
        serviceRecords: [],
        projectsReadyForService: [],
        fetchedService: {},
        selectedProjectForService: {},
        serviceMapData: []
    }
}

const admin = (state: ServiceState = initialState(), action: any) => {
    switch (action.type) {
        case constants.FETCH_SERVICE_RECORDS: {
            return {
                ...state,
                serviceRecords: action.payload
            };
        }
        case constants.FETCH_PROJECTS_FOR_SERVICE: {
            return {
                ...state,
                projectsReadyForService: action.payload
            };
        }
        case constants.FETCH_SERVICE: {
            return {
                ...state,
                fetchedService: action.payload
            };
        }
        case constants.SET_SELECTED_PROJECT_FOR_SERVICE: {
            return {
                ...state,
                selectedProjectForService: action.payload
            }
        }
        case constants.GET_SERVICE_MAP_DATA: {
            console.log(action.payload,'action.payload')
            return {
                ...state,
                serviceMapData: action.payload
            }
        }
    }
    return state;
}

export default admin;
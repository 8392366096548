import * as constants from '../constants/constants';
import { apiCaller, axiosCaller } from "../utils/apiCaller";
import { ACTION, wrap, setLoading, setButtonLoading }  from './generalActions'
import { toast } from 'react-toastify';
import {GET_AGREEMENTS_FOR_CALENDAR} from "../constants/constants";

export const resetAssets = (payload: any): ACTION => ({
    type: constants.RESET_ASSETS,
    payload
});

export const fetchAllClientAgreements = () => wrap(async(dispatch:any) => {
    try {
        // dispatch(setLoading(true));
        // dispatch(setButtonLoading(true));Dont setLoader to true for this request
        return await apiCaller('GET', `agreements`).then(res => {
            dispatch({ "type": constants.FETCH_ALL_CLIENT_AGREEMENTS, "payload": res });
            // dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        toast.error('Diqka nuk shkoi mire');
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_ALL_CLIENT_AGREEMENTS')

export const fetchClientAgreement = (e:number) => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true));
        dispatch(setButtonLoading(true));
        return await apiCaller('GET', `agreements/${e}`).then(res => {
            dispatch({ "type": constants.FETCH_CLIENT_AGREEMENT, "payload": res });
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            return res
        })
    } catch(err) {
        toast.error('Diqka nuk shkoi mire');
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_CLIENT_AGREEMENT')

export const createAgreement = (payload:any) => wrap((dispatch:any, state:any) => {
    try {
        return axiosCaller('POST', 'agreements', payload).then((response:any) => {
            dispatch({ "type": constants.CREATE_AGREEMENTS, payload: response })
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            return response
        });
    } catch(err) {
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_AGREEMENTS');

export const editAgreement = (payload:any) => wrap((dispatch:any, state:any) => {
    try {
        return axiosCaller('POST', `agreements`, payload).then((response:any) => {
            dispatch({ "type": constants.EDIT_AGREEMENT, payload: response })
            return response
        });
    } catch(err) {
        toast.error('Diqka nuk shkoi mire');
    }
}, 'EDIT_AGREEMENT');

export const editAgreementDates = (payload:any) => wrap((dispatch:any, state:any) => {
    try {
        dispatch(setLoading(true));
        dispatch(setButtonLoading(true));
        return axiosCaller('POST', `agreements`, payload).then((response:any) => {
            dispatch({ "type": constants.EDIT_AGREEMENT, payload: response })
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            return response
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'EDIT_AGREEMENT');

export const fetchAgreementAssets = (clientId:number) => wrap((dispatch:any, state:any) => {
    try {
        dispatch(setLoading(true));
        dispatch(setButtonLoading(true));
        return apiCaller('GET', `agreements/assets/${clientId}`).then((response:any) => {
            dispatch({ "type": constants.GET_AGREEMENT_ASSETS, payload: response })
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            return response
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_AGREEMENT_ASSETS');

export const getAgreementsForCalendar = () => wrap((dispatch:any, state:any) => {
    try {
        dispatch(setLoading(true));
        dispatch(setButtonLoading(true));
        return apiCaller('GET', `agreements-calendar`).then((response:any) => {
            console.log(response,'response,')
            dispatch({ "type": constants.GET_AGREEMENTS_FOR_CALENDAR, payload: response?.agreements })
            console.log(response?.tasks,'response?.tasks')
            dispatch({ "type": constants.GET_TASKS_FOR_CALENDAR, payload: response?.tasks })
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            return response
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_AGREEMENT_ASSETS');

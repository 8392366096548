import * as React from 'react';
import {IInputConfig} from '../Input';
import {DropdownOption} from './DropdownOption';
import {defined} from '../../../utils/variableEvaluation';
import {ReactComponent as IconArrow} from '../../../assets/icons/arrow-down.svg';
import {ReactComponent as IconClose} from '../../../assets/icons/x-dark.svg';
import styles from './DropdownInput.module.scss'; // Import css modules stylesheet as styles
import './DropdownInput.scss';
import classNames from "classnames";
import Comparator from '../../../utils/sorting';

const backendPagination = process.env.REACT_APP_BACKEND_PAGINATION;

interface IProps {
    dropdownOptions: IInputConfig[];
    name?: string;
    value?: string[] | string | number;
    isDisabled?: boolean;
    clearable?: boolean;
    readOnly?: boolean;
    onOpen?: (isOpen : boolean) => void;
    onSelected?: (name : string, value : string) => void;
    onBlur?: any;
    filtering?: boolean;
    isOpen?: any;
    onFocus?: any;
    sort?: boolean;
}

interface IState {
    isOpen: boolean;
    currentlyActiveIndex: number;
    optionSelected: string;
    shouldFocus: boolean;
}

export class DropdownInput extends React.Component<IProps, IState> {
    public constructor(props? : any, context? : any) {
        super(props, context);
        this.state = {
            isOpen: false,
            optionSelected: '',
            currentlyActiveIndex: -1,
            shouldFocus: true

        };
    }

    private listRef: HTMLInputElement | null | undefined;

    public componentDidMount() {
        window.addEventListener('click', this.handleClickWindow);
        let options = this.props.dropdownOptions;
        if(!this.props.filtering && options && options.length == 1) {
            this.setState({
                isOpen: false, 
                currentlyActiveIndex: parseInt(options[0].id as string), 
                optionSelected: options[0].dropdownLabel as any
            });
            this.props.onSelected && this.props.onSelected(this.props.name as string, options[0].id as string);
        }
    }

    public componentWillUnmount() {
        window.removeEventListener('click', this.handleClickWindow);
    }

    selectOption = (idx:any) => {
        this.setState({currentlyActiveIndex: idx});
        if(this.listRef?.children && this.listRef?.children[idx]) {
            // this.listRef.children[idx].scrollIntoView({block: 'end', behavior: 'smooth'});
        }
        // this.listRef.childNodes[idx].classList.add('active');
    }

    private handleKeyPress = (event:any) => {
        event.stopPropagation();
        const code = event.keyCode || event.charCode;
        if (!this.props.readOnly) {
            if (code === 40) {
                // arrow down
                if (this.state.currentlyActiveIndex < this.props.dropdownOptions.length - 1) {
                    if (this.state.currentlyActiveIndex < 0) {
                        this.selectOption(0);
                    } else {
                        this.selectOption(this.state.currentlyActiveIndex + 1);
                    }
                }
                if (!this.state.isOpen) {
                    this.setState({ isOpen: true });
                }
            } else if (code === 38 && (this.state.currentlyActiveIndex > 0)) {
                // arrow up
                this.selectOption(this.state.currentlyActiveIndex - 1);
            } else if (code === 27) {
                // escape
                this.setState({isOpen: false});
            } else if (code === 13) {
                event.preventDefault();
                // enter
                if (this.state.currentlyActiveIndex >= 0) {
                    this.handleOptionClick(this.props.dropdownOptions[this.state.currentlyActiveIndex]['dropdownLabel'] as any, this.state.currentlyActiveIndex);
                }
                this.setState({ isOpen: !this.state.isOpen });
            } else if (code === 9) {
                this.setState({isOpen: false});
            }
        }

    };

    private handleClickWindow = () => {
        // this.setState({ isOpen: false });
    };

    private handleWrapperClick = (e:any) => {

        if(e.target.classList.contains("inputSelectOption") || e.target.classList.contains("selectOptionsWrapper")) {
            return;
        }
        const { isOpen } = this.state;
        const isDisabled = defined(this.props.isDisabled) && this.props.isDisabled;

        if (!this.props.readOnly) {
            if (!isDisabled) {
                // const nextIsOpen = !this.state.isOpen;

                this.setState({ isOpen: !isOpen, shouldFocus: true });
                this.props.onOpen && this.props.onOpen(!this.state.isOpen)
            }
        }
        // e.stopPropagation();
    };

    private handleOptionClick = (value : string, idx: number, e?:any) => {
        e && e.stopPropagation();
        if(value === "close") {
            this.setState({ isOpen: false });
            return;
        }
        if (!this.props.readOnly) {
            let idVal = "" as string | undefined;
            if(backendPagination === "true" && this.props.filtering) {
                idVal = idx >=0 ? this.props.dropdownOptions[idx]['dropdownLabel'] : "";
            } else {
                idVal = idx >=0 ? this.props.dropdownOptions[idx]['id'] : "";
            }
            // this.props.onChange(this.props.name, value, idVal);
            this.setState({ optionSelected: value, currentlyActiveIndex: idx, isOpen: false});
            this.props.onSelected && this.props.onSelected(this.props.name as string, idVal as string);
        }
    };

    private handleFocus = (e:any) => {
        if (!this.props.readOnly) {
            this.state.shouldFocus && this.setState({ isOpen: true });
        }
        this.props.onFocus && this.props.onFocus()
    };

    private handleBlur = (e:any) => {
            if(this.props.value != "" || this.props.clearable === false ) {
                var value = this.props.dropdownOptions.filter((option, index) => {
                    const value = this.props.value ? this.props.value.toString() : '';
                    return value == option.dropdownLabel || RegExp(value, 'i').test(option?.dropdownLabel as string);
                })
                if(value.length > 0) {
                    this.setState({isOpen: false, currentlyActiveIndex: parseInt(value[0].id as string), optionSelected: value[0].dropdownLabel as any});
                    if(backendPagination == "true" && this.props.filtering) {
                        this.props.onSelected && this.props.onSelected(this.props.name as string, value[0].dropdownLabel as string);
                    } else {
                        this.props.onSelected && this.props.onSelected(this.props.name as string, value[0].id as string);
                    }
                } else {
                    this.setState({ isOpen: false, currentlyActiveIndex: -1, optionSelected: "" });
                    this.props.onSelected && this.props.onSelected(this.props.name as string, "");
                }
            } else if (this.props.value != "") {
                this.setState({ isOpen: false, currentlyActiveIndex: -1, optionSelected: "" });
                this.props.onSelected && this.props.onSelected(this.props.name as string, "");
            } else if (this.props.value == "") {
                this.setState({ isOpen: false, currentlyActiveIndex: -1, optionSelected: "" });
            }
    };

    private renderChildren(child:any, i:any): JSX.Element {
        return React.cloneElement(child, {
            key: `InputText ${i}`,
            value: this.props.value,
            // onChange: (value) => this.handleTextInputChange(value)
            /* || this.state.optionSelected */
        });
    }

    handleArrowClick(e:any) {
        e.stopPropagation();

        let isOpen = this.state.isOpen;
        if (!this.props.readOnly) {
            this.setState({isOpen: !isOpen});
            this.props.onOpen && this.props.onOpen(!isOpen)
        }
    }

    public render() {
        const {children, dropdownOptions, value} = this.props;

        let options = []
        if(this.props.sort){
            dropdownOptions?.sort((a,b) => {
                return Comparator(a.dropdownLabel,b.dropdownLabel,1,true)
            })
        }

        options = dropdownOptions.map((input : IInputConfig, idx:number) => {
            // If an option was selected, don't filter
            let regexMatch;
            let selected = dropdownOptions.find(option => option.dropdownLabel === value)
            if (this.state.optionSelected !== value && value !== "" && !selected) {
                regexMatch = RegExp(this.props.value ? this.props.value.toString() : '' , 'i').test(input.dropdownLabel as any);
            } else {
                regexMatch = true;
            }
            return regexMatch && <DropdownOption
                key={input.id}
                id={input.id  as string}
                dropdownLabel={input.dropdownLabel  as string}
                selected={idx === this.state.currentlyActiveIndex}
                onOptionClick={(label:any, e:any) => this.handleOptionClick(label, idx, e)}/>
            });
        return (
            <div
                className={`dropdown ${styles.inputSelectWrapper} ${(defined(this.props.isOpen) && (this.props.isOpen && this.state.isOpen)) || (!defined(this.props.isOpen) && this.state.isOpen) ? styles.isOpen : ''}`}
                onMouseDown={() => this.setState({ shouldFocus: false })}
                // onMouseUp={() => this.handleWrapperClick()}
                onClick={(e) => this.handleWrapperClick(e)}
                onKeyDown={(e) => this.handleKeyPress(e)}
                onFocus={(e) => this.handleFocus(e)}
                onBlur={this.handleBlur}
            >
                    {React.Children.map(children, (child, i) => this.renderChildren(child, i))}
                    {this.props.value && (this.props.clearable === false ? null :
                    <div className={styles.clearIcon} onClick={(e) => {
                            e.stopPropagation();
                            this.handleOptionClick("", -1)
                        }}>
                        <IconClose className="strokeGrey"/>
                    </div>)
            }
            <div className={classNames("inputSelectArrow" ,styles.inputSelectArrow)} onClick={(e) => this.handleArrowClick(e)}>
                <IconArrow/>
            </div>
            <div
                ref={(div:any) => {this.listRef = div}}
                className={styles.inputSelectOptionsWrapper + " selectOptionsWrapper"}>{options}</div>
        </div>);
    }
}

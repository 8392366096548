import * as React from 'react';
import styles from './DropdownInput.module.scss'; // Import css modules stylesheet as styles

interface IProps {
  id: string;
  selected?: boolean;
  dropdownLabel: string;
  onOptionClick: (value: string, e: any) => void;
}

export function DropdownOption(props: IProps) {
  const { dropdownLabel, onOptionClick, selected } = props;
  return (
    <div
      className={styles.inputSelectOption + ' inputSelectOption'  + (selected ? " active" : "")}
      onMouseDown={(e) => onOptionClick(dropdownLabel, e)}
      onMouseUp={(e) => onOptionClick("close", e)}
    >
      {dropdownLabel}
    </div>
  );
}

import {injectIntl} from 'react-intl'
import {Dispatch, bindActionCreators} from 'redux';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import {RootState} from '../../reducers';
import './calendarComponent.scss'
import {useEffect, useState} from 'react';
import moment from "moment";
import TranslatedText from "../TranslatedText";
import clearFormSvg from "../../assets/icons/archeology.svg";
import Tooltip from "rc-tooltip";

interface Props {
    eventInfo: any,
    setProductId: (number) => void,
    setStartDate: (any) => void,
    setEndDate: (any) => void,
    setProductData: (any) => void,
    setInitialStartDate: (any) => void,
    setCalendarComment: (any) => void,
    productId: number
}

const GREEN_COLOR = "#32BC20";
const BLUE_COLOR = "#3788d8"; 
const YELLOW_COLOR = "#DFDF04";
const RED_COLOR = "#D01314";

const CalendarEvent = (props: Props) => {

    const [statusArray, setStatusArray] = useState([])
    const [colorContainerArray, setColorContainerArray] = useState([])
    const [isYellowColor, setYellowColor] = useState<boolean>(false);

    useEffect(() => {
        let eventArrayitem = document.getElementsByClassName(`eventNr${props.eventInfo?.event?.id}`)
        let temp = props.eventInfo.event?.extendedProps?.statuses
        let tempEventTotalWidth = 0

        for (var i = 0; i < eventArrayitem.length; i++) {
            let tempParentElementWidth = eventArrayitem[i].parentElement.parentElement.offsetWidth
            eventArrayitem[i].getElementsByTagName("div")[0].style.transform = `translateX(-${tempEventTotalWidth}px)`
            tempEventTotalWidth += tempParentElementWidth
        }
        let colorContainterWidth = tempEventTotalWidth / temp.length

        let color = {
            minWidth: colorContainterWidth,
            colorName: ''
        }
        let colorArray = []
        let statusArray = []
        if (temp) {
            temp?.forEach(status => {
                if (status.id === 1) {
                    color = {
                        ...color,
                        colorName: GREEN_COLOR
                    }
                } else if (status.id === 2) {
                    color = {
                        ...color,
                        colorName: BLUE_COLOR
                    }
                } else if (status.id === 3) {
                    color = {
                        ...color,
                        colorName: RED_COLOR
                    }
                } else {
                    color = {
                        ...color,
                        colorName: YELLOW_COLOR
                    }
                    setYellowColor(true);
                }
                if(props.eventInfo?.event?.extendedProps.statuses[0].id === 2){
                    if(moment(props.eventInfo.event.start) < moment()){
                        color = {
                            ...color,
                            colorName: RED_COLOR
                        }
                    }
                }else {
                    if(moment(props.eventInfo.event.end) < moment()){
                        color = {
                            ...color,
                            colorName: RED_COLOR
                        }
                    }
                }
                colorArray.push(color)
                statusArray.push(status.id)
            });
        }
        setColorContainerArray(colorArray)
        setStatusArray(statusArray)
    }, [props.eventInfo])

    function returnProgressInPercentage() {
        let completedResponsibilities = statusArray.filter(status => status === 1)
        let calculatedPercentage = (100 * completedResponsibilities.length) / statusArray.length
        return `${Number(calculatedPercentage).toFixed(2)} %`
    }

    function setModalData(product) {
        if (!props.productId) {
            console.log(product, 'products')
            console.log(product, product?.extendedProps, 'product?.extendedProps')
            props.setProductData({
                ...product,
                extendedProps: product?.extendedProps,
                startStr: product?.startStr,
                progressInPercentage: returnProgressInPercentage() !== 'NaN %' ? returnProgressInPercentage() : ''
            })
            props.setProductId(product?.id)
            props.setInitialStartDate(product?.start)
            props.setCalendarComment(product?.extendedProps?.calendarComment)
            props.setEndDate(product?.end)
        }
    }

    return (
        <div className={`event-container eventNr${props.eventInfo?.event?.id} ${props.eventInfo?.event?.extendedProps?.measureDone ? 'measureDone': 'notDone'}`}
             onClick={() => setModalData(props.eventInfo?.event)}>
            <p className={isYellowColor ? `bg-yellow client-name` :`client-name` }>{props.eventInfo.event?.extendedProps?.clientName} ( {props.eventInfo.event?.extendedProps?.projectName}, {moment(props.eventInfo?.event?.startStr)?.format('DD-MM-YYYY')} / {moment(props.eventInfo?.event?.extendedProps?.endingDateString)?.format('DD-MM-YYYY')}) {returnProgressInPercentage() !== 'NaN %' && returnProgressInPercentage()} </p>
            {/*<p className={isYellowColor ? 'bg-yellow' : ''}>{props.eventInfo.event?.extendedProps?.productName} {returnProgressInPercentage() !== 'NaN %' && returnProgressInPercentage()}  ({props.eventInfo?.event?.startStr} / {props.eventInfo?.event?.extendedProps?.endingDateString})</p>*/}
            <div className="color-container">
                {colorContainerArray?.map((color, index) => {
                    return <Tooltip placement="top" overlay={<span>{props.eventInfo?.event?.extendedProps.statuses[index].responsibilityName}</span>}>
                        <div key={index} className={`colorContainter`}
                             style={{backgroundColor: color.colorName, minWidth: color.minWidth}}>
                            <div className={`colorContainter${props.eventInfo?.event?.id}`}
                                 style={{backgroundImage: `linear-gradient(to right, transparent , ${color.colorName} )`}}>
                            </div>
                        </div>
                    </Tooltip>
                })}
            </div>
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(CalendarEvent as any)));
import React, {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import * as measuresActions from '../../actions/measures';
import './Offers.scss'
import {withRouter} from 'react-router';
import TableBbros from '../../components/Table/TableBbros';
import {ALL_MODULE_CONTENT_COLUMNS, OPTIONS} from '../../components/Table/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import {useParams} from "react-router-dom";
import {ParameterTypes} from "../../types/parameter.type";
import DeleteModalComponent from "../../components/Modal/DeleteModal";
import ModalComponent from "../../components/Modal/ModalComponent";
import DeleteIcon from "@material-ui/icons/DeleteOutline";

interface Props {
    intl: any;
    history: any;
    clientActions: any;
    measuresActions: any;
    measures: any
    actions: any;
    letter: string,
    allProjects: any,
    loading: boolean,
    client: any
}

const AllOffers = (props: Props) => {

    const {clientId} = useParams<ParameterTypes>();
    const [moduleId, setModuleId] = useState<number>(null);
    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);
    useEffect(() => {
        props.actions.getModuleDataForClient('offers', clientId)
    }, [])

    useEffect(()=>{
        props.clientActions.fetchClient(clientId, 2)
    },[])

    function editOfferData (offer) {
        localStorage.setItem('projectName', offer?.projectName)
        props.actions.setProjectName(offer?.projectName)
        props.history.push({pathname: `/dashboard/offers/create/${offer?.id}/${offer?.projectId}`})
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData) => editOfferData(rowData)
        },
        {

            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData) => deleteButtonFunction(rowData)
        }
    ]
    function deleteButtonFunction (measure) {
        setModuleId(measure?.id)
        setDeleteModal(true)
    }

    function deleteOffer () {
        props.actions.deleteModuleData('offer', {id: moduleId})
        setDeleteModal(false)
    }

    return (
        <div className="wrapper">
            <TableBbros
                title={props?.client?.client?.firstnameAndLastname}
                data={props?.allProjects}
                columns={ALL_MODULE_CONTENT_COLUMNS}
                options={OPTIONS}
                actions={actions}
                isLoading={props.loading}
            />
            <ModalComponent
                isModalHeader
                isOpen={isDeleteModalOpen}
                close={setDeleteModal}
            >
                <DeleteModalComponent
                    history={props.history}
                    close={() => setDeleteModal(false)}
                    deleteFunction={() => deleteOffer()} />
            </ModalComponent>
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        measures: state.measures
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        clientActions: bindActionCreators(clientActions as any, dispatch),
        measuresActions: bindActionCreators(measuresActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(AllOffers)))

import React, {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import * as measuresActions from '../../actions/measures';
import {withRouter} from 'react-router';
import {Client} from '../../types/client.type';
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import TableBbros from '../../components/Table/TableBbros';
import {
    ARCHIVED_RESPONSIBILITIES_AND_MATERIALS_COLUMNS,
    ARCHIVED_RESPONSIBILITIES_COLUMNS,
    OPTIONS
} from '../../components/Table/TableConstants';
import {UnitsObj} from "../../types/units.type";
import ReplayIcon from "@material-ui/icons/Replay";
import {Input} from "../../components/Input/Input";
import './archive.scss'
import {Button, EButtonType} from "../../components/Button";
import HotkeyButton from "../../components/HotkeyButton/HotkeyButton";
import {axiosCaller} from "../../utils/apiCaller";
import {toast} from "react-toastify";
import MikaSelect from "../../components/BbrosSelect/mikaSelect.component";
import moment from "moment";
import FontAwesome from 'react-fontawesome';
import {SelectOption} from "../../types/selectOption.type";
import {convertNumberToHMS} from "../Admin/globalFunctions";
import TranslatedText, {translatedText} from "../../components/TranslatedText";
import {getArchivedMaterialsAndResponsibilities} from "../../actions/generalActions";

interface Props {
    intl: any;
    history: any;
    clientActions: any;
    measuresActions: any;
    measures: any
    actions: any;
    letter: string,
    clientsByModuleData: Array<Client>,
    loading: boolean,
    archivedProducts: any,
    archivedResponsibilities: any,
    archivedMaterials: any,
    permissions: any
    buttonLoader: boolean
    departments: any
    archivedMaterialsAndResponsibilitiesPaginated: any
    productionUsers: any
}

const ArchivedResponsibilities = (props: Props) => {

    const [selectedDepartment, setSelectedDepartment] = useState<SelectOption>({} as SelectOption)
    const [selectedClient, setSelectedClient] = useState<SelectOption>({} as SelectOption)
    const [selectedUser, setSelectedUser] = useState<SelectOption>({} as SelectOption)
    const [selectedResponsibility, setSelectedResponsibility] = useState<SelectOption>({} as SelectOption)
    const [startDate, setStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()
    const [filtersOpened, setFiltersOpened] = useState<boolean>(false)
    const [adminComment, setAdminComment] = useState<string>('')
    const [showReturnModal, setShowReturnModal] = useState<boolean>(false)
    const [selectedMaterialStatus, setSelectedMaterialStatus] = useState<any>()
    const [archivedResponsibilities, setArchivedResponsibilities] = useState([])
    const [page, setPage] = useState(0)
    const [clientsToFilter, setClientsToFilter] = useState<SelectOption[]>([])
    const [responsibilitiesToFilter, setResponsibilitiesToFilter] = useState<SelectOption[]>([])


    // useEffect(() => {
    //     let currentDate = new Date();
    //
    //     // Calculate the start date six months back
    //     let startDate = new Date();
    //     startDate.setMonth(currentDate.getMonth() - 1);
    //
    //     // End date is the current date
    //     let endDate = currentDate;
    //
    //     // Format the dates as strings (optional)
    //     let formattedStartDate = startDate.toISOString().split('T')[0];
    //     let formattedEndDate = endDate.toISOString().split('T')[0];
    //     setStartDate(formattedStartDate)
    //     setEndDate(formattedEndDate)
    //
    //     props.actions.getAllDepartments()
    //     // props.actions.getUserPermissionsAndResponsibilities()
    //     // props.actions.getArchivedMaterialsAndResponsibilities(1)
    // }, [])

    useEffect(() => {
        props.actions.getAllDepartments()
        props.actions.getAllProductionUsers()
        let currentDate = new Date();

        // Calculate the start date six months back
        let startDate = new Date();
        startDate.setMonth(currentDate.getMonth() - 1);

        // End date is the current date
        let endDate = currentDate;

        // Format the dates as strings (optional)
        let formattedStartDate = startDate.toISOString().split('T')[0];
        let formattedEndDate = endDate.toISOString().split('T')[0];
        setStartDate(formattedStartDate)
        setEndDate(formattedEndDate)
        props.actions.getArchivedMaterialsAndResponsibilities(null,selectedDepartment?.value ? selectedDepartment?.value : null, formattedStartDate, formattedEndDate)
        // props.actions.getArchivedMaterials(selectedDepartment?.value ? selectedDepartment?.value : null, formattedStartDate, formattedEndDate)
        // props.actions.getArchivedResponsibilities(selectedDepartment?.value ? selectedDepartment?.value : null, formattedStartDate, formattedEndDate)
    }, []);

    // useEffect(()=>{
    //     let tempArray = [
    //         ...props?.archivedMaterials,
    //         ...props?.archivedResponsibilities
    //     ]
    //     tempArray = tempArray.sort(function compare(a, b) {
    //         let dateA: any = new Date(a.trackersEnd);
    //         let dateB: any = new Date(b.trackersEnd);
    //         return dateA - dateB;
    //     });
    //     setArchivedResponsibilities(tempArray)
    //
    // },[props?.archivedResponsibilities, props?.archivedMaterials])

    useEffect(() => {
        // setPage(props?.archivedMaterialsAndResponsibilitiesPaginated?.current_page)
        setArchivedResponsibilities(props?.archivedMaterialsAndResponsibilitiesPaginated)
        // Step 1: Extract all clientName fields
        let clients = props?.archivedMaterialsAndResponsibilitiesPaginated.map(item => item.client);
        // @ts-ignore
        let uniqueClientNames = [...new Set(clients)];
        let uniqueClientNameObjects = uniqueClientNames.map(client => ({ value: client?.clientId, label: client?.clientName }));
        setClientsToFilter(uniqueClientNameObjects)
        let responsibilitiesGrouped = props?.archivedMaterialsAndResponsibilitiesPaginated.map(item => {
            return {
                value: item.responsibilityId,
                label: item.responsibilityName
            }
        });
        // @ts-ignore
        // let uniqueClientNameObjects = uniqueClientNames.map(client => ({ value: client?.clientId, label: client?.clientName }));
        const uniqueData = responsibilitiesGrouped.reduce((acc, current) => {
            const x = acc.find(item => item.value === current.value && item.label === current.label);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
        console.log(uniqueData,"uniqueResponsibilityNames")
        setResponsibilitiesToFilter(uniqueData)
        console.log(props?.archivedMaterialsAndResponsibilitiesPaginated, 'props?.archivedMaterialsAndResponsibilitiesPaginated')
    }, [props?.archivedMaterialsAndResponsibilitiesPaginated])


    function selectData(rowData) {
        if (props?.permissions?.fullPrivileges) {
            setSelectedMaterialStatus(rowData)
            setAdminComment(rowData?.adminWarning)
            setShowReturnModal(true)
        }

    }

    function returnMaterialInProcess() {
        if (!adminComment) {
            toast.error('Komenti eshte obligativ!')
            return
        }
        let payload = {
            materialId: selectedMaterialStatus?.material?.materialId,
            productId: selectedMaterialStatus?.product?.productId,
            responsibilityStatusId: 3,
            responsibilityId: selectedMaterialStatus?.responsibilityId,
            adminWarning: adminComment,
        }
        console.log(selectedMaterialStatus,'selectedMaterialStatus')

        if (selectedMaterialStatus?.material?.materialId) {
            axiosCaller('POST', `responsibilities/clients/material/statuses`, payload).then(() => {
                props.actions.getArchivedMaterialsAndResponsibilities(null,selectedDepartment?.value ? selectedDepartment?.value : null, startDate, endDate)
                setShowReturnModal(false)
            })
        } else {
            axiosCaller('POST', `responsibilities/clients/statuses`, payload).then(() => {
                props.actions.getArchivedMaterialsAndResponsibilities(null,selectedDepartment?.value ? selectedDepartment?.value : null, startDate, endDate)
                setShowReturnModal(false)
            })
        }


    }

    function filterData(){
        if(selectedDepartment?.value || (startDate && endDate)){
            props.actions.getArchivedMaterialsAndResponsibilities(null,selectedDepartment?.value ? selectedDepartment?.value : null, startDate, endDate, selectedClient?.value, selectedResponsibility?.value, selectedUser?.value )
            setFiltersOpened(false)
        }else{
            if(!selectedDepartment?.value){
                props.actions.getArchivedMaterialsAndResponsibilities(null,selectedDepartment?.value ? selectedDepartment?.value : null, startDate, endDate, selectedClient?.value, selectedResponsibility?.value, selectedUser?.value )
                setFiltersOpened(false)
                return
            }
            props.actions.getArchivedMaterialsAndResponsibilities(null,selectedDepartment?.value ? selectedDepartment?.value : null, startDate, endDate, selectedClient?.value, selectedResponsibility?.value, selectedUser?.value )
        }
    }


    const actions = [
        {
            icon: ReplayIcon,
            tooltip: 'Ktheni ne prodhim',
            onClick: (event: Event, rowData: UnitsObj) => selectData(rowData)
        },
    ]

    return (
        <div className="wrapper archivedResponsibility">
            <div className={`productionFilterButton`}>
                <div className={'w-100 h-100 position-relative'}>
                    <div className={'filterIconContainer'} onClick={() => setFiltersOpened(!filtersOpened)}>
                        <FontAwesome name={'filter'}/>
                    </div>
                    <div className={`filterDropdownContainer ${filtersOpened ? 'show' : 'hide'}`}>
                        <div className={'filterWrapper'}>
                            <div className={'closeFiltersButton'} onClick={() => setFiltersOpened(!filtersOpened)}>
                                <FontAwesome name={'close'}/>
                            </div>
                            <MikaSelect options={props?.departments} placeholder={'Reparti'} value={selectedDepartment}
                                        setEntireOptionFunction={setSelectedDepartment}/>
                            <MikaSelect options={responsibilitiesToFilter} placeholder={'Procesi'} value={selectedResponsibility}
                                        setEntireOptionFunction={setSelectedResponsibility}/>
                            <MikaSelect options={clientsToFilter} placeholder={'Klienti'} value={selectedClient}
                                        setEntireOptionFunction={setSelectedClient}/>
                            <MikaSelect options={props?.productionUsers} placeholder={'Perdoruesi'} value={selectedUser}
                                        setEntireOptionFunction={setSelectedUser}/>
                            <div
                                className={`dateContainer`}>
                                <p className={'label'}>Data e fillimit</p>
                                <p className={'dateValue'}>{startDate ? moment(startDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                {startDate && <img src={DifferentColoredPlus} onClick={() => setStartDate('')}
                                                   className="x-signDate"/>}
                                <div className={'calendar'}>
                                    <input type="date" value={startDate}
                                           onChange={(e) => setStartDate(e?.target?.value)}/>
                                    <div className={'calendarIcon'}>
                                        <FontAwesome
                                            name="calendar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`dateContainer`}>
                                <p className={'label'}>Data e mbarimit</p>
                                <p className={'dateValue'}>{endDate ? moment(endDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                {endDate &&
                                    <img src={DifferentColoredPlus} onClick={() => setEndDate('')}
                                         className="x-signDate"/>}
                                <div className={'calendar'}>
                                    <input type="date" value={endDate} onChange={(e) => setEndDate(e?.target?.value)}/>
                                    <div className={'calendarIcon'}>
                                        <FontAwesome
                                            name="calendar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <Button
                                isLoading={props?.buttonLoader}
                                isDisabled={props?.buttonLoader}
                                className="m-auto w-100"
                                onClick={filterData}
                                buttonType={EButtonType.GreyButton}>
                                Filtro
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="allModuleContent">
                <TableBbros
                    title={translatedText({id: "table.measure", defaultMessage: "Arkiva e proceseve"})}
                    data={archivedResponsibilities || []}
                    columns={ARCHIVED_RESPONSIBILITIES_AND_MATERIALS_COLUMNS}
                    options={OPTIONS}
                    actions={actions}
                    // customPagination={true}
                    // callBack={props?.actions.getArchivedMaterialsAndResponsibilities}
                    // paginationDetails={{
                    //     page: page,
                    //     count: props?.archivedMaterialsAndResponsibilitiesPaginated?.total
                    // }}
                    isLoading={props.loading}
                />
            </div>
            {showReturnModal && <div className={'adminCommentModalWrapper'}>
                <div className={'adminCommentModal'}>
                    <HotkeyButton
                        keyCode="Escape"
                        modifiers={[]}
                        isActive={false}
                        onStateChange={(state) => setShowReturnModal(!state)}
                    >
                        <img className={'x-sign'} src={DifferentColoredPlus} alt=""
                             onClick={() => setShowReturnModal(false)}/>
                    </HotkeyButton>
                    <p className={'title'}>Rikthimi ne proces</p>
                    <Input
                        name="comments"
                        label="label"
                        id="comments"
                        defaultValue=''
                        inputWrapperClass={"w-100 textarea-wrapper"}
                        type="textarea"
                        value={adminComment || ''}
                        onChange={(name, value) => setAdminComment(value)}
                    >
                        <TranslatedText id="app.agreements.appendFinalAgreement"
                                        defaultMessage="Komenti per administrator"/>
                    </Input>
                    <div className={'d-flex'}>
                        <Button
                            isLoading={props?.buttonLoader}
                            isDisabled={props?.buttonLoader}
                            onClick={() => setShowReturnModal(false)}
                            className="w-100 returnMaterialInProcessButtons"
                            buttonType={EButtonType.GreyButton}
                        >
                            <TranslatedText id="admin.addColor" defaultMessage="Anulo kthimin"/>
                        </Button>
                        <Button
                            isLoading={props?.buttonLoader}
                            isDisabled={props?.buttonLoader}
                            onClick={returnMaterialInProcess}
                            className="w-100 returnMaterialInProcessButtons"
                            buttonType={EButtonType.GreyButton}
                        >
                            <TranslatedText id="admin.addColor" defaultMessage="Ktheni ne proces"/>
                        </Button>
                    </div>
                </div>
            </div>}
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        measures: state.measures
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        clientActions: bindActionCreators(clientActions as any, dispatch),
        measuresActions: bindActionCreators(measuresActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ArchivedResponsibilities)))

import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import styles from './InputFile.module.scss';
import TranslatedText, {translatedText} from "../../TranslatedText"; // Import css modules stylesheet as styles


interface IProps {
    isFocused?: boolean;
    isLoading?: boolean;
    name?: string;
    value?: string[] | string | number;
    placeholder?: string;
    type?: string;
    onChange?: (name: string, value: any, file?: any) => void;
    additionalClass?: string;
    isDisabled?: boolean;
    autoFocus?: boolean;
    readOnly?: boolean;
    inputTextType?: any;
    id?: any;
    onBlur: any;
    intl:any;
    multiple?: boolean;
    isChildren?: boolean;
    isContact?: boolean;
    accept?: string;
}

interface IState {
    isFocused: boolean;
    fileName: string;
}

export class InputFile extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isFocused: false,
            fileName: ""
        }
    }
    private inputRef: HTMLInputElement | null | undefined;
    private focusTimeoutId:any;

    public componentDidMount() {
        this.focusTimeoutId = setTimeout(() => {
            if (this.props.isFocused) {
                // @ts-ignore
                this.inputRef.focus();
            }
        }, 0);
    }

    public componentWillUnmount() {
        clearTimeout(this.focusTimeoutId);
    }

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        const file = e.target.files && e.target.files[0] ? e.target.files[0] : null

        this.setState({ fileName: file ? file.name : "" });
        this.props.onChange && this.props.onChange(name, value, file);
    };

    public render() {
        const {
            name,
            value,
            type,
            isFocused,
            isLoading,
            isDisabled,
            readOnly,
            id,
            onBlur,
            children,
            multiple,
            isContact,
            accept
        } = this.props;

        const { fileName } = this.state;

        return (
            <label htmlFor={id} className={styles.inputFileWrapper}>
                {!children && <><span className={styles.inputFileButton}><TranslatedText id="navigation.chooseFile" defaultMessage="Zgjidh nje file" /></span>
                <span className={`${styles.inputFileName} ${fileName && styles.hasFile}`}>
                    {fileName ? fileName : translatedText({ id: "navigation.noFileSelected", defaultMessage: "Asnje file i selektuar"})}
                </span></>}
                {(children && isContact) &&
                <span className={`${styles.inputFileName} ${fileName && styles.hasFile}`}>
                    {fileName ? fileName : translatedText({ id: "navigation.noFileSelected", defaultMessage: "Asnje file i selektuar"})}
                </span>}
                <input
                    id={id}
                    name={name}
                    type={type}
                    value={value}
                    readOnly={readOnly}
                    onBlur={onBlur}
                    onChange={this.handleChange}
                    className={styles.inputFile}
                    ref={input => isFocused && (this.inputRef = input)}
                    autoFocus={isFocused}
                    disabled={isDisabled || isLoading}
                    autoComplete="off"
                    multiple={multiple}
                    accept={accept}
                />
                <div></div>
            </label>
        );
    }
}


import React, {CSSProperties} from 'react';
import {Form, Formik} from 'formik';


interface Props{
    style?: CSSProperties;
    onSubmit?: (values: any) => void;
    initialValues: any,
    validationScheme: any,
    children?: JSX.Element[] | JSX.Element,
    className?: string
}

const FormikFormWrapper: React.FC<Props> = (props: Props) => {

    return <Formik
        enableReinitialize
        initialValues={props.initialValues}
        validationSchema={props.validationScheme}
        onSubmit={props.onSubmit}
    >
        {formikProps => (
            <Form className={props?.className}>
                { props?.children }
            </Form>
        )}
    </Formik>
}


export default FormikFormWrapper;

import React, {useEffect, useRef, useState} from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import * as measuresActions from '../../actions/measures';
import { Button, EButtonType } from '../../components/Button';
import { EInputTextType, Input } from '../../components/Input/Input';
import './Measures.scss'
import { useHistory, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { Client } from '../../types/client.type';
import * as Yup from "yup";
import moment from 'moment';
import Spinner from '../../components/Spinner';
import clearFormSvg from '../../assets/icons/archeology.svg'
import Tooltip from "rc-tooltip";
import locationPinSvg from '../../assets/icons/pin.svg'
import { apiCaller } from '../../utils/apiCaller'
import * as adminActions from '../../actions/admin';
import { SelectOption } from '../../types/selectOption.type';
import MikaTextInput from "../../components/Input/MikaTextInput/MikaTextInput";
import MikaTextAreaInput from "../../components/Input/MikaTextAreaInput/MikaTextAreaInput";
import FormikFormWrapper from "../../components/FormikFormWrapper/FormikFormWrapper";
import DataTransferComponent from "../../components/DataTransferComponent/DataTransferComponent";
import PICFile from '../../assets/icons/pic.svg'
import calendarIcon from '../../assets/icons/blackCalendar.svg'
import arrowDown1 from '../../assets/icons/Icon_awesome_arrow_alt_circle_right.svg'
import MikaSelect from "../../components/BbrosSelect/mikaSelect.component";
import { useParams } from "react-router-dom";
import { resetClient } from "../../actions/client";
import {isMobile, MEASURE_STATUSES} from "../../constants/constants";
import MikaFileInput from "../../components/Input/MikaFileInput/MikaFileInput";
import { ParameterTypes } from '../../types/parameter.type';
import {checkIfUserIsAuthorized} from "../Admin/globalFunctions";
import { FileUpload } from "../../types/fileUpload.type";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import { Project } from "../../types/Project.type";
import {getClientMeasureByProjectId} from "../../actions/measures";
import TranslatedText, {translatedText} from "../../components/TranslatedText";
import ReactToPrint from "react-to-print";
import FileIcon from "../../assets/icons/file-text.svg";
import {PrintedContent} from "../Offers/CalculateOfferModal/PrintedContent";
import {ModalContent} from "../../types/modalContent.type";
import {MeasureImages} from "./MeasureImages";
import { compress, decompress } from 'lzma';
import {LatLngExpression} from "leaflet";

interface Props {
    intl: any;
    loading: boolean;
    location: any;
    client: any;
    clientActions: any;
    measuresActions: any;
    actions: any;
    measures: any;
    letter: string;
    clientsByModuleData: Array<Client>;
    adminActions: any;
    articleTypes: SelectOption[];
    allClients: SelectOption[];
    history: any;
    permissions: any;
    admin: any;
    projectName: string
    projectDetails: any
    buttonLoader: boolean
}

const CreateMeasureForm = (props: Props) => {

    const language:any = TranslatedText
    const componentRef = useRef();

    const { measureId, clientId } = useParams<ParameterTypes>();

    const moduleId = 2;
    const [loading, setLoading] = useState<boolean>(false);
    const [firstAndLastName, setFirstAndLastName] = useState<string>();
    const [clients, setClients] = useState<SelectOption[]>([])
    const [searchedClient, setSearchedClient] = useState<string>();
    const [isDropDownInfoOpen, setIsDropDownInfoOpen] = useState(false);
    const [productLocation, setProductLocation] = useState<string>();
    const [details, setDetails] = useState<string>();
    const [measuringDate, setMeasuringDate] = useState<string>('')
    const [measureStatus, setMeasureStatus] = useState<SelectOption>()
    const [measureColor, setMeasureColor] = useState<SelectOption>()
    const [assets, setAssets] = useState([])
    const [measureImages, setMeasureImages] = useState([])
    const [showRequiredMessage, setShowRequiredMessage] = useState<boolean>(false)
    const [initialValues, setInitialValues] = useState({
        productMaterial: '',
    })
    const [position, setPosition] = useState<LatLngExpression>()
    const [selectedProject, setSelectedProject] = useState<SelectOption>({} as SelectOption)
    const [projectOptions, setProjectOptions] = useState<SelectOption[]>([] as SelectOption[])
    const history = useHistory();
    const [colors, setColors] = useState([])

    const [addSketch, setAddSketch] = useState<FileUpload[]>([] as FileUpload[])

    const [addPic, setAddPic] = useState<FileUpload[]>([] as FileUpload[])

    const [addFile, setAddFile] = useState<FileUpload[]>([] as FileUpload[])

    useEffect(() => {
        props.adminActions.getColorPalettes()
        props.actions.getAllClients(2) //the static 2 is for moduleId
        props.measuresActions.getAllMeasureTemplates()
        getCurrentPosition()
    }, [])

    useEffect(() => {
        if(props?.projectDetails){
            setSelectedProject({value: props?.projectDetails?.id, label: props?.projectDetails?.projectName})
        }
    }, [props.projectDetails])

    useEffect(()=>{
        props.actions.getAllProjectInfo('', measureId)
    },[measureId])

    useEffect(() => {
        let tempArray = []

        props.admin?.colorPalettes?.forEach((color) => {
            tempArray.push({
                label: color?.colorPalette,
                value: color?.id
            })
        })

        setColors(tempArray)
    }, [props.admin?.colorPalettes])

    useEffect(() => {
        if (Object.keys(props.permissions).length !== 0 && !checkIfUserIsAuthorized(props.permissions, props.permissions?.fullPrivileges, props.permissions?.measures)) {
            toast.error(translatedText({
                id: "app.yourNotAuthenticatedToModifyDataForThisModule",
                defaultMessage: "Nuk keni qasje ne modifikimin e te dhenave te ketij moduli!"
            }))
            history.push('/dashboard/measures')
        }
    }, [props.permissions])

    useEffect(() => {
        setClients(props.allClients)
    }, [props.allClients])

    useEffect(() => {
        props.actions.fetchClientsByModuleData(props.letter, 1, 2)
    }, [props.letter, props.actions])

    useEffect(() => {
        getModuleData()
    }, [measureId])

    useEffect(()=>{
        props.clientActions.getClientProjects(clientId)
        props.clientActions.fetchClient(clientId, 2)
    },[clientId])

    function getModuleData() {
        setLoading(true)
        if (measureId) {
            props.measuresActions.getClientMeasureByProjectId(measureId).then((res) => {
                let tempValues = {
                    ...initialValues,
                    productMaterial: res?.data?.productMaterial,
                    details: res?.data?.details
                }
                setMeasureColor(res?.data?.productColor)
                setMeasuringDate(res?.data.measureDate ? moment(res?.data?.measureDate).format('DD.MM.YYYY') : '')
                setMeasureStatus(res?.data?.status)
                setProductLocation(res?.data?.productLocation)
                setDetails(res?.data?.details)
                setInitialValues(tempValues)
                setLoading(false)
                let tempArray = []
                let tempPicsArray = []
                res?.data?.measureFiles?.forEach((file) => {
                    if (file?.measureWithUrl) {
                        tempArray.push({ id: file.id, fileUrl: file?.measureWithUrl, name: file?.measureName })
                    } else if (file?.pictureWithUrl) {
                        tempPicsArray.push({ id: file.id, fileUrl: file?.pictureWithUrl, name: file?.pictureName })
                        tempArray.push({ id: file.id, fileUrl: file?.pictureWithUrl, name: file?.pictureName })
                    } else if (file?.sketchWithUrl) {
                        tempPicsArray.push({ id: file.id, fileUrl: file?.sketchWithUrl, name: file?.sketchName })
                        tempArray.push({ id: file.id, fileUrl: file?.sketchWithUrl, name: file?.sketchName })
                    }
                })
                setMeasureImages(tempPicsArray)
                setAssets(tempArray)
            })
        } else {
            setLoading(false)
            resetFields()
        }
    }

    useEffect(() => {
        if (props.client.client.id) {
            props.clientActions.getClientProjects(props.client?.client?.id, true)
            setFirstAndLastName(`${props?.client?.client?.firstnameAndLastname} ${props?.client?.client?.lastName}`)
            if (props?.client?.client?.clientLocation) {
                setProductLocation(props?.client?.client?.clientLocation)
            }
        }
    }, [props.client.client])

    useEffect(() => {
        let tempArray = []

        props.client?.clientProjects?.forEach((project) => {
            tempArray.push({ value: project.id, label: project.projectName })
        })
        setProjectOptions(tempArray)
    }, [props.client.clientProjects])

    function getMeasureLocation() {
        let currentLocation = ''

        navigator.geolocation.getCurrentPosition(function (position) {
            currentLocation = `${position?.coords?.latitude},${position?.coords?.longitude}`
            setProductLocation(currentLocation)

        },(err)=> {
            console.warn(`ERROR(${err.code}): ${err.message}`)
        }, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 60000,
        });
    }

    function getCurrentDate() {
        let currentDate = moment().format("DD.MM.YYYY")
        setMeasuringDate(currentDate)
    }

    function saveMeasure(values){
        setLoading(true);
        if (!measureStatus?.label) {
            setShowRequiredMessage(true)
            setLoading(false)
            return
        }
        if (!selectedProject?.label) {
            console.log('1')
            setShowRequiredMessage(true)
            setLoading(false)
            return
        }
        let formData = new FormData();

        formData.append("clientId", props.client?.client?.id);
        if (measureColor) {
            formData.append("productColor", measureColor?.value);
        }
        if (values.productMaterial) {
            formData.append("productMaterial", values.productMaterial)
        }
        if (selectedProject?.value) {
            formData.append("projectId", selectedProject?.value);
        }
        if (productLocation) {
            formData.append("productLocation", productLocation)
        }
        if (details) {
            formData.append("details", details);
        }
        if (measureStatus?.value) {
            formData.append("measureStatusId", measureStatus?.value);
        }
        if (addSketch.length !== 0) {
            addSketch?.forEach((file) => {
                formData.append("sketch[]", file.file);
            })
        }
        if (addPic.length !== 0) {
            addPic?.forEach((file) => {
                // const compressedData = compress(file);
                // console.log(compressedData,'compressedData')
                formData.append("picture[]", file.file);
            })
        }
        if (addFile.length !== 0) {
            addFile?.forEach((file) => {
                formData.append("measure[]", file.file);
            })
        }
        if (measuringDate) {
            formData.append("measureDate", measuringDate);
        }
        formData.append("startDate", props.client?.client?.measureDate ? moment(props.client?.client?.measureDate).format('YYYY-MM-DD') : "");
        if (props.measures.measureClient.id) {
            try {
                props.measuresActions.editMeasure(props.measures.measureClient.id, formData).then((response) => {
                    if(response?.status === 200){
                        // if (response?.data?.unique?.name) {
                        //     toast.error('Emri i projektit duhet te jete unik!')
                        //     setLoading(false);
                        //     return
                        // }
                        if (response?.data?.inProduction){
                            toast.error('Kjo matje ka vazhduar ne prodhim!')
                            setLoading(false);
                            return
                        }
                        let payload = {
                            moduleId: 2,
                            clientId: props?.client?.client?.id
                        }
                        setInitialValues({
                            productMaterial: response?.data?.data?.productMaterial,
                        })
                        setMeasureColor(response?.data?.data?.productColor)
                        setProductLocation(response?.data?.data?.productLocation)
                        setDetails(response?.data?.data?.details)
                        setMeasuringDate(response?.data?.data?.measureData ? moment(response.data?.data?.measureDate).format('DD.MM.YYYY') : '')
                        setMeasureStatus(response?.data?.data?.status)
                        apiCaller('POST', `modules/create-client-for-next-module`, payload).then(res => {
                        })
                        history.goBack()
                        setLoading(false);
                        toast.info(translatedText({
                            id: "measure.measureEdited",
                            defaultMessage: "Matja eshte ruajtur"
                        }))
                    }
                })
            } catch (err) {
                toast.error(err);
                setLoading(false);
            }
        } else {
            try {
                props.measuresActions.createMeasure(formData).then((response) => {
                    if (response?.data?.unique?.name) {
                        toast.error('Emri i projektit duhet te jete unik!')
                        setLoading(false);
                        return
                    }
                    let payload = {
                        moduleId: 2,
                        clientId: props?.client?.client?.id
                    }
                    if (measureStatus.value == 2) {
                        apiCaller('POST', `modules/create-client-for-next-module`, payload).then(res => {
                        })
                    }
                    setLoading(false);
                    history?.goBack()
                    toast.info(translatedText({
                        id: "measure.measureCreated",
                        defaultMessage: "Matja eshte krijuar"
                    }))
                })
            } catch (err) {
                toast.error('Diqka nuk shkoi mire')
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        let tempArrayOfClients = props.allClients

        tempArrayOfClients = tempArrayOfClients.filter(val => val.name.includes(searchedClient?.charAt(0).toUpperCase()))

        setClients(tempArrayOfClients)

    }, [searchedClient])


    function validationScheme() {
        return Yup.object().shape({});
    }

    function resetFields() {
        resetClient({
            id: undefined
        })
        setInitialValues({
            productMaterial: '',
        })
        setSelectedProject({} as SelectOption)
        setProductLocation('')
        setMeasuringDate(null)
        setMeasureStatus({
            label: '',
            value: undefined
        })
        setIsDropDownInfoOpen(false)
        history.push('/dashboard/measures/create')
    }

    function getTransferredDataFromModules(clientId) {
        setSearchedClient('')
        setIsDropDownInfoOpen(false)
        props.clientActions.fetchClient(clientId, 2)
    }

    const measureStatuses = MEASURE_STATUSES

    function removeFile(fileId: number) {
        setLoading(true)
        props.actions.removeFile('measure', fileId).then(() => {
            getModuleData()
        })
    }

    function checkProductLocation(sentState){
        let isValid = true
        let location = [...sentState]
        location?.forEach((letter)=>{
            if(/^[a-zA-Z]+$/.test(letter)){
                isValid = false
                return
            }else{
                isValid = true
            }
        })
        if(isValid){
            setProductLocation(sentState)
        }
    }

    function checkMeasureStatus(measureStatus){
        if(measureStatus?.value === 2 && !productLocation){
            toast?.error('Duhet te jepni lokacionin per matje finale')
            return
        }else{
            setMeasureStatus(measureStatus)
        }
    }
    function getCurrentPosition() {
        navigator.geolocation.getCurrentPosition(function (geoLocationPosition) {
            if (geoLocationPosition?.coords?.latitude && geoLocationPosition?.coords?.longitude) {
                setPosition([geoLocationPosition.coords.latitude, geoLocationPosition.coords.longitude])
            }
        },(err)=> {
            console.warn(`ERROR(${err.code}): ${err.message}`)
        }, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 60000,
        });
    }

    const measureForm = () => {
        return (
            <div className="measure-form-wrapper">
                <FormikFormWrapper initialValues={initialValues} validationScheme={validationScheme()} onSubmit={()=>{}}>
                    <p className="d-flex small-text create-new-text create-new-text pl-15">
                        <TranslatedText id="client.createMeasure" defaultMessage="Krijo Matje" />
                        <Tooltip placement="right" overlay={<span><TranslatedText id="app.clearForm"
                            defaultMessage="Pastro Formen" /></span>}>
                            <img className="create-client-icon" onClick={resetFields} src={clearFormSvg}
                                alt="create measure" />
                        </Tooltip>
                    </p>
                    <div className="w-100 mx-0 row d-flex justify-content-between">
                        <div className="w-100 d-flex flex-column mb-3">
                            <div className="col-12 position-relative">
                                {props?.client?.client?.id ?
                                    <div>
                                        <a onClick={() => setIsDropDownInfoOpen(!isDropDownInfoOpen)}>
                                            <Input
                                                value={firstAndLastName || ''}
                                                type="text"
                                                inputWrapperClass="w-100 nameSearch mb-0"
                                                disabled
                                                inputTextType={EInputTextType.SimpleInput}
                                                placeholder={translatedText({
                                                    id: "client.nameSurname",
                                                    defaultMessage: "Emri dhe mbiemri"
                                                })}>
                                                <img className="arrowdown" src={arrowDown1} alt="" />
                                            </Input>
                                        </a>
                                    </div>
                                    :
                                    <Input
                                        name="nameSearch"
                                        value={searchedClient || ''}
                                        type="text"
                                        inputWrapperClass="w-100 nameSearch mobileMargin"
                                        onChange={(name, value) => setSearchedClient(value)}
                                        inputTextType={EInputTextType.SimpleInput}
                                        placeholder={translatedText({
                                            id: "client.nameSurname",
                                            defaultMessage: "Emri dhe mbiemri"
                                        })} />
                                }
                                {searchedClient && <div className="suggestedClients-container-measures ">
                                    {clients.length === 0 ?
                                        <p className={'m-0 w-100 text-center'}>{language.noData}</p>
                                        :
                                        clients?.map((client) => {
                                            return (
                                                <div className="suggestedClients" key={client.id}
                                                    onClick={() => getTransferredDataFromModules(client.id)}>
                                                    <p className="m-0">{client.name}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>}
                                {isDropDownInfoOpen && <DataTransferComponent clientId={clientId} />}
                            </div>
                            <div className={'w-100 my-3 d-flex align-items-end small-tablet mobileAlignment'}>
                                <MikaSelect
                                    options={projectOptions}
                                    value={selectedProject || ''}
                                    setEntireOptionFunction={setSelectedProject}
                                    placeholder={'Projekti'}
                                    required
                                    disabled={true}
                                    showRequiredMessage={showRequiredMessage}
                                    requiredMessage={translatedText({
                                        id: "app.fieldIsRequired",
                                        defaultMessage: "Fusha eshte e nevojshme!"
                                    })}
                                    selectWrapperClassName="pr-12 w-50 heightFitContent mb-bottom mobileWidth" />
                                <MikaSelect
                                    options={measureStatuses}
                                    value={measureStatus || ''}
                                    setEntireOptionFunction={(e)=>checkMeasureStatus(e)}
                                    placeholder={'Statusi i matjes'}
                                    required
                                    showRequiredMessage={showRequiredMessage}
                                    requiredMessage={translatedText({
                                        id: "app.fieldIsRequired",
                                        defaultMessage: "Fusha eshte e nevojshme!"
                                    })}
                                    selectWrapperClassName="pl-12 w-50 heightFitContent mb-bottom mobileWidth" />
                            </div>
                            <div className="row justify-content-between align-items-start tablet-form">
                                <div className="w-50 d-flex flex-wrap small-tablet">
                                    {/*<MikaSelect*/}
                                    {/*    options={colors}*/}
                                    {/*    value={measureColor || ''}*/}
                                    {/*    setEntireOptionFunction={setMeasureColor}*/}
                                    {/*    placeholder={translatedText({*/}
                                    {/*        id: "client.productColor",*/}
                                    {/*        defaultMessage: "Ngjyra e produktit"*/}
                                    {/*    })}*/}
                                    {/*    selectWrapperClassName="col-6 pr-12 heightFitContent mt-auto mb-3 mobileWidth" />*/}

                                    <MikaTextInput className={'col-12 mobileWidth'} name={'productMaterial'} bordered
                                        labelKey={translatedText({
                                            id: "client.productMaterial",
                                            defaultMessage: "Materiali i produktit"
                                        })} />
                                    <MikaTextInput className={'col-6 heightFitContent pr-12 mobileWidth'} name={'measuringDate'}
                                        bordered
                                        value={measuringDate || ''}
                                        disabled
                                        labelKey={translatedText({
                                            id: "client.clientLocation",
                                            defaultMessage: "Data e matjes"
                                        })}>
                                        <Tooltip placement="right"
                                            overlay={<span>{language.saveDate}</span>}>
                                            <img src={calendarIcon} alt=""
                                                className="calendarIconForMeasure pinLogoForMeasure"
                                                onClick={getCurrentDate} />
                                        </Tooltip>
                                        {props.client?.client?.measureDate &&
                                            <p className={'googleMapsLocationLink'}>{translatedText({
                                                id: "measures.planedMeasuringDate",
                                                defaultMessage: "Data e planifikuar e matjes"
                                            })}: {props.client?.client?.measureDate ? moment(props.client?.client?.measureDate).format('DD.MM.YYYY') : ''}</p>}
                                    </MikaTextInput>
                                    <MikaTextInput className={'col-6 heightFitContent pl-12 mobileWidth'} name={'productLocation'}
                                        bordered
                                        value={productLocation || ''}
                                        onChange={checkProductLocation}
                                        labelKey={translatedText({
                                            id: "client.clientLocation",
                                            defaultMessage: "Lokacioni i klientit"
                                        })}>
                                        <Tooltip placement="right"
                                            overlay={<span>{language.locationGenerate}</span>}>
                                            <img src={locationPinSvg} alt="" className="pinLogoForMeasure"
                                                onClick={getMeasureLocation} />
                                        </Tooltip>
                                        {/*{productLocation &&*/}
                                        {/*    <a href={`https://www.google.com/maps/?q=${productLocation}`}*/}
                                        {/*        className={'googleMapsLocationLink'}*/}
                                        {/*        target='_blank'>{translatedText({*/}
                                        {/*            id: "client.clientLocationOnGoogleMaps",*/}
                                        {/*            defaultMessage: "Kliko per te pare lokacionin ne harte"*/}
                                        {/*        })}</a>}*/}

                                        {position ?
                                            <>
                                                {productLocation && <a target={'_blank'}
                                                                       href={`https://www.google.com/maps/dir/${position[0]},${position[1]}/${productLocation}/@${position[0]},${position[1]},15z?entry=ttu`}
                                                                       className={'mb-0 googleMapsLocationLink'}>Shiko ne Google maps</a>}
                                            </>
                                            :
                                            <>
                                                {productLocation && <a target={'_blank'}
                                                                       href={`https://www.google.com/maps/?q=${productLocation}`}
                                                                       className={'mb-0 googleMapsLink'}>Shiko ne Google maps</a>}
                                            </>
                                        }

                                    </MikaTextInput>
                                </div>
                                <div className="w-50 d-flex flex-column justify-content-start small-tablet">
                                    <Input
                                        name="label"
                                        label="label"
                                        id="label"
                                        defaultValue=''
                                        inputWrapperClass={"w-100 m-0"}
                                        type="textarea"
                                        value={details}
                                        inputTextType={EInputTextType.SimpleInput}
                                        onChange={(name, value) => setDetails(value)}
                                    >
                                        <TranslatedText id="client.measureComment"
                                                        defaultMessage="Komenti i matjes"/>
                                    </Input>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5 justify-content-between fileMobileAlignment">
                            <div className="w-25 mobileWidth">
                                <MikaFileInput labelKey={translatedText({
                                    id: "offer.addPDF",
                                    defaultMessage: "Shto nje skice"
                                })} multiple setFiles={setAddSketch} value={addSketch} className={'mt-3'} />
                                <MikaFileInput labelKey={translatedText({
                                    id: "offer.addPDF",
                                    defaultMessage: "Shto nje foto"
                                })} multiple setFiles={setAddPic} className={'mt-3'} value={addPic} accept="image/*" />
                                <MikaFileInput labelKey={translatedText({
                                    id: "offer.addPDF",
                                    defaultMessage: "Shto PDF"
                                })} multiple setFiles={setAddFile} className={'mt-3'} value={addFile} accept=".pdf" />
                            </div>
                            <div className="w-50 d-flex mobileWidthAndAlignment">
                                <div className={'w-50 mobileWidth'}>
                                    <p className="small-text product-measure-photos">
                                        <TranslatedText id="measures.productPhotos"
                                            defaultMessage="Template per matje" />
                                    </p>
                                    {props.measures.measureTemplates.map((template, index) => {
                                        return <div className={'template'} key={index}>
                                            <a href={template?.template} target='_blank'>{template?.nameOfFile}</a>
                                        </div>
                                    })}
                                </div>
                                <div className="w-50 mobileWidth">
                                    <div className="row">
                                        <div className={`d-flex justify-content-between ${isMobile ? 'mt-4' : ''}`}>
                                            <p className="small-text product-measure-photos">
                                                <TranslatedText id="measures.productPhotos"
                                                                defaultMessage="Assetet e matjes" />
                                            </p>
                                            <div className="measureImagesContainer">
                                                <Tooltip
                                                    placement="right"
                                                    overlay={
                                                        <span>
                                                            <TranslatedText id="app.clearForm"
                                                                            defaultMessage="Ruaj se pari oferten pastaj printo"/>
                                                        </span>
                                                    }>
                                                    <ReactToPrint
                                                        trigger={() => <button
                                                            // disabled={props.offers.length > 0}
                                                                               className="printButton position-relative d-flex align-items-center">
                                                            <p className={'measureImagesImgIndicator'}>{measureImages?.length}</p>
                                                            <p className="m-0">
                                                                <TranslatedText id="app.table.photos"
                                                                                defaultMessage="Fotot"/>
                                                            </p>
                                                            <img src={FileIcon} alt="" className="mx-2"/>
                                                        </button>}
                                                        content={() => componentRef.current}
                                                    />
                                                </Tooltip>
                                                <MeasureImages images={measureImages} ref={componentRef}/>
                                            </div>
                                        </div>
                                        {assets?.length > 0 &&
                                            <>
                                                {assets?.map((item, id) => {
                                                    return (
                                                        <div className="d-flex align-items-center mb-2 cursor-pointer position-relative" key={id}>
                                                            <img className="product-pic m-1" src={PICFile} alt="pic" />
                                                            <a target={'_blank'} href={item.fileUrl} className="link-pics">{item.name}</a>
                                                            <div className="fileRemovalButton" onClick={() => removeFile(item?.id)}>
                                                                <img src={DifferentColoredPlus} alt="close" />
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-100 mx-0 row d-flex justify-content-between">
                        <div className="w-100">
                            <div className="row d-flex justify-content-end flex-start-tablet">
                                {props?.client?.client?.id ? <div className={`w-25 buttonMobileWidth ${isMobile ? 'hugePaddingBottom':''}`}>
                                    {((!props.permissions.observer) || props.permissions.fullPrivileges) && <Button
                                        isLoading={props?.buttonLoader}
                                        isDisabled={props?.buttonLoader}
                                        onClick={saveMeasure}
                                        className="m-auto w-100"
                                        buttonType={EButtonType.GreyButton}>
                                        {props.measures.measureClient?.id ?
                                            <TranslatedText id="app.navigation.next"
                                                            defaultMessage="Ruaj"/> :
                                            <TranslatedText id="app.navigation.next"
                                                            defaultMessage="Krijo"/>
                                        }
                                    </Button>}
                                </div> : ''}
                            </div>
                        </div>
                    </div>
                </FormikFormWrapper>
            </div>
        )
    }

    return (
        <div className="wrapper">
            {loading ? <Spinner /> :
                measureForm()
            }
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        measures: state.measures,
        admin: state.admin
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        clientActions: bindActionCreators(clientActions as any, dispatch),
        measuresActions: bindActionCreators(measuresActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch),
        adminActions: bindActionCreators(adminActions as any, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateMeasureForm)))
import * as constants from '../constants/constants';
import { axiosCaller } from "../utils/apiCaller";
import {ACTION, setButtonLoading, setLoading, wrap} from './generalActions'

export const fetchEquipments = () => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `mounting-equipments`).then(res => {
            dispatch({ "type": constants.FETCH_EQUIPMENTS, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const fetchTransportProjects = () => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `transport`).then(res => {
            dispatch({ "type": constants.FETCH_TRANSPORT_PROJECTS, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const deleteTransportRecord = (transportRecordId) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('DELETE', `transport/${transportRecordId}`).then(res => {
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')


export const fetchTransportEquipments = (payload) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('POST', `transport/equipments`, payload).then(res => {
            dispatch({ "type": constants.FETCH_TRANSPORT_EQUIPMENTS, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const createTransportRecord = (payload) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('POST', `transport`, payload).then(res => {
            return res
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const editTransportRecord = (payload, id) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('POST', `transport/${id}`, payload).then(res => {
            return res
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const getAllTransportRecords = () => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `transport/transports-all`).then(res => {
            dispatch({ "type": constants.FETCH_TRANSPORT_RECORDS, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const getProjectsForTransport = (query?, startDate?, endDate?) => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `transport/not-installation${query ? `?${query}` : ''}${query && startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}`:`${startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}`:``}`}`).then(res => {
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            dispatch({ "type": constants.FETCH_PROJECTS_FOR_TRANSPORT, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const getTransportRecord = (transportId) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `transport/${transportId}/transport`).then(res => {
            dispatch({ "type": constants.FETCH_TRANSPORT_RECORD, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const getTransportRecordsByCode = (code) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `transport/${code}/code`).then(res => {
            dispatch({ "type": constants.FETCH_TRANSPORT_RECORD_CODE, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')


export const getWarehouseRecordsReadyForTransport = () => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `transport/warehouse-records`).then(res => {
            dispatch({ "type": constants.GET_WAREHOUSE_RECORDS_READY_FOR_TRANSPORT, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const setSelectedWarehouseRecordForTransport = (payload: any): ACTION => ({
    type: constants.SET_SELECTED_WAREHOUSE_RECORD_FOR_TRANSPORT,
    payload
});

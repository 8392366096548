import { Asset } from './../types/asset.type';
import * as constants from "../constants/constants";

export interface OffersState {
    clientId: number;
    offerStatusId: number;
    firstNameAndLastName: string;
    date: string;
    fiscalNumber: number;
    address: string;
    phoneNumber: string;
    details: string;
    unit: number;
    offerId: string;
    paymentDeadline: string;
    accountNumber: number;
    comment:string;
    total:number;
    offersBasedOnWorker: object;
    offerClient: object;
    allClientsOffers: Array<any>;
    types: Array<TemplateStringsArray>;
    offerAssets: Array<Asset>;
    offerMessage: string;
    discountType: any,
    discountValue: any,
    discountTotal: any,
    canceledProducts: any
}

const initialState = () => {
    return {
        clientId: null,
        offerStatusId: null,
        firstNameAndLastName: '',
        date: '',
        fiscalNumber: null,
        address: '',
        phoneNumber: '',
        details: '',
        unit: null,
        offerId: null,
        paymentDeadline: null,
        accountNumber: null,
        comment:"",
        total:null,
        offersBasedOnWorker: {},
        offerClient: {},
        allClientsOffers: [],
        types: [],
        tax: [],
        taxPrice: [],
        designation: [],
        price: [],
        productUnit: [],
        start: [],
        discount: [],
        products: [],
        product: {},
        offerAssets: [],
        offerMessage: '',
        discountType: null,
        discountValue: null,
        discountTotal: '',
        canceledProducts: []
    }
}

const offers = (state: OffersState = initialState(), action: any) => {
    switch (action.type) {
        case constants.FETCH_ALL_CLIENT_OFFERS: {
            return { 
                ...state, 
                allClientsOffers: action.payload,
            };
        }
        case constants.FETCH_CLIENT_OFFERS: {
            return { 
                ...state, 
                offerClient: action.payload.data
            };
        }
        case constants.EDIT_OFFER: {
            return { 
                ...state, 
                offerClient: action.payload.data.data
            };
        }
        case constants.CREATE_OFFERS: {
            return { 
                ...state, 
                clientId: action.payload.data.clientId,
                offerStatusId: action.payload.data.offerStatusId,
                firstNameAndLastName:action.payload.data.firstNameAndLastName,
                date: action.payload.data.date,
                fiscalNumber: action.payload.data.fiscalNumber,
                address:action.payload.data.address,
                phoneNumber: action.payload.data.phoneNumber,
                details:action.payload.data.details,
                unit: action.payload.data.unit,
                offerId: action.payload.data.offerId,
                paymentDeadline: action.payload.data.paymentDeadline,
                accountNumber: action.payload.data.accountNumber,
                // offerClient: action.payload.data
            };
        }
        case constants.SET_NAME_SURNAME_OFFER: {
            return {
                ...state,
                firstNameAndLastName: action.payload
            }
        }
        case constants.SET_DATE_OFFER: {
            return {
                ...state,
                date: action.payload
            }
        }
        case constants.SET_FISCAL_NUMBER: {
            return {
                ...state,
                fiscalNumber: action.payload
            }
        }
        case constants.SET_ADDRESS: {
            return {
                ...state,
                address: action.payload
            }
        }
        case constants.SET_UNIT: {
            return {
                ...state,
                unit: action.payload
            }
        }
        case constants.SET_OFFER_ID: {
            return {
                ...state,
                offerId: action.payload
            }
        }
        case constants.SET_PAYMENT_DEADLINE: {
            return {
                ...state,
                paymentDeadline: action.payload
            }
        }
        case constants.SET_ACCOUNT_NUMBER: {
            return {
                ...state,
                accountNumber: action.payload
            }
        }
        case constants.SET_DISCOUNT_VALUE: {
            return {
                ...state,
                discountValue: action.payload
            }
        }
        case constants.SET_DISCOUNT_TOTAL: {
            return {
                ...state,
                discountTotal: action.payload
            }
        }
        case constants.SET_DISCOUNT_TYPE: {
            return {
                ...state,
                discountType: action.payload
            }
        }
        case constants.SET_TYPES: {
            return {
                ...state,
                types: action.payload
            }
        }
        case constants.SET_DISCOUNT: {
            return {
                ...state,
                discount: action.payload
            }
        }
        case constants.SET_TAX: {
            return {
                ...state,
                tax: action.payload
            }
        }
        case constants.SET_TAX_PRICE: {
            return {
                ...state,
                taxPrice: action.payload
            }
        }
        case constants.SET_PRODUCT_UNIT: {
            return {
                ...state,
                productUnit: action.payload
            }
        }
        case constants.SET_START_DATE: {
            return {
                ...state,
                start: action.payload
            }
        }
        case constants.SET_PRICE: {
            return {
                ...state,
                price: action.payload
            }
        }
        case constants.SET_DESIGNATION: {
            return {
                ...state,
                designation: action.payload
            }
        }
        case constants.SET_PRODUCTS: {
            return {
                ...state,
                products: action.payload
            }
        }
        case constants.SET_COMMENT: {
            return {
                ...state,
                comment: action.payload
            }
        }
        case constants.SET_OFFER_PRODUCT: {
            return {
                ...state,
                product: action.payload
            }
        }
        case constants.SET_ALL_OFFERS: {
            return {
                ...state,
                allClientsOffers: action.payload
            }
        }
        case constants.RESET_OFFER_CLIENT: {
            return {
                ...state,
                offerClient: {}
            }
        }
        case constants.GET_ASSETS: {
            return {
                ...state,
                offerAssets: action.payload.data
            }
        }
        case constants.GET_CANCELED_PRODUCTS: {
            return {
                ...state,
                canceledProducts: action.payload.data
            }
        }
    }
    return state;
}

export default offers;
import * as constants from "../constants/constants";
import {Project} from "../types/Project.type";

export interface ClientState {
    nameSurname: string;
    address: string;
    phone: number;
    clientEmail: string;
    comments: string;
    businessNr: number;
    bankAccountNr: number;
    bankName: string;
    city: string;
    country: string;
    contactPerson: string;
    productCode: number;
    order: string;
    productImg: any;
    client: any;
    clients: any;
    clientId: number;
    deletedState: boolean;
    measuringDate: string;
    measuringAssignee: string;
    productLocation: string;
    clientProjects: Project[]
}

const initialState = () => {
    return {
        nameSurname: "",
        address: "",
        phone: null,
        clientEmail: "",
        comments: "",
        businessNr: null,
        bankAccountNr: null,
        bankName: "",
        city: "",
        country: "",
        contactPerson: "",
        productCode: null,
        order: "",
        productImg: "",
        client: {},
        clients: [],
        clientId:null,
        deletedState:false,
        measuringDate: "",
        measuringAssignee: "",
        productLocation: "",
        clientProjects: []
    }
}

const client = (state: ClientState = initialState(), action: any) => {
    switch (action.type) {
        case constants.SET_NAME_SURNAME: {
            return {
                ...state,
                nameSurname: action.payload
            }
        }
        case constants.CREATE_CLIENT: {
            return {
                ...state,
                client: action.payload,
                nameSurname: action.payload,
                address: action.payload,
                phone: action.payload,
                clientEmail: action.payload,
                comments: action.payload,
                businessNr: action.payload,
                bankAccountNr: action.payload,
                bankName: action.payload,
                city: action.payload,
                country: action.payload,
                contactPerson: action.payload,
                productCode: action.payload,
                order: action.payload,
                productImg: action.payload
            }
        }
        case constants.EDIT_CLIENT: {
            return {
                ...state,
                nameSurname: action.payload,
                address: action.payload,
                phone: action.payload,
                clientEmail: action.payload,
                comments: action.payload,
                businessNr: action.payload,
                bankAccountNr: action.payload,
                bankName: action.payload,
                city: action.payload,
                country: action.payload,
                contactPerson: action.payload,
                productCode: action.payload,
                order: action.payload,
                productImg: action.payload
            }
        }
        case constants.DELETE_CLIENT: {
            return {
                ...state,
                clients: action.payload.data
            }
        }
        case constants.SET_USER_TO_DELETE: {
            return {
                ...state,
                clientId: action.payload
            }
        }
        case constants.SET_DELETED_STATE: {
            return {
                ...state,
                deletedState: action.payload
            }
        }
        case constants.FETCHED_CLIENT: {
            return { 
                ...state, 
                client: action.payload.data
            };
        }
        case constants.FETCHED_CLIENTS: {
            return { 
                ...state, 
                clients: action.payload.data
            };
        }
        case constants.IMPORT_CLIENTS: {
            return {
                ...state,
                // importClients: action.payload
            }
        }
        case constants.RESET_CLIENT: {
            return {
                ...state,
                client: action.payload
            }
        }
        case constants.PRODUCT_LOCATION: {
            return {
                ...state,
                productLocation: action.payload
            }
        }
        case constants.GET_CLIENT_PROJECTS: {
            return {
                ...state,
                clientProjects: action.payload
            }
        }
    }
    return state;
}

export default client;
import * as React from 'react';
import classNames from 'classnames';
import style from './Spinner.module.scss'; // Import css modules stylesheet as styles



export interface Props {
    color?: string;
    text?: string;
    relative?: boolean;
    button?: boolean;
    loadMore?: boolean;
    pdf?: boolean;
    isTransparent?: boolean;
}

const pdf = () => {
    var url_string = window.location.href; //window.location.href
    if (url_string.includes("content=pdf")) {
        return true;
    } else {
        return false;
    }
}

const Spinner = (props: Props) => (

    props.relative ?
        ((pdf() && props.pdf) || !pdf()) ? <div className={classNames(style.loading, { [style.relative]: props.relative })}>
           <div className={classNames(style.wrapper, {
                [style.backgroundTransparent]: props.isTransparent
           })}>
                <div className={style.loader}>
                    <svg  width="72px" height="70px" viewBox="0 0 72 70" version="1.1">
                        <title>Path 3</title>
                        <desc>Created with Sketch.</desc>
                        <defs>
                            <linearGradient x1="90.7424418%" y1="2.73919753%" x2="2.25179582%" y2="72.1581015%" id="linearGradient-2">
                                <stop stopColor="#A7B871" stopOpacity="0" offset="0%"/>
                                <stop stopColor="#C4D787" offset="100%"/>
                            </linearGradient>
                        </defs>
                        <g id="✅-Feather-(Neutral)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="Download" transform="translate(-551.000000, -507.000000)" fill="url(#linearGradient-2)">
                                <g id="File-Upload" transform="translate(443.000000, 383.000000)">
                                    <path d="M118.790565,172.684191 C123.485115,177.856854 128.85678,181.160258 134.90556,182.594402 C149.756776,186.115573 165.324162,179.779977 172.219183,164.578278 C175.030133,157.794992 175.370135,151.692369 174.763229,147.299622 C173.423697,137.604159 168.503828,129.837619 160.003624,124 C176.490109,134.595857 182.78209,148.020061 178.879566,164.272611 C176.891039,172.55408 172.251192,179.054122 167.392355,183.636036 C159.654226,190.933138 150.651175,194.37691 140.383202,193.967352 C128.11136,193.51392 118.211162,188.699304 110.68261,179.523504 C108.238858,176.545059 106.44904,172.590792 109.989192,169.945546 C112.349293,168.182048 115.283084,169.09493 118.790565,172.684191 Z" id="Path-3"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        </div> : null
    : <div className={classNames(style.loading, { 
        [style.relative]: props.relative,
        [style.backgroundTransparent]: props.isTransparent })}>
        <div className={style.wrapper}>
          <div className={style.loader}>
            <svg
              version="1.1"
              id="loader-1"
              x="0px"
              y="0px"
              viewBox="0 0 50 50"
            >
              <path d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                <animateTransform
                  attributeType="xml"
                  attributeName="transform"
                  type="rotate"
                  from="0 25 25"
                  to="360 25 25"
                  dur="0.8s"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </div>
        </div>
        <p>{props.text}</p>
      </div>
);

export default Spinner;

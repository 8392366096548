import * as constants from '../constants/constants';
import { axiosCaller } from "../utils/apiCaller";
import {ACTION, setLoading, setButtonLoading, wrap} from './generalActions'
import {GET_MATERIALS_OF_PRODUCT} from "../constants/constants";

export const fetchResponsibilities = () => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `responsibilities`).then(res => {
            dispatch({ "type": constants.FETCH_RESPONSIBILITIES, "payload": res.data.data });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_RESPONSIBILITIES')


export const fetchUserResponsibilities = () => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `responsibilities/user-responsibilities`).then(res => {
            dispatch({ "type": constants.FETCH_USER_RESPONSIBILITIES, "payload": res.data.data });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_USER_RESPONSIBILITIES')

export const fetchProductionTableData = (clientId: number) => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `clients/${clientId}/products`).then(res => {
            dispatch({ "type": constants.FETCH_PRODUCTION_TABLE_DATA, "payload": res.data });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_PRODUCTION_TABLE_DATA')

export const fetchProductStatuses = (departmentId?, startDate?, endDate?, done?, searchName?, responsibilityId?, responsibilityStatusId?, sort?, filterDatesBy?, shipmentDestinationId?) => wrap(async (dispatch: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `clients/products-statuses?moduleId=5&active=1${done ? `&done=${done}` : '&done=0'}${departmentId && departmentId != 'null' ? `&departmentId=${departmentId}`: ''}${startDate && endDate ? `&start=${startDate}&end=${endDate}` : ''}${searchName ? `&name=${searchName}`: ''}${responsibilityId ? `&responsibilityId=${responsibilityId}`: ''}${responsibilityStatusId ? `&responsibilityStatusId=${responsibilityStatusId}`: ''}${sort ? `&sort=${sort}` : ''}${filterDatesBy ? `&filterDatesBy=${filterDatesBy}` : ''}${shipmentDestinationId ? `&shipmentDestinationId=${shipmentDestinationId}` : ''}`).then(res => {
            dispatch({ "type": constants.FETCH_PRODUCT_STATUSES, "payload": res.data });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_PRODUCT_STATUSES')

export const fetchProductPrices = (departmentId?, startDate?, endDate?, done?, searchName?, responsibilityId?, responsibilityStatusId?, sort?, filterDatesBy?, measureStatusId?) => wrap(async (dispatch: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `products/view-price?active=1${done ? `&done=${done}` : '&done=0'}${departmentId && departmentId != 'null' ? `&departmentId=${departmentId}`: ''}${startDate && endDate ? `&start=${startDate}&end=${endDate}` : ''}${searchName ? `&name=${searchName}`: ''}${responsibilityId ? `&responsibilityId=${responsibilityId}`: ''}${responsibilityStatusId ? `&responsibilityStatusId=${responsibilityStatusId}`: ''}${sort ? `&sort=${sort}` : ''}${filterDatesBy ? `&filterDatesBy=${filterDatesBy}` : ''}${measureStatusId? `&measureStatusId=${measureStatusId}` : ""}`).then(res => {
            dispatch({ "type": constants.FETCH_PRODUCT_PRICES, "payload": res.data });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_PRODUCT_STATUSES')

export const fetchPassiveProductStatuses = (departmentId?, startDate?, endDate?) => wrap(async (dispatch: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `clients/products-statuses?moduleId=5&active=0&done=0${departmentId && departmentId != 'null' ? `&departmentId=${departmentId}`: ''}${startDate && endDate ? `&start=${startDate}&end=${endDate}` : ''}`).then(res => {
            dispatch({ "type": constants.FETCH_PASSIVE_PRODUCTS, "payload": res.data });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_PRODUCT_STATUSES')

export const fetchWarehouseProducts = (startDate?, endDate?, departmentId?, shipmentDestinationId?) => wrap(async (dispatch: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `products/warehouse-products${startDate && endDate ? `?start=${startDate}&end=${endDate}` : ''}${departmentId ? startDate && endDate ? `&departmentId=${departmentId}`: `?departmentId=${departmentId}` :''}${shipmentDestinationId ? (startDate && endDate) || departmentId ? `&shipmentDestinationId=${shipmentDestinationId}`: `?shipmentDestinationId=${shipmentDestinationId}` :''}`).then(res => {
            dispatch({ "type": constants.FETCH_WAREHOUSE, "payload": res.data });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_WAREHOUSE')

export const addArticlesToWarehouse = (payload) => wrap(async (dispatch: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('POST', `warehouse/store`, payload).then(res => {
            dispatch({ "type": constants.ADD_ARTICLES_TO_WAREHOUSE, "payload": res.data });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'ADD_ARTICLES_TO_WAREHOUSE')

export const updateProductDates = (payload) => wrap(async (dispatch: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('POST', `admin/products/change-start-end-date`, payload).then(res => {
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            return res
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'UPDATE_PRODUCT_DATES')

export const getMaterialsForProductAndResponsibility = (productId: number, responsibilityId: number) => wrap(async (dispatch: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `products/responsibilities-materials?productId=${productId}&responsibilityId=${responsibilityId}`).then((res) => {
            dispatch({ "type": constants.GET_MATERIALS_FOR_PRODUCTS_AND_RESPONSIBILITY, "payload": res.data });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            return res.data.data
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'UPDATE_PRODUCT_DATES')

export const createBlueprint = (payload) => wrap(async (dispatch: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('POST', `project-blueprint`, payload).then(() => {
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'CREATE_BLUEPRINT_FILES')

export const getBlueprintFiles = (productId) => wrap(async (dispatch: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `project-blueprint/${productId}/products`).then((res) => {
            dispatch({ "type": constants.GET_BLUEPRINT_FILES, "payload": res.data });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'GET_BLUEPRINT_FILES')

export const deleteBlueprintFile = (fileId) => wrap(async (dispatch: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('DELETE', `project-blueprint/${fileId}`).then(() => {
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'GET_BLUEPRINT_FILES')

export const saveTimeTracked = (payload) => wrap(async (dispatch: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('POST', `trackers`, payload).then(() => {
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'CREATE_BLUEPRINT_FILES')

export const editTimeTracked = (payload, trackId) => wrap(async (dispatch: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('POST', `trackers/${trackId}`, payload).then(() => {
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'CREATE_BLUEPRINT_FILES')

export const getCurrentTimeTracked = (payload) => wrap(async (dispatch: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return await axiosCaller('POST', `trackers/tracker`, payload).then((res) => {
            dispatch({ "type": constants.GET_CURRENT_RESPONSIBILITY_TIME, "payload": res.data.data });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'CREATE_BLUEPRINT_FILES')

export const getMaterialsOfProduct = (productId) => wrap(async (dispatch: any) => {
    try {
       dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `clients/materials/statuses?productId=${productId}`).then((res) => {
            dispatch({ "type": constants.GET_MATERIALS_OF_PRODUCT, "payload": res.data?.data });
            dispatch(setButtonLoading(false));
            return res?.data?.data
        })
    } catch(err) {
        dispatch(setButtonLoading(false));
    }
}, 'CREATE_BLUEPRINT_FILES')


export const setSelectedTrack = (payload: any): ACTION => ({
    type: constants.GET_CURRENT_RESPONSIBILITY_TIME,
    payload
});
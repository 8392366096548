import { useEffect,useState } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import { Switch,Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import CreateUserForm from './CreateUserForm'
import ShowAllUsers from './ShowAllUsers';
import Responsibilities from './Responsibilities';
import Equipments from './Equipments';
import Units from './Units';
import Articles from '../Admin/Articles';
import AuditLog from './AuditLog';
import OfferFields from './OfferFields';
import OfferConditions from './OfferConditions';
import MeasurmentSketchTemplate from './MeasurmentSketchTemplate';
import {checkIfUserIsAuthorized} from "./globalFunctions";
import {toast} from "react-toastify";
import ColorPalettes from "./ColorPalettes";
import Materials from './Materials';
import Suppliers from './Suppliers'
import ProductionSettings from './ProductionSettings'
import ImpressionQuestions from "./ImpressionQuestions";
import Page404 from "../Page404";
import Departments from "./Departments";
import {translatedText} from "../../components/TranslatedText";
import UsersDashboard from "./UsersDashboard/UsersDashboard";

interface Props {
    intl: any;
    permissions: any;
    history: any;
    actions: any
}

const Admin = (props: Props) => {

    useEffect(()=>{
        props.actions.getUserPermissionsAndResponsibilities()
    },[])

    useEffect(()=>{
        console.log(props.permissions,'props.permissions')
        if(Object.keys(props.permissions).length !== 0 && !checkIfUserIsAuthorized(props.permissions, props.permissions?.fullPrivileges, false)){
            toast.error(translatedText({
                id: "app.yourNotAuthenticatedToModifyDataForThisModule",
                defaultMessage: "Nuk keni qasje ne modifikimin e te dhenave te ketij moduli!"
            }))
            props.history.push('/dashboard/contact')
        }
    }, [props.permissions])

    return (
        <div className="admin-page-container">
            <Switch>
                <Route exact path="/admin" component={UsersDashboard} />
                <Route exact path="/admin/all-users" component={ShowAllUsers} />
                <Route exact path="/admin/create-user/:userId?" component={CreateUserForm} />
                <Route exact path="/admin/responsibilities" component={Responsibilities} />
                <Route exact path="/admin/departments" component={Departments} />
                <Route exact path="/admin/equipments" component={Equipments} />
                <Route exact path="/admin/units" component={Units} />
                <Route exact path="/admin/articles" component={Articles} />
                <Route exact path="/admin/suppliers" component={Suppliers} />
                <Route exact path="/admin/audit-log" component={AuditLog} />
                <Route exact path="/admin/measurement-sketch-templates" component={MeasurmentSketchTemplate} />
                <Route exact path="/admin/color-palettes" component={ColorPalettes} />
                <Route exact path="/admin/offer-fields" component={OfferFields} />
                <Route exact path="/admin/offer-conditions" component={OfferConditions} />
                <Route exact path="/admin/impression-questions" component={ImpressionQuestions} />
                <Route exact path="/admin/materials" component={Materials} />
                <Route exact path="/admin/production-settings" component={ProductionSettings} />
                <Route render={()=><Page404/>}/>
            </Switch>
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Admin as any)))
import React, {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as offerActions from '../../actions/offers';
import * as clientActions from '../../actions/client';
import * as measuresActions from '../../actions/measures'
import * as agreementsActions from '../../actions/agreements'
import {withRouter} from 'react-router';
import PICFile from "../../assets/icons/pic.svg";
import './DataTransferComponent.scss'
import moment from "moment";
import TranslatedText, {translatedText} from "../TranslatedText";
import {LatLngExpression} from "leaflet";
import {isMobile} from "../../constants/constants";

interface Props {
    intl: any;
    actions: any;
    client: any;
    clientActions: any;
    measures: any,
    measuresActions: any;
    offers: any;
    offerActions: any;
    measureNeeded?: boolean,
    offerNeeded?: boolean;
    agreementNeeded?: boolean,
    agreements: any
    agreementsActions: any;
    clientId?: number,
    projectDetails: any
    noProjectDetails?: boolean
    noContactDetails?: boolean
}

const DataTransferComponent = (props: Props) => {

    const [measureAssets, setMeasureAssets] = useState([])
    const [offerAssets, setOfferAssets] = useState([])
    const [agreementAssets, setAgreementAssets] = useState([])
    const [projectDetails, setProjectDetails] = useState<any>([])
    const [measure, setMeasure] = useState<any>({})
    const [offer, setOffer] = useState<any>({})
    const [agreement, setAgreement] = useState<any>({})
    const [position, setPosition] = useState<LatLngExpression>()

    useEffect(()=>{
        getCurrentPosition()
    },[])

    function getCurrentPosition() {
        navigator.geolocation.getCurrentPosition(function (geoLocationPosition) {
            if (geoLocationPosition?.coords?.latitude && geoLocationPosition?.coords?.longitude) {
                console.log(geoLocationPosition?.coords)
                setPosition([geoLocationPosition.coords.latitude, geoLocationPosition.coords.longitude])
            }
        },(err)=> {
            console.warn(`ERROR(${err.code}): ${err.message}`)
        }, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 60000,
        });
    }

    useEffect(() => {
        setMeasure(props?.projectDetails?.measure ? props?.projectDetails?.measure : {})
        setOffer(props?.projectDetails?.offer ? props?.projectDetails?.offer : {})
        setAgreement(props?.projectDetails?.agreement ? props?.projectDetails?.agreement : {})
        setProjectDetails(props?.projectDetails ? props?.projectDetails : {})
    }, [props.projectDetails])

    useEffect(() => {
        let tempArray = []
        measure?.measureFiles?.forEach((file) => {
            if (file?.measureWithUrl) {
                tempArray.push({id: file.id, fileUrl: file?.measureWithUrl, name: file?.measureName})
            } else if (file?.pictureWithUrl) {
                tempArray.push({id: file.id, fileUrl: file?.pictureWithUrl, name: file?.pictureName})
            } else if (file?.sketchWithUrl) {
                tempArray.push({id: file.id, fileUrl: file?.sketchWithUrl, name: file?.sketchName})
            }
        })
        setMeasureAssets(tempArray)
    }, [measure])

    useEffect(() => {
        let tempArray = []
        offer?.offerFiles?.forEach((file) => {
            if (file?.finalOfferWithUrl) {
                tempArray.push({id: file.id, fileUrl: file?.finalOfferWithUrl, name: file?.finalOfferName})
            } else if (file?.pdfOfferWithUrl) {
                tempArray.push({id: file.id, fileUrl: file?.pdfOfferWithUrl, name: file?.pdfOfferName})
            } else if (file?.requirementWithUrl) {
                tempArray.push({id: file.id, fileUrl: file?.requirementWithUrl, name: file?.requirementName})
            }
        })
        setOfferAssets(tempArray)
    }, [offer])

    useEffect(() => {
        let tempArray = []
        agreement?.agreementFiles?.forEach((file) => {
            if (file?.signatureWithUrl) {
                tempArray.push({id: file.id, fileUrl: file?.signatureWithUrl, name: file?.signature})
            } else if (file?.finalAgreementWithUrl) {
                tempArray.push({id: file.id, fileUrl: file?.finalAgreementWithUrl, name: file?.finalAgreementName})
            } else if (file?.annexesWithUrl) {
                tempArray.push({id: file.id, fileUrl: file?.annexesWithUrl, name: file?.annexes})
            } else if (file?.cancelationWithUrl) {
                tempArray.push({id: file.id, fileUrl: file?.cancelationWithUrl, name: file?.cancelation})
            }
        })
        setAgreementAssets(tempArray)
    }, [agreement])

    return <div className="gatheredInfoWrapper">
        <div className={'gatheredInfoContainer'}>
            {(!props?.noProjectDetails) && <div className="projectDetails">
                <div className="data-section">
                    <div className="data">
                        <p className="labelData">Emri i projektit:</p>
                        <p>{projectDetails?.projectName}</p>
                    </div>
                    <div className="data">
                        <p className="labelData">Lokacioni i projektit:</p>
                        <p>{projectDetails?.projectLocation}</p>
                        {projectDetails?.projectLocation &&
                        <a href={`https://www.google.com/maps/?q=${projectDetails?.projectLocation}`}
                           target='_blank'>{translatedText({
                            id: "client.clientLocationOnGoogleMaps",
                            defaultMessage: "Kliko per te pare lokacionin ne harte"
                        })}</a>}
                    </div>
                </div>
            </div>}
            <div className={`d-flex ${isMobile ? "flex-wrap": ""}`}>
                {props.client?.client && (!props?.noContactDetails) && <div className="col-flex">
                    <div className={'data'}>
                        <p className={'labelData'}>Kontakti</p>
                    </div>
                    {projectDetails?.projectFiles?.length !== 0 &&
                    <>
                        <p className="small-text product-measure-photos">
                            <TranslatedText id="measures.productPhotos"
                                              defaultMessage="Assetet e kontaktit"/>
                        </p>
                        {projectDetails?.projectFiles?.map((item, id) => {
                            return (
                                <div className="d-flex align-items-center mb-2 cursor-pointer" key={id}>
                                    <img className="product-pic m-1" src={PICFile} alt="pic"/>
                                    <a target={'_blank'} href={item?.fileUrl} className="link-pics">{item?.fileName}</a>
                                </div>
                            )
                        })}
                    </>
                    }
                    <div className="data-section">
                        <div className="data">
                            <p className="labelData">Telefoni:</p>
                            <p>{props.client?.client?.phoneNumber}</p>
                        </div>

                        <div className="data">
                            <div>
                                <p className="labelData">Qyteti:</p>
                                <p className="city">{props.client?.client?.city}</p>
                            </div>
                        </div>
                        <div className="data">
                            <div>
                                <p className="labelData">Adresa:</p>
                                <p>{props.client?.client?.address}</p>
                            </div>
                        </div>
                        <div className="data">
                            <p className="labelData">Shteti:</p>
                            <p>{props.client?.client?.country}</p>
                        </div>
                    </div>
                </div>}
                {props.measureNeeded && <div className="col-flex">
                    <div className={'data'}>
                        <p className={'labelData'}>Matja</p>
                    </div>
                    {measureAssets?.length !== 0 &&
                    <>
                        <p className="small-text product-measure-photos">
                            <TranslatedText id="measures.productPhotos"
                                              defaultMessage="Assetet e matjes"/>
                        </p>
                        {measureAssets?.map((item, id) => {
                            return (
                                <div className="d-flex align-items-center mb-2 cursor-pointer" key={id}>
                                    <img className="product-pic m-1" src={PICFile} alt="pic"/>
                                    <a target={'_blank'} href={item?.fileUrl} className="link-pics">{item?.name}</a>
                                </div>
                            )
                        })}
                    </>
                    }
                    <div className="data-section">
                        {/*<div className="data">*/}
                        {/*    <p className="labelData">Ngjyra e produktit:</p>*/}
                        {/*    <p>{measure?.productColor?.label}</p>*/}
                        {/*</div>*/}

                        <div className="data">
                            <div>
                                <p className="labelData">Lokacioni i klientit:</p>
                                <p className="city">{measure?.productLocation}</p>
                                {measure?.productLocation && position && <a target={'_blank'}
                                                                       href={`https://www.google.com/maps/dir/${position[0]},${position[1]}/${measure?.productLocation}/@${position[0]},${position[1]},15z?entry=ttu`}
                                                                       className={'mb-0 googleMapsLink'}>Shiko ne Google maps</a>}
                            </div>
                        </div>
                        <div className="data">
                            <div>
                                <p className="labelData">Materiali i produktit:</p>
                                <p>{measure?.productMaterial}</p>
                            </div>
                        </div>
                        <div className="data">
                            <p className="labelData">Data e matjes:</p>
                            <p>{measure?.startDate}</p>
                        </div>
                    </div>
                </div>}
                {props?.offerNeeded && <div className="col-flex">
                    <div className={'data'}>
                        <p className={'labelData'}>Oferta</p>
                    </div>
                    {offerAssets?.length !== 0 &&
                    <>
                        <p className="small-text product-measure-photos">
                            <TranslatedText id="measures.productPhotos"
                                              defaultMessage="Assetet e ofertes"/>
                        </p>
                        {offerAssets?.map((item, id) => {
                            return (
                                <div className="d-flex align-items-center mb-2 cursor-pointer" key={id}>
                                    <img className="product-pic m-1" src={PICFile} alt="pic"/>
                                    <a target={'_blank'} href={item?.fileUrl} className="link-pics">{item?.name}</a>
                                </div>
                            )
                        })}
                    </>
                    }
                    <div className="data-section">
                        <div className="data">
                            <p className="labelData">Data e ofertes:</p>
                            <p>{moment(offer?.offerDate).format('DD.MM.YYYY')}</p>
                        </div>

                        <div className="data">
                            <p className="labelData">Statusi i ofertes:</p>
                            <p className="city">{offer?.offerStatus?.label}</p>
                        </div>
                    </div>
                </div>}
                {props?.agreementNeeded && <div className="col-flex">
                    <div className="data-section">
                        <div className="data">
                            <p className="labelData">Parapagesa:</p>
                            <p>{agreement?.prepaid}</p>
                        </div>

                        <div className="data">
                            <p className="labelData">Borxhi i mbetur:</p>
                            <p className="city">{agreement?.totalDebtLeft}</p>
                        </div>
                        <div className="data">
                            <p className="labelData">Totali:</p>
                            <p>{agreement?.total}</p>
                        </div>
                        <div className="data">
                            <p className="labelData">Statusi i marrveshjes:</p>
                            <p>{agreement?.agreementStatus?.label}</p>
                        </div>
                    </div>
                    {agreementAssets?.length !== 0 &&
                    <>
                        <p className="small-text product-measure-photos">
                            <TranslatedText id="measures.productPhotos"
                                              defaultMessage="Assetet e marrveshjes"/>
                        </p>
                        {agreementAssets?.map((item, id) => {
                            return (
                                <div className="d-flex align-items-center mb-2 cursor-pointer" key={id}>
                                    <img className="product-pic m-1" src={PICFile} alt="pic"/>
                                    <a target={'_blank'} href={item?.fileUrl} className="link-pics">{item?.name}</a>
                                </div>
                            )
                        })}
                    </>
                    }
                </div>}
            </div>

            <div className={`d-flex ${isMobile ? "flex-wrap" : ""}`}>
                {(!props?.noProjectDetails) && <div className="col-flex comment">
                    <p>Komentet e projektit</p>
                    <p>{projectDetails?.projectComment}</p>
                </div>}

                {props.measureNeeded && <div className="col-flex comment">
                    <p>Komentet e matjes</p>
                    <p>{measure?.details}</p>
                </div>}

                {props.offerNeeded && <div className="col-flex comment">
                    <p>Komentet e ofertes</p>
                    <p>{offer?.addition}</p>
                </div>}

                {props.agreementNeeded && <div className="col-flex comment">
                    <p>Komentet e marrveshjes</p>
                    <p>{agreement?.comment}</p>
                </div>}
            </div>
        </div>
    </div>
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        measures: state.measures,
        offers: state.offers,
        agreements: state.agreements
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        measuresActions: bindActionCreators(measuresActions as any, dispatch),
        offerActions: bindActionCreators(offerActions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch),
        agreementsActions: bindActionCreators(agreementsActions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(DataTransferComponent as any)));
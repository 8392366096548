import { Dispatch, bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import * as agreementsActions from '../../actions/agreements';
import * as measuresActions from '../../actions/measures';
import { withRouter } from 'react-router';
import { useEffect } from 'react';
import { Client, ClientObject } from '../../types/client.type';
import * as offerActions from '../../actions/offers';
import TableBbros from '../../components/Table/TableBbros';
import { COLUMNS, OPTIONS } from '../../components/Table/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import {translatedText} from "../../components/TranslatedText";

interface Props {
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history:any;
    client:any;
    clientActions: any,
    offerActions: any,
    actions: any,
    agreements: any
    agreementsActions: any,
    measuresActions: any,
    letter: string,
    clientsByModuleData: Array<Client>
}

const ShowAllBlueprints = (props: Props) => {

    useEffect(() => {
        const page = 1;
        const moduleId = 5;
        props.actions.fetchClientsByModuleData(props.letter, page, moduleId).then((response)=>{})
    }, [])

    const editBlueprintData = (client:any) => {
        props.history.push({pathname: `/dashboard/blueprints/create/${client?.id}`})
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData:ClientObject) => editBlueprintData(rowData)
        }
    ]

    return (
        <div className="wrapper">
            <TableBbros
                title={translatedText({ id:"table.blueprints", defaultMessage:"Projektimet"})}
                data={props.clientsByModuleData}
                columns={COLUMNS}
                options={OPTIONS}
                actions={actions}
                isLoading={props.loading}
            />
        </div>
    )
}


function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        agreements: state.agreements
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        agreementsActions: bindActionCreators(agreementsActions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        measuresActions: bindActionCreators(measuresActions as any, dispatch),
        offerActions: bindActionCreators(offerActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ShowAllBlueprints as any)));
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as warehouseActions from '../../actions/warehouse'
import { withRouter } from 'react-router';
import './warehouse.scss'
import * as productionActions from '../../actions/production'
import DeleteModalComponent from '../../components/Modal/DeleteModal';
import ModalComponent from '../../components/Modal/ModalComponent';
import TableBbros from '../../components/Table/TableBbros';
import {OPTIONS, WAREHOUSE_CLIENTS} from '../../components/Table/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { ClientObject } from '../../types/client.type';
import {translatedText} from "../../components/TranslatedText";
import {toast} from "react-toastify";
import moment from "moment";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import {Button, EButtonType} from "../../components/Button";
import FontAwesome from 'react-fontawesome';
import {checkCorrespondingInput, setOnlyNewCorrespondingItems} from "../Admin/globalFunctions";
import {Input} from "../../components/Input/Input";

interface Props {
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history: any;
    production: any,
    warehouse: any,
    productionActions: any
    actions:any;
    letter: string;
    clientsByModuleData: any;
    warehouseActions: any,
    buttonLoader: any
}

const AllWarehouseClients = (props: Props) => {

    const moduleName = 'warehouse'
    const [clientId, setClientId] = useState<number>(null);
    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()
    const [withoutTransportFilter, setWithoutTransportFilterDate] = useState<boolean>(false)
    const [withoutInstallationFilter, setWithoutInstallationFilterDate] = useState<boolean>(false)
    const [filtersOpened, setFiltersOpened] = useState<boolean>(false)

    useEffect(() => {
        props.warehouseActions.getWarehouseAllClients()
    }, [])

    const editWarehouseData = (client: any) => {
        props.history.push({pathname: `/dashboard/warehouse/create/${client?.client?.id}`})
    }

    function deleteWarehouse(client: ClientObject) {
        setClientId(client?.id)
        setDeleteModal(true)
    }

    const deleteAction = () => {
        props.actions.deleteModuleData(clientId, moduleName)
        setDeleteModal(false)
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData: ClientObject) => editWarehouseData(rowData)
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData:ClientObject) => deleteWarehouse(rowData)
        }
    ]

    function filterData(){
        props.warehouseActions.getWarehouseAllClients(startDate, endDate, withoutTransportFilter, withoutInstallationFilter)
        setFiltersOpened(false)
    }

    return(
        <div className="wrapper warehouseContainer">
            <div className={`productionFilterButton`}>
                <div className={'w-100 h-100 position-relative'}>
                    <div className={'filterIconContainer'} onClick={() => setFiltersOpened(!filtersOpened)}>
                        <FontAwesome name={'filter'}/>
                    </div>
                    <div className={`filterDropdownContainer ${filtersOpened ? 'show' : 'hide'}`}>
                        <div className={'filterWrapper'}>
                            <div className={'closeFiltersButton'} onClick={() => setFiltersOpened(!filtersOpened)}>
                                <FontAwesome name={'close'}/>
                            </div>
                            <div
                                className={`dateContainer`}>
                                <p className={'label'}>Data e fillimit</p>
                                <p className={'dateValue'}>{startDate ? moment(startDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                {startDate && <img src={DifferentColoredPlus} onClick={() => setStartDate('')}
                                                   className="x-signDate"/>}
                                <div className={'calendar'}>
                                    <input type="date" value={startDate}
                                           onChange={(e) => setStartDate(e?.target?.value)}/>
                                    <div className={'calendarIcon'}>
                                        <FontAwesome
                                            name="calendar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`dateContainer`}>
                                <p className={'label'}>Data e mbarimit</p>
                                <p className={'dateValue'}>{endDate ? moment(endDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                {endDate &&
                                <img src={DifferentColoredPlus} onClick={() => setEndDate('')} className="x-signDate"/>}
                                <div className={'calendar'}>
                                    <input type="date" value={endDate} onChange={(e) => setEndDate(e?.target?.value)}/>
                                    <div className={'calendarIcon'}>
                                        <FontAwesome
                                            name="calendar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`dateContainer`}>
                                <Input
                                    id={`withoutTransport`}
                                    name={`withoutTransport`}
                                    value={1}
                                    type="checkbox"
                                    inputWrapperClass="checkboxWrapper w-100"
                                    valueNeedeed
                                    checked={withoutTransportFilter}
                                    onChange={(currentTarget) => setWithoutTransportFilterDate(currentTarget.checked)}
                                >
                                    <p className="m-0">Pa transport</p>
                                </Input>
                            </div>
                            <div
                                className={`dateContainer`}>
                                <Input
                                    id={`withoutInstallation`}
                                    name={`withoutInstallation`}
                                    value={1}
                                    type="checkbox"
                                    inputWrapperClass="checkboxWrapper w-100"
                                    valueNeedeed
                                    checked={withoutInstallationFilter}
                                    onChange={(currentTarget) => setWithoutInstallationFilterDate(currentTarget.checked)}
                                >
                                    <p className="m-0">Pa instalim</p>
                                </Input>
                            </div>
                            <Button
                                isLoading={props?.buttonLoader}
                                isDisabled={props?.buttonLoader}
                                className="m-auto w-100"
                                onClick={filterData}
                                buttonType={EButtonType.GreyButton}>
                                Filtro
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <TableBbros
                className={'firstColumnThinner'}
                title={translatedText({id: "table.warehouseClients", defaultMessage: "Te gjithe klientat ne depo"})}
                data={props?.warehouse?.warehouseClients}
                columns={WAREHOUSE_CLIENTS}
                options={OPTIONS}
                actions={actions}
                isLoading={props.loading}
            />
            <ModalComponent
                isModalHeader
                isOpen={isDeleteModalOpen}
                close={setDeleteModal}
            >
                <DeleteModalComponent
                    history={props.history}
                    close={() => setDeleteModal(false)}
                    deleteFunction={() => deleteAction()} />
            </ModalComponent>
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        production: state.production,
        warehouse: state.warehouse
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        productionActions: bindActionCreators(productionActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch),
        warehouseActions: bindActionCreators(warehouseActions as any, dispatch)
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(AllWarehouseClients as any)));

import { EInputTextType , Input } from '../../components/Input/Input'
import { FormattedMessage , injectIntl } from 'react-intl'
import { withRouter } from 'react-router';
import { RootState } from '../../reducers';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import * as actions from '../../actions/generalActions';
import * as adminActions from '../../actions/admin';
import { Button, EButtonType } from '../../components/Button';
import { axiosCaller } from '../../utils/apiCaller';
import { toast } from 'react-toastify';
import clearFormSvg from '../../assets/icons/archeology.svg'
import Tooltip from 'rc-tooltip';
import TableBbros from '../../components/Table/TableBbros';
import {IMPRESSION_QUESTIONS_COLUMNS, OPTIONS} from '../../components/Table/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import {isMobile} from "../../constants/constants";
import TranslatedText, {translatedText} from "../../components/TranslatedText";

interface Props {
    history:any;
    actions: any;
    production: any;
    intl: any;
    admin: any
    adminActions: any;
    transport: any;
    transportActions: any;
    loading: boolean;
    buttonLoader: boolean
}

const ImpressionQuestions = (props: Props) =>{

    const [impressionQuestion , setImpressionQuestion] = useState<string>('');
    const [selectedImpressionQuestion , setSelectedImpressionQuestion] = useState<any>({});

    useEffect(()=>{
        props.adminActions.getImpressionQuestions()
    }, [])

    useEffect(() => {
        if(selectedImpressionQuestion?.label){
            setImpressionQuestion(selectedImpressionQuestion?.label)
        }
    }, [selectedImpressionQuestion])

    function saveImpressionQuestion(){
        let payload = {
            question: impressionQuestion
        }

        if (selectedImpressionQuestion?.value) {
            props.adminActions.editImpressionQuestion( selectedImpressionQuestion?.value, payload).then(() => {
                props.adminActions.getImpressionQuestions()
                resetFields()
            })
        } else {
            props.adminActions.createImpressionQuestion(payload).then(() => {
                props.adminActions.getImpressionQuestions()
            })
            resetFields()
        }
    }

    const getImpressionQuestionData = (item) => {
        setSelectedImpressionQuestion(item)
    }

    const deleteImpressionQuestion = (item) => {
        axiosCaller('DELETE', `admin/impression-questions/${item.value}`).then((res: any) => {
            props.adminActions.getImpressionQuestions()
            toast.success('Pyetja u fshi me sukses!')
        })
    }

    function resetFields() {
        props.adminActions.clearImpressionQuestion()
        setImpressionQuestion('')
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData) => getImpressionQuestionData(rowData)
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData) => deleteImpressionQuestion(rowData)
        },
    ]

    return(
        <div className="admin-page">
            <div className={`create-admin-page-form ${isMobile ? 'w-100' : 'w-25'}`}>
                <div className="d-flex align-items-center">
                    <Input
                        name="impressionQuestion"
                        value={impressionQuestion || ''}
                        type="text"
                        inputWrapperClass="col-12 m-0"
                        onChange={(name, value) => setImpressionQuestion(value)}
                        inputTextType={EInputTextType.SimpleInput}
                        placeholder={translatedText({ id: "client.impressionQuestion", defaultMessage: "Pyetja" })} />
                    <Tooltip placement="right" overlay={<span><TranslatedText id="app.clearForm" defaultMessage="Pastro Formen" /></span>}>
                        <img className="create-client-icon" onClick={resetFields} src={clearFormSvg} alt="create client" />
                    </Tooltip>
                </div>
                <Button
                    isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                    submit={true}
                    className="w-100"
                    buttonType={EButtonType.GreyButton}
                    onClick={saveImpressionQuestion}>
                    {selectedImpressionQuestion?.value ?
                        <TranslatedText id="client.createOfferField" defaultMessage="Edito pyetjen" />
                        :
                        <TranslatedText id="client.createOfferField" defaultMessage="Krijo pyetjen" />
                    }
                </Button>
            </div>
            <div className="admin-page-list">
                <h2 className="m-0 mb-4">
                    <TranslatedText id="client.impressionQuestionsList" defaultMessage="Lista e pyetjeve te pershtypjes" />
                </h2>
                <TableBbros
                    title={translatedText({ id:"table.units", defaultMessage:"Pyetjet"})}
                    data={props.admin.impressionQuestions}
                    columns={IMPRESSION_QUESTIONS_COLUMNS}
                    options={OPTIONS}
                    actions={actions}
                    isLoading={props.loading}
                />
            </div>
        </div>
    )
}


function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        admin: state.admin
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        adminActions: bindActionCreators(adminActions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(ImpressionQuestions as any)));
import React, {useEffect} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import Navbar from '../../components/Navbar/Navbar';
import Wrapper from '../../components/Wrapper';
import Header from '../../components/Header';
import Modal from 'react-modal';
import {useHistory, withRouter} from 'react-router';
import RouterContext from '../RouterContext';
import Contacts from '../Contact/Contacts';
import Measures from '../Measures/Measures';
import Offers from '../Offers/Offers';
import Agreements from '../Agreements/Agreements';
import Production from '../Production/Production';
import Blueprints from '../Blueprints/Blueprints';
import Transport from '../Transport/Transport';
import Warehouse from '../Warehouse/Warehouse';
import Installation from '../Installation/Installation';
import Service from '../Service/Service';
import Impression from '../Impression/Impression';
import Admin from '../Admin/Admin';
import Page404 from '../Page404';
import "./Dashboard.scss";
import {Switch, Route, Redirect} from 'react-router-dom';
import Archive from "../Archive/Archive";
import ArchivedProducts from "../Archive/ArchivedProducts";
import {isMobile} from "../../constants/constants";
import MobileNavbar from "../../components/MobileNavbar/MobileNavbar";

Modal.setAppElement('#root');

interface Props {
    intl: any;
    isLoading: boolean;
    setLoading: boolean;
    actions: any;
    letter: string;
    location: any;
    history: any;
    loading: boolean;
    user: any;
    match: any;
    client: any;
    clientActions: any;
    isNavOpen: boolean;
}


const Dashboard: React.FC<Props> = (props) => {

    // const history = useHistory()

    return (
        <div className={'height111vh'}>
            <div className="d-flex height111vh w-100">
                <div className={`navbarWrapper ${isMobile ? props.isNavOpen ? '' : 'w-100' : ''}`}>
                    <Header/>
                    {/*{isMobile ? <MobileNavbar/> : <Navbar/>}*/}
                    <Navbar/>
                </div>
                <Wrapper isNavOpen={props.isNavOpen} className="dashboard">
                    <RouterContext.Provider value={props.history}>
                        <Switch>
                            <Route path="/dashboard/observer" render={props => (<Redirect to={{
                                pathname: '/dashboard/contact',
                            }}
                            />)}/>
                            <Route path="/dashboard/contact" component={Contacts}/>
                            <Route path="/dashboard/measures" component={Measures}/>
                            <Route path="/dashboard/offers" component={Offers}/>
                            <Route path="/dashboard/agreements" component={Agreements}/>
                            <Route path="/dashboard/blueprints" component={Blueprints}/>
                            <Route path="/dashboard/production" component={Production}/>
                            <Route path="/dashboard/warehouse" component={Warehouse}/>
                            <Route path="/dashboard/transport" component={Transport}/>
                            <Route path="/dashboard/installation" component={Installation}/>
                            <Route path="/dashboard/service" component={Service}/>
                            <Route path="/dashboard/impression" component={Impression}/>
                            <Route path="/admin" component={Admin}/>
                            <Route component={Page404}/>
                        </Switch>
                    </RouterContext.Provider>
                </Wrapper>
            </div>
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        user: state.user,
        step: state.app.step,
        client: state.client,
    }
}


function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Dashboard)));
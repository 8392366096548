import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router';
import { RootState } from '../../reducers';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as actions from '../../actions/generalActions';
import './admin.scss'
import ArticleListAndCreateForm from './Article/ArticleListAndCreateForm';

interface Props {
}

const Articles = ( props: Props ) =>{

    return(
        <div>
            <ArticleListAndCreateForm/>
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        transport: state.transport,
        admin: state.admin,
        production: state.production
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(Articles as any)));
import { EInputTextType , Input } from '../../components/Input/Input'
import { FormattedMessage , injectIntl } from 'react-intl'
import { withRouter } from 'react-router';
import { RootState } from '../../reducers';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import * as actions from '../../actions/generalActions';
import * as transportActions from '../../actions/transport';
import * as adminActions from '../../actions/admin';
import './admin.scss'
import { Button, EButtonType } from '../../components/Button';
import { Units as UnitsType } from '../../types/units.type';
import { toast } from 'react-toastify';
import { OPTIONS, UNIT_COLUMNS } from '../../components/Table/TableConstants';
import TableBbros from '../../components/Table/TableBbros';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Tooltip from "rc-tooltip";
import clearFormSvg from "../../assets/icons/archeology.svg";
import {isMobile} from '../../constants/constants'
import TranslatedText, {translatedText} from "../../components/TranslatedText";

interface Props {
    history:any;
    actions: any;
    production: any;
    intl: any;
    admin: any
    adminActions: any;
    transport: any;
    transportActions: any;
    loading: boolean;
    units: UnitsType[]
    buttonLoader: boolean
}

const Units = ( props: Props ) =>{

    const [unitId , setUnitId] = useState<number>(null);
    const [unitName , setUnitName] = useState<string>('');
   const [edit, isEdit] = useState<boolean>(false);

    useEffect(()=>{
        props.actions.fetchUnits()
    }, [])

    function saveUnit(){
        if(unitName){
            let payload = {
                name: unitName
            }

            props.adminActions.createUnit(payload).then(()=>{
                props.actions.fetchUnits()
            })
            setUnitName('')
        }else{
            toast.error('Ju lutem mbushni emrin e njesise!')
        }
    }

    function editUnit(){
        if(unitName){
            let payload = {
                name: unitName,
            }

            props.adminActions.editUnit(unitId,payload).then(()=>{
                props.actions.fetchUnits()
            })
            setUnitName('')
        }else{
            toast.error('Ju lutem mbushni emrin e njesise!')
        }
    }

    const getUnit = (item: UnitsType) => {
        isEdit(true);
        setUnitName(item.name)
        setUnitId(item.id)
        toast.info("Njesia u selektua")
    }
    
    const deleteUnit = (item:UnitsType) => {
        props.adminActions.deleteUnit(item.id).then(() => {
            props.actions.fetchUnits()
        })
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData:UnitsType) => getUnit(rowData)
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData:UnitsType) => deleteUnit(rowData)
        },
    ]

    function resetFields (){
        setUnitId(null)
        setUnitName('')
        isEdit(false)
    }

    return(
        <div className="admin-page">
            <div className={`create-unit-form ${isMobile? 'w-100':'w-25'}`}>
                <Input
                    name="unitName"
                    value={unitName || ''}
                    type="text"
                    inputWrapperClass="col-12"
                    onChange={(name, value) => setUnitName(value)}
                    inputTextType={EInputTextType.SimpleInput}>
                    <TranslatedText id="client.unitName" defaultMessage="Emri i njesise" />
                </Input>
                {edit ? 
                <Button
                    isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                    submit={true}
                    className="w-100"
                    buttonType={EButtonType.GreyButton}
                    onClick={editUnit}>
                    <TranslatedText id="client.editUnit" defaultMessage="Ndrysho" />
                </Button>:
                <Button
                    isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                    submit={true}
                    className="w-100"
                    buttonType={EButtonType.GreyButton}
                    onClick={saveUnit}>
                    <TranslatedText id="client.createUnit" defaultMessage="Krijo" />
                </Button>}
            </div>
            <div className="admin-page-list">
                <h2 className="m-0 mb-4">
                    <TranslatedText id="admin.listOfColors" defaultMessage="Lista e njesive"/>
                    <Tooltip placement="right" overlay={<span><TranslatedText id="app.clearForm"
                                                                                defaultMessage="Pastro Formen"/></span>}>
                        <img className="create-client-icon" onClick={resetFields} src={clearFormSvg}
                             alt="create client"/>
                    </Tooltip>
                </h2>
                <TableBbros
                    title={translatedText({ id:"table.units", defaultMessage:"Units"})}
                    data={props.units}
                    columns={UNIT_COLUMNS}
                    options={OPTIONS}
                    actions={actions}
                    isLoading={props.loading}
                />
            </div>
        </div>
    )
}


function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        transport: state.transport,
        admin: state.admin
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        transportActions: bindActionCreators(transportActions as any, dispatch),
        adminActions: bindActionCreators(adminActions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(Units as any)));
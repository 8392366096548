import { Permission } from './../types/permissions.type';
import * as constants from "../constants/constants";
import { User } from '../types/user.type';
import { ArticleInfomation } from '../types/ArticleInformation.type';
import { Units } from '../types/units.type';
import { OfferField } from '../types/offerField.type';

export interface AdminState {
    allUsers: User[],
    selectedUser: User,
    permissions: Permission[],
    responsibility: {},
    usersForResponsibilities: any[],
    articleInformation: ArticleInfomation,
    units: Units[],
    offerField: OfferField,
    offerConditions: string,
    auditLogs: any[],
    colorPalettes: any[],
    selectedMaterial: any,
    suppliers: any[]
    impressionQuestions: any,
    impressionQuestion: object,
    productionSettings: any,
    usersDashboardInformation: any
}

const initialState = () => {
    return {
        allUsers: [],
        selectedUser: {} as User,
        permissions: [] as Permission[],
        responsibility: {},
        usersForResponsibilities: [],
        articleInformation: {} as ArticleInfomation,
        units: [] as Units[],
        offerField: {name: ''},
        offerConditions: '',
        auditLogs: [],
        colorPalettes: [],
        selectedMaterial: {},
        suppliers: [],
        impressionQuestions: [],
        impressionQuestion: null,
        productionSettings: {},
        usersDashboardInformation: []
    }
}

const admin = (state: AdminState = initialState(), action: any) => {
    switch (action.type) {
        case constants.FETCH_USERS: {
            return { 
                ...state, 
                allUsers: action.payload
            };
        }
        case constants.FETCH_USERS_DASHBOARD_INFORMATION: {
            return {
                ...state,
                usersDashboardInformation: action.payload
            };
        }
        case constants.FETCH_USER: {
            return { 
                ...state, 
                selectedUser: action.payload
            };
        }
        case constants.FETCH_PERMISSIONS: {
            return { 
                ...state, 
                permissions: action.payload
            };
        }
        case constants.FETCH_MATERIALS: {
            return { 
                ...state, 
                materials: action.payload
            };
        }
        case constants.FETCH_RESPONSIBILITY: {
            return { 
                ...state, 
                responsibility: action.payload
            };
        }
        case constants.FETCH_USERS_FOR_RESPONSIBILITIES: {
            return { 
                ...state, 
                usersForResponsibilities: action.payload.data.data
            };
        }
        case(constants.FETCH_ARTICLE_TYPE): {
            return {
                ...state,
                articleType: action.payload
            }
        }
        case(constants.FETCH_ARTICLE): {
            return {
                ...state,
                articleInformation: action.payload
            }
        }
        case(constants.RESET_ARTICLE): {
            return {
                ...state,
                articleInformation: {}
            }
        }
        case(constants.CREATE_UNIT): {
            return {
                ...state,
                units: action.payload
            }
        }
        case(constants.EDIT_UNIT): {
            return {
                ...state,
                units: action.payload
            }
        }
        case(constants.DELETE_UNIT): {
            return {
                ...state,
                units: action.payload
            }
        }
        case(constants.GET_OFFER_FIELD): {
            return {
                ...state,
                offerField: action.payload
            }
        }
        case(constants.CLEAR_OFFER_FIELD): {
            return {
                ...state,
                offerField: {name: ''}
            }
        }
        case(constants.GET_OFFER_CONDITIONS): {
            return {
                ...state,
                offerConditions: action.payload[0]
            }
        }
        case(constants.GET_AUDIT_LOGS): {
            return {
                ...state,
                auditLogs: action.payload
            }
        }
        case(constants.GET_COLOR_PALETTES): {
            return {
                ...state,
                colorPalettes: action.payload
            }
        }
        case(constants.GET_MATERIAL): {
            return {
                ...state,
                selectedMaterial: action.payload.data
            }
        }
        case(constants.GET_SUPPLIERS): {
            return {
                ...state,
                suppliers: action.payload
            }
        }
        case(constants.GET_IMPRESSION_QUESTIONS): {
            return {
                ...state,
                impressionQuestions: action.payload
            }
        }
        case(constants.GET_IMPRESSION_QUESTION): {
            return {
                ...state,
                impressionQuestion: action.payload
            }
        }
        case(constants.GET_PRODUCTION_SETTINGS): {
            return {
                ...state,
                productionSettings: action.payload.data
            }
        }
    }
    return state;
}

export default admin;
import React from 'react';
import { ModalContent } from '../../../types/modalContent.type';
import { FormattedMessage } from 'react-intl'
import Logo from "../../../assets/icons/MIKA_logo-01.svg";
import './printedContent.scss'
import { formatDate } from '../../../utils/variableEvaluation';
import TranslatedText from "../../../components/TranslatedText";

interface Props {
  offer: ModalContent;
  message?: string;
}

export class PrintedContent extends React.PureComponent<Props> {
  render() {
      const { offer, message } = this.props
    return (
      <>
        <div id="printContainer">
            <div className="logoContainer">
                <img src={Logo} alt="logo"/>
            </div>
            <div className="consumatorInfoContainer">
                <p className="listTitle">
                    <TranslatedText id="table.nameSurname" defaultMessage="Konsumatori"/>
                </p>
                <div className="contentFieldContainer userDetailsContainer">
                    <div className="column">
                        <label><TranslatedText id="table.nameSurname" defaultMessage="Emri dhe mbiemri *"/></label>
                        <p>{ offer.firstAndLastName }</p>
                    </div>
                    <div className="column">
                        <label><TranslatedText id="client.scanDocument" defaultMessage="Data e ofertes *"/></label>
                        <p>{ offer.date ? formatDate(offer.date) : "" }</p>
                    </div>
                    <div className="column">
                        <label><TranslatedText id="table.fiscalNumber" defaultMessage="Nr. fiskal *"/></label>
                        <p>{ offer.fiscalNumber }</p>
                    </div>
                    <div className="column">
                        <label><TranslatedText id="table.address" defaultMessage="Adresa *"/></label>
                        <p>{ offer.address }</p>
                    </div>
                    <div className="column">
                        <label><TranslatedText id="app.table.offerId" defaultMessage="Nr. Ofertes *"/></label>
                        <p>{ offer.invoiceNumber }</p>
                    </div>
                    <div className="column">
                        <label><TranslatedText id="table.account" defaultMessage="Llogaria bankare *"/></label>
                        <p>{ offer.accountNumber }</p>
                    </div>
                </div>
            </div>
            {/* {JSON.stringify(offer)} */}
            {offer?.products?.map( (item , index)=>{
                return(
                    <div className="consumatorInfoContainer" key={index}>
                        <p className="listTitle">
                            <TranslatedText id="table.nameSurname" defaultMessage="Produkti"/>
                        </p>
                        <div className="contentFieldContainer productDetailsContainer">
                            <div>
                                <label><TranslatedText id="table.product" defaultMessage="Produkti *"/></label>
                                <p>{ item?.designation?.label }</p>
                            </div>
                            <div>
                                <label><TranslatedText id="table.type" defaultMessage="Tipi *"/></label>
                                <p>{ item?.type }</p>
                            </div>
                            <div>
                                <label><TranslatedText id="table.unit" defaultMessage="Njesia *"/></label>
                                <p>{ item?.unit }</p>
                            </div>
                            <div>
                                <label><TranslatedText id="table.price" defaultMessage="Qmimi *"/></label>
                                <p>{ item?.price }</p>
                            </div>
                            <div>
                                <label><TranslatedText id="table.discount" defaultMessage="Zbritja *"/></label>
                                <p>{ item?.discount }</p>
                            </div>
                            <div>
                                <label><TranslatedText id="table.tax" defaultMessage="TVSH *"/></label>
                                <p>{ item?.tax }</p>
                            </div>
                            <div>
                                <label><TranslatedText id="table.taxPrice" defaultMessage="TVSH qmimi *"/></label>
                                <p>{ item?.taxPrice }</p>
                            </div>
                            <div>
                                <label><TranslatedText id="table.value" defaultMessage="Vlera *"/></label>
                                <p>{ item?.value }</p>
                            </div>
                        </div>
                        <hr />
                    </div>
                )
            })}
            <div className="consumatorInfoContainer">
                <p className="listTitle">
                    <TranslatedText id="table.nameSurname" defaultMessage="Verejtje"/>
                </p>
                <div className="contentFieldContainer commentAndTotal">
                    <div className="commentInput">
                        <label><TranslatedText id="table.warning" defaultMessage="Verejtje *"/></label>
                        <p>{ !offer.comment }</p>
                    </div>
                    <div className="column">
                        <label><TranslatedText id="table.total" defaultMessage="Totali"/></label>
                        <p>{ offer.offerTotalCalculation }</p>
                    </div>
                </div>
            </div>
            <div dangerouslySetInnerHTML={{__html: message}}></div>
        </div>
      </>
  );
  }
}
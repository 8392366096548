import { EInputTextType , Input } from '../../components/Input/Input'
import { FormattedMessage , injectIntl } from 'react-intl'
import { withRouter } from 'react-router';
import { RootState } from '../../reducers';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import * as actions from '../../actions/generalActions';
import * as adminActions from '../../actions/admin';
import { Button, EButtonType } from '../../components/Button';
import { OfferField } from '../../types/offerField.type';
import { axiosCaller } from '../../utils/apiCaller';
import { toast } from 'react-toastify';
import clearFormSvg from '../../assets/icons/archeology.svg'
import Tooltip from 'rc-tooltip';
import TableBbros from '../../components/Table/TableBbros';
import { OFFERS_FIELD_COLUMNS, OPTIONS } from '../../components/Table/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import {isMobile} from "../../constants/constants";
import TranslatedText, {translatedText} from "../../components/TranslatedText";

interface Props {
    history:any;
    actions: any;
    production: any;
    intl: any;
    admin: any
    adminActions: any;
    transport: any;
    transportActions: any;
    loading: boolean;
    allOfferFields: OfferField[]
    buttonLoader: boolean
}

const OfferFields = (props: Props) =>{

    const [offerFieldName , setOfferFieldName] = useState<string>('');

    useEffect(()=>{
        props.actions.fetchOfferFields()
    }, [])
    
    useEffect(() => {
        setOfferFieldName(props.admin.offerField.name)
    }, [props.admin.offerField])

    function saveOfferField(){
        let payload = {
            name: offerFieldName
        }

        if (props.admin?.offerField?.id) {
            props.adminActions.editOfferField( props.admin?.offerField?.id, payload).then(() => {
                props.actions.fetchOfferFields()
                setOfferFieldName('')
            })
        } else {
            props.adminActions.createOfferField(payload).then(() => {
                props.actions.fetchOfferFields()
            })
            setOfferFieldName('')
        }
    }

    const getOfferFieldData = (item: OfferField) => {
        props.adminActions.getOfferField(item.id)
    }
    
    const deleteOfferField = (item: OfferField) => {
        axiosCaller('DELETE', `admin/dynamic-offers/${item.id}`).then((res: any) => {
            props.actions.fetchOfferFields()
            toast.success('Fusha u fshi me sukses!')
        })
    }

    function resetOfferField() {
        props.adminActions.clearOfferField()
        setOfferFieldName('')
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData:OfferField) => getOfferFieldData(rowData)
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData:OfferField) => deleteOfferField(rowData)
        },
    ]

    return(
        <div className="admin-page">
            <div className={`create-admin-page-form ${isMobile ? 'w-100' : 'w-25'}`}>
                <div className="d-flex align-items-center">
                    <Input
                        name="offerFieldName"
                        value={offerFieldName || ''}
                        type="text"
                        inputWrapperClass="col-12"
                        onChange={(name, value) => setOfferFieldName(value)}
                        inputTextType={EInputTextType.SimpleInput}
                    >
                        <TranslatedText id="admin.offerFieldName" defaultMessage="Emri i Fushes"/>
                    </Input>
                    <Tooltip placement="right" overlay={<span><TranslatedText id="app.clearForm" defaultMessage="Pastro Formen" /></span>}>
                        <img className="create-client-icon" onClick={resetOfferField} src={clearFormSvg} alt="create client" />
                    </Tooltip>
                </div>
                <Button
                    isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                    submit={true}
                    className="w-100"
                    buttonType={EButtonType.GreyButton}
                    onClick={saveOfferField}>
                    {props.admin?.offerField?.id ? 
                        <TranslatedText id="client.createOfferField" defaultMessage="Edito fushen" />
                        :
                        <TranslatedText id="client.createOfferField" defaultMessage="Krijo fushen" />
                    }
                </Button>
            </div>
            <div className="admin-page-list">
                <h2 className="m-0 mb-4">
                    <TranslatedText id="client.offerFieldsList" defaultMessage="Lista e fushave te ofertes" />
                </h2>
                <TableBbros
                    title={translatedText({ id:"table.units", defaultMessage:"Fushat"})}
                    data={props.allOfferFields}
                    columns={OFFERS_FIELD_COLUMNS}
                    options={OPTIONS}
                    actions={actions}
                    isLoading={props.loading}
                />
            </div>
        </div>
    )
}


function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        admin: state.admin
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        adminActions: bindActionCreators(adminActions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(OfferFields as any)));
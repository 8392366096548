import * as Yup from "yup";


export const NAME_SURNAME_VALIDATION = Yup.string()
    .min(1, "Too Short!")
    .max(50, "Too Long!")
    .required("Emri eshte obligativ !");

export const LAST_NAME_VALIDATION = Yup.string()
    .min(1, "Too Short!")
    .max(50, "Too Long!")
    .required("Mbiemri eshte obligativ !");

export const PHONE_VALIDATION = Yup.string()
    .matches(
        /^[0-9 _.,!()+=`,"@$#%*-]+$/,
        "Invalid phone number"
    )
    .min(9, "Too Short!")
    .max(50, "Too Long!")
    // .required("Numri eshte obligativ")


export const SECOND_PHONE_VALIDATION = Yup.string()
    .matches(
        /^[0-9 _.,!()+=`,"@$#%*-]+$/,
        "Invalid phone number"
    )
    .min(9, "Too Short!")
    .max(50, "Too Long!")

export const CITY_VALIDATION = Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Qyteti eshte obligativ");

export const ADDRESS_VALIDATION = Yup.string()
    .min(2, "Too Short!")
    .max(100, "Too Long!")

export const EMAIL_VALIDATION = Yup.string()
    .email()

export const BUSINESS_NR_VALIDATION =  Yup.string()
    .min(9, "Business number need 9 digits")
    .max(10, "Too Long, maximum 9 numbers!")

export const BANK_ACCOUNT_NR_VALIDATION = Yup.string()
    .min(16, "Too Short!")
    .max(16, "Too Long!")

export const COUNTRY_VALIDATION = Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Shteti eshte obligativ");

export const COMMENT_VALIDATION = Yup.string()
    .min(2, "Too Short!")

export const BANK_NAME_VALIDATION = Yup.string()
    .min(2, "Too Short!")
    .max(255, "Too Long!")

export const CONTACT_PERSON_VALIDATION = Yup.string()
    .min(2, "Too Short!")
    .max(255, "Too Long!")

export const ORDER_VALIDATION = Yup.string()
    .min(2, "Too Short!")
    .max(255, "Too Long!")

export const DATE_MEASURE_VALIDATION = Yup.string()
    .min(2, "Too Short!")
    .max(255, "Too Long!")

export const MEASURE_EMPLOYEE_VALIDATION = Yup.string()
    .min(2, "Too Short!")
    .max(255, "Too Long!")

export const PRODUCT_TYPE_VALIDATION = Yup.string()
    .min(2, "Too Short!")
    .max(255, "Too Long!")

export const MEASURE_SIMPLE_INPUT_VALIDATION = Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Fusha eshte obligative!")




// add  
export const CODE_VALIDATION = Yup.string()
    .min(2, "Too Short!")
    .max(10, "Too Long!")
    .required("Kodi i produktit eshte obligativ")


export const PRODUCTS_VALIDATION = Yup.string()
    .matches(
        /[0-9]+/,
        "Should be a number"
    )
    .max(8, "Too Long!")
    .required("Kjo fushe eshte obligative")

export const LOGIN_VALIDATION =  Yup.string().required('Required')


//USER

export const USER_FIRSTNAME_VALIDATION = Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Emri eshte obligativ");

export const USER_LASTNAME_VALIDATION = Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Mbiemri eshte obligativ");

export const USER_EMAIL_VALIDATION = Yup.string()
    .email()
    .required("Email adresa eshte obligative");

export const USER_USERNAME_VALIDATION = Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Username eshte obligativ");


export const USER_PASSWORD_VALIDATION = Yup.string()
    .min(8, "Too Short!")
    .max(50, "Too Long!")
    .required("Fjalekalimi eshte obligativ");


export const USER_PASSWORDCONFIRM_VALIDATION = Yup.string()
    .min(8, "Too Short!")
    .max(50, "Too Long!")
    .required("Konfirmimi i fjalekalimit eshte obligativ");

    export const MATERIAL_VALIDATION = Yup.string()

    export const RESPONSIBILITY_VALIDATION = Yup.string()
    .required("Procesi eshte obligativ");
import { TablePagination, TablePaginationProps } from "@material-ui/core";

export function PatchedPagination(props: TablePaginationProps) {
	const {
		ActionsComponent,
		onChangePage,
		onChangeRowsPerPage,
		...tablePaginationProps
	} = props;

	return (
		<TablePagination
			{...tablePaginationProps}
			onPageChange={onChangePage}
			onRowsPerPageChange={onChangeRowsPerPage}
			ActionsComponent={(subprops) => {
				const { onPageChange, ...actionsComponentProps } = subprops;
				return (
					// @ts-expect-error ActionsComponent is provided by material-table
					<ActionsComponent
						{...actionsComponentProps}
						onChangePage={onPageChange}
					/>
				);
			}}
		/>
	);
}
// import { TablePagination, TablePaginationProps } from "@material-ui/core";
// import {useEffect, useState} from "react";
//
// export function PatchedPagination(props: any) {
//
//
// 	const {
// 		ActionsComponent,
// 		onChangePage,
// 		onChangeRowsPerPage,
// 		customPagination,
// 		paginationDetails,
// 		callBack,
// 		...tablePaginationProps
// 	} = props;
// 	console.log(props,'paginantions props')
// 	// console.log(tablePaginationProps,'tablePaginationProps')
//
// 	useEffect(() => {
// 		const previous = document.querySelector('[title="Previous Page"]');
// 		const next = document.querySelector('[title="Next Page"]');
// 		previous.setAttribute('class', '-1');
// 		next.setAttribute('class', '1');
// 	}, [paginationDetails?.page]);
//
// 	function checkChangePageFunction(clickEvent, functionTablePaginationProps, pageChangeFunction){
// 		let pageAdd = clickEvent?.target.parentElement.getAttribute('class')
// 		pageChangeFunction(clickEvent, paginationDetails?.page + (+pageAdd))
// 		callBack(paginationDetails?.page + (+pageAdd))
//
// 	}
// 	console.log(paginationDetails,'paginationDetails')
// 	return (
// 		<TablePagination
// 			{...tablePaginationProps}
// 			count={paginationDetails?.count}
// 			page={paginationDetails?.page}
// 			onPageChange={onChangePage}
// 			onRowsPerPageChange={onChangeRowsPerPage}
// 			ActionsComponent={(subprops) => {
// 				const { onPageChange, ...actionsComponentProps } = subprops;
// 				return (
// 					customPagination ? <ActionsComponent
// 						{...actionsComponentProps}
// 						// onChangePage={onPageChange}
// 						onChangePage={(e)=>{
// 							checkChangePageFunction(e, tablePaginationProps,onPageChange)
// 						}}
// 					/> : <ActionsComponent
// 						{...actionsComponentProps}
// 						onChangePage={onPageChange}
// 					/>
// 				);
// 			}}
// 		/>
// 	);
// }
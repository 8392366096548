import * as constants from '../constants/constants';
import { axiosCaller } from "../utils/apiCaller";
import {setButtonLoading, setLoading, wrap} from './generalActions'
import {toast} from "react-toastify";

export const getImpressionRecords = (status) => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true));
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `impressions${status ? `?status=${status}`:''}`).then(res => {
            dispatch({ "type": constants.FETCH_IMPRESSION_RECORDS, "payload": res.data.data });
            dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_EQUIPMENTS')

export const getProjectReadyForImpression = () => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true));
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `impressions`).then(res => {
            dispatch({ "type": constants.FETCH_PROJECTS_FOR_IMPRESSION, "payload": res.data.data });
            dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_EQUIPMENTS')

export const getImpression = (impressionId) => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true));
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `impressions/${impressionId}`).then(res => {
            dispatch({ "type": constants.FETCH_IMPRESSION, "payload": res.data.data });
            dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_EQUIPMENTS')

export const getImpressionByProjectId = (projectId) => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true));
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `impressions/impression-by-project-id/${projectId}`).then(res => {
            dispatch({ "type": constants.FETCH_IMPRESSION, "payload": res.data.data });
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_EQUIPMENTS')


export const getImpressionNeededInfo = (projectId) => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true));
        dispatch(setButtonLoading(true));
        return await axiosCaller('GET', `installations/${projectId}/project`).then(res => {
            dispatch({ "type": constants.FETCH_IMPRESSION_NEEDED_INFO, "payload": res.data.data });
            dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_EQUIPMENTS')


export const createImpression = (payload) => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true));
        dispatch(setButtonLoading(true));
        return await axiosCaller('POST', `impressions`, payload).then(res => {
            toast.success('Pershtypja u krijua me sukses')
            dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_EQUIPMENTS')

export const editImpression = (payload, impressionId) => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true));
        dispatch(setButtonLoading(true));
        return await axiosCaller('POST', `impressions/${impressionId}`,payload).then(res => {
            toast.success('Pershtypja u editua me sukses')
            dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_EQUIPMENTS')
